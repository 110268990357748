import { ReactTable, Spinner, axios } from '@campxdev/shared'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { useQuery } from 'react-query'

function SemesterTable({ state }) {
  const { data: quotas, isLoading: quotaLoading } = useQuery(
    ['quotas', state.courseId],
    () =>
      axios
        .get('/paymentx/quotas', {
          params: {
            courseId: state.courseId,
          },
        })
        .then((res) => res.data),
    {
      enabled: !!state.courseId,
    },
  )

  if (quotaLoading) return <Spinner />

  return (
    <>
      {state?.data &&
        state?.data.map((item, index) => {
          let grandTotal = []
          return (
            <Table key={index} sx={{ margin: '16px 0px' }}>
              <TableHead>
                <TableCell colSpan={quotas?.quotas?.length + 5}>
                  {item.semName} A/Y {'(' + state.year + ')'}
                </TableCell>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Batch</TableCell>
                  <TableCell>Branch</TableCell>
                  <TableCell>Semester</TableCell>

                  {quotas?.quotas?.map((quota, index) => (
                    <TableCell key={index}>{quota.name}</TableCell>
                  ))}
                  <TableCell>Total</TableCell>
                </TableRow>
                {item.programs?.map((prog, progIndex) => {
                  let cellTotal = 0

                  return (
                    <TableRow key={index}>
                      <TableCell>{progIndex + 1}</TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {state?.year}
                      </TableCell>
                      <TableCell>{prog?.branchCode}</TableCell>
                      <TableCell>{item?.semNo}</TableCell>
                      {quotas?.quotas?.map((quota, ind) => (
                        <TableCell key={ind}>
                          {prog.quotas.map((q, i) => {
                            if (q.quotaId == quota.id) {
                              cellTotal += +q.count ? +q.count : 0

                              return (
                                <span key={i}>{q.count ? q.count : '-'}</span>
                              )
                            }
                          })}
                        </TableCell>
                      ))}
                      <TableCell>{cellTotal}</TableCell>
                    </TableRow>
                  )
                })}

                <TableRow>
                  <TableCell colSpan={4}>Total</TableCell>

                  {quotas?.quotas?.map((quota, index) => {
                    let colTotal = 0
                    item.programs.map((prog) => {
                      prog.quotas.map((q) => {
                        if (q.quotaId == quota.id) {
                          colTotal += q.count ? +q.count : 0
                        }
                      })
                    })
                    grandTotal.push(colTotal)
                    return <TableCell key={index}>{colTotal}</TableCell>
                  })}

                  <TableCell>{_.sum(grandTotal)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )
        })}
    </>
  )
}

export default SemesterTable
