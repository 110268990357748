import {
  ActionButton,
  SingleCheckbox,
  Spinner,
  ToastContainer,
  axiosErrorToast,
} from '@campxdev/shared'
import { Box, Stack, Typography, styled } from '@mui/material'
import { fetchCounsellors } from 'pages/LeadAdmissions/services'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { services } from 'services'
import { toast } from 'react-toastify'

const AssignCounsellors = ({ close, row }) => {
  const [counsellors, setCounsellors] = useState([])

  const { data, isLoading } = useQuery(
    'counsellors',
    () => fetchCounsellors(),
    {
      onSuccess: (res) => {
        const counsellors = res?.counsellors.map((item) => ({
          ...item,
          selected: item.clusterId === row.id,
        }))
        setCounsellors(counsellors)
      },
    },
  )

  const { mutate, isLoading: addFeeTypeLoading } = useMutation(
    services.clusters.assignCounsellors,
    {
      onSuccess: (res) => {
        close()
        toast.success('Counsellors Added to Cluster Successfully')
      },
      onError: (err) => {
        axiosErrorToast(err)
      },
    },
  )
  const handleChange = (e, counsellor) => {
    setCounsellors(
      counsellors.map((item) => {
        if (counsellor._id === item._id) {
          return {
            ...item,
            selected: !item.selected,
          }
        } else {
          return item
        }
      }),
    )
  }

  const handleSubmit = () => {
    const counsellorIds: string[] = counsellors
      .filter((counsellor) => counsellor.selected == true)
      .map((counsellor) => counsellor._id)
    mutate({ clusterId: row.id, globalUserIds: counsellorIds })
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <Box sx={{ padding: '10px' }}>
        <Typography variant="body2">Counsellors</Typography>
        <StyledCounsellorsContainer>
          <Stack gap={2}>
            {counsellors?.map((counsellor) => (
              <SingleCheckbox
                checked={counsellor?.selected}
                label={counsellor?.fullName}
                key={counsellor?._id}
                onChange={(e) => handleChange(e, counsellor)}
              />
            ))}
          </Stack>
        </StyledCounsellorsContainer>
        <Stack direction="row" gap={2} marginTop="20px">
          <ActionButton fullWidth onClick={close} variant="outlined">
            Cancel
          </ActionButton>
          <ActionButton
            loading={addFeeTypeLoading}
            fullWidth
            onClick={handleSubmit}
          >
            Submit
          </ActionButton>
        </Stack>
      </Box>
    </>
  )
}

export default AssignCounsellors

export const StyledCounsellorsContainer = styled(Box)({
  height: '400px',
  marginTop: '10px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.5em',
    height: '0.5em',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '3px',

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.3)',
    },
  },
  width: '100%',
})
