import {
  ActionButton,
  FormSingleSelect,
  axios,
  axiosErrorToast,
} from '@campxdev/shared'
import { Stack } from '@mui/material'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
const postRegistration = (postbody) => {
  return axios.post('/paymentx/leads/create-exam-registration', postbody)
}
const postThinkExamRegistration = (postbody) => {
  return axios.post('/paymentx/leads/think-exam-registrations', postbody)
}

const schema = yup.object().shape({
  groupName: yup.string().required('Exam Group is required'),
  examMode: yup.string().required('Exam Mode is required'),
})
function RegisterExam({ close, ids, type }) {
  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(schema),
  })

  const { data } = useQuery('think-exam-group', () => {
    return axios
      .get('/paymentx/leads/think-exam-groups')
      .then((res) => res.data)
  })
  const { mutate, isLoading } = useMutation(
    type == 'think-exam-registrations'
      ? postThinkExamRegistration
      : postRegistration,
    {
      onSuccess: () => {
        close()
        toast.success('Registered for exam successfully')
      },
      onError: (err) => {
        axiosErrorToast(err)
      },
    },
  )

  const onSubmit = (formData) => {
    const postBody = {
      leadIds: ids,
      ...formData,
    }
    mutate(postBody)
  }
  const onError = (err) => {
    console.log(err, 'error')
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Stack gap={3}>
          <FormSingleSelect
            required
            options={data?.map((item) => ({
              label: item.groupName,
              value: item.groupName,
            }))}
            label={'Select Exam Group'}
            name={'groupName'}
            control={control}
          />
          <FormSingleSelect
            required
            options={[
              {
                label: 'Offline',
                value: 'offline',
              },
              {
                label: 'Online',
                value: 'online',
              },
            ]}
            label={'Select Exam Mode'}
            name={'examMode'}
            control={control}
          />

          <Stack direction="row" gap={2} mt={2}>
            <ActionButton variant="outlined" fullWidth onClick={() => close()}>
              Cancel
            </ActionButton>
            <ActionButton type="submit" fullWidth loading={isLoading}>
              Submit
            </ActionButton>
          </Stack>
        </Stack>
      </form>
    </>
  )
}

export default RegisterExam
