import { useState } from 'react'

import { axios, axiosErrorToast } from '@campxdev/shared'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { AxiosRequestConfig } from 'axios'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

export const DeleteAdmissions = ({ open, admissionId, setOpen }) => {
  const [reason, setReason] = useState(null)
  const queryClient = useQueryClient()
  function handleClose() {
    setOpen(false)
  }

  async function handleDelete() {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `/paymentx/admissions/delete`,
      data: {
        admissionIds: [admissionId],
        reason: reason ? reason : '',
      },
    }

    try {
      await axios(config)
      queryClient.invalidateQueries('notification-admissions')
      toast.success('Admission Deleted Successfully')
      setOpen(false)
      setReason(null)
    } catch (error) {
      axiosErrorToast(error)
    }
  }
  return (
    <Dialog open={open} fullWidth maxWidth={'sm'} onClose={handleClose}>
      <DialogTitle sx={{ padding: '20px', backgroundColor: '#f7f7f7' }}>
        Delete Admission
      </DialogTitle>
      <DialogContent sx={{ padding: '30px' }}>
        <TextField
          fullWidth
          multiline
          rows={3}
          label="Reason to delete"
          sx={{ margin: '20px 0px' }}
          onChange={(e) => {
            setReason(e.target.value)
          }}
        />
        <Typography> Are you sure you want to delete?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleDelete()}>Delete</Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
