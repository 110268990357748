import { ActionButton, Spinner, axiosErrorToast } from '@campxdev/shared'
import { Box, Typography, styled } from '@mui/material'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { services } from 'services'
import { toast } from 'react-toastify'

const StyledResultCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 200px',
  outline: selected
    ? `2px solid ${theme.palette.primary.main}`
    : theme.borders.grayLight,
  padding: '16px',
  borderRadius: '20px',
  cursor: 'pointer',
  marginTop: '10px',
}))

const SearchResultCard = ({ onSelected, data, selected }) => {
  return (
    <StyledResultCard
      selected={data?._id === selected?._id}
      onClick={() => {
        onSelected(data)
      }}
    >
      <Typography>{data?.fullName}</Typography>
      <Typography variant="subtitle1">User Id: {data?._id}</Typography>
      <Typography variant="subtitle1">{data?.email}</Typography>
    </StyledResultCard>
  )
}

export default function BulkAssignCounsellor({ leadIds, clear, close }) {
  const queryClient = useQueryClient()
  const [state, setState] = useState({
    selected: null,
  })

  const { data, isLoading } = useQuery(
    ['counsellors'],
    services.users.fetchCounsellors,
  )

  const { mutate, isLoading: posting } = useMutation(
    services.leads.bulkAssignCounsellor,
    {
      onSuccess(data) {
        queryClient.invalidateQueries('leads')
        toast.success('Counsellor has been assigned successfully')
        clear()
        close()
      },
      onError(error) {
        axiosErrorToast(error)
      },
    },
  )

  const onSelected = (data) => {
    setState((prev) => ({
      ...prev,
      selected: data,
    }))
  }

  const handleSubmit = () => {
    mutate({
      counsellorUserId: state.selected?._id,
      leadIds: leadIds,
    })
  }

  if (isLoading) return <Spinner />
  return (
    <div>
      <Box sx={{ height: '500px', overflowY: 'auto' }}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Box padding="16px 10px">
            {data.counsellors?.map((item, index) => (
              <SearchResultCard
                key={index}
                data={item}
                onSelected={onSelected}
                selected={state.selected}
              />
            ))}
          </Box>
        )}
      </Box>
      <Box display="flex" gap="20px" padding={'10px 0'}>
        <ActionButton onClick={close} variant="outlined" fullWidth>
          Cancel
        </ActionButton>
        <ActionButton onClick={handleSubmit} loading={posting} fullWidth>
          Submit
        </ActionButton>
      </Box>
    </div>
  )
}
