import {
  ActionButton,
  DateRangePicker,
  FormSingleSelect,
  axios,
  axiosErrorToast,
} from '@campxdev/shared'
import { Box } from '@mui/material'
import { Stack } from '@mui/system'
import moment from 'moment'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

export function BranchWiseFilters({ setIntakeData, course }) {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [error, setError] = useState(false)

  const { control, handleSubmit } = useForm({
    defaultValues: {
      lateralEntry: 'all',
    },
  })

  function onSubmit(formData) {
    if (!startDate && !endDate) {
      setError(true)
      return
    }
    setError(false)
    const data = axios
      .get('/paymentx/admissions/branch-wise-report', {
        params: {
          lateralEntry:
            formData.lateralEntry != 'all' ? formData.lateralEntry : undefined,
          fromDate: moment(startDate).format('YYYY-MM-DD'),
          toDate: moment(endDate).format('YYYY-MM-DD'),
        },
      })
      .then((res) => {
        setIntakeData(res.data)
      })
      .catch((err) => {
        axiosErrorToast(err)
      })
  }
  return (
    <Box sx={{ marginTop: '20px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction={'row'} alignItems={'flex-start'} gap="10px">
          <Box sx={{ width: '200px' }}>
            <FormSingleSelect
              control={control}
              size="small"
              name={'lateralEntry'}
              label={'Type'}
              options={[
                { label: 'All Admissions', value: 'all' },
                { label: 'Regular Admissions', value: 'false' },
                { label: 'Lateral Admissions', value: 'true' },
              ]}
            />
          </Box>
          <Box>
            <DateRangePicker
              label="Filter by Date"
              size="small"
              required
              helperText="Please select date"
              value={{
                start: startDate,
                end: endDate,
              }}
              onChange={(newValue) => {
                setStartDate(!!newValue.start && newValue.start)
                setEndDate(!!newValue.end && newValue.end)
              }}
              containerProps={{
                sx: { width: '400px' },
              }}
              error={error}
            />
          </Box>
          <Box sx={{ minWidth: '150px', marginTop: '28px' }}>
            <ActionButton size="small" sx={{ width: '150px' }} type="submit">
              Get Report
            </ActionButton>
          </Box>
        </Stack>
      </form>
    </Box>
  )
}
