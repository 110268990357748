import { axios } from '@campxdev/shared'
import moment from 'moment'
import * as XLSX from 'xlsx'

export const fetchNotificationAdmissions = (id, params) => {
  return axios
    .get(`/paymentx/admissions/export`, {
      params: {
        ...params,
        startDate:
          params?.startDate && moment(params?.startDate).format('YYYY-MM-DD'),
        endDate:
          params?.endDate && moment(params?.endDate).format('YYYY-MM-DD'),
        admissionNotificationId: id != 'all-admissions' ? id : undefined,
      },
    })
    .then((res) => res.data)
}

export const fetchCounsellors = async () => {
  const res = await axios.get('/paymentx/counsellors')
  return res.data
}

export const counselledByMe = async (id: string) => {
  const res = await axios.post(`/paymentx/admissions/counselled-by-me/${id}`)
  return res.data
}

export const RequestForProvisionalLetter = async (body: {
  admissionId: any
  studentServiceId: any
}) => {
  const res = await axios.post(`/paymentx/student-service-requests`, body)
  return res.data
}

export const bulkAssignCounsellor = async ({
  ids,
  counsellorId,
  counsellorName,
}) => {
  return axios.post('/paymentx/admissions/assign-bulk-counsellor', {
    ids,
    counsellorId,
    counsellorName,
  })
}

export const getAdmissionExport = async (id, params) => {
  let excelData

  const changedParams = {
    ...params,
    limit: undefined,
    offset: undefined,
  }

  const data = await fetchNotificationAdmissions(id, changedParams)

  if (data) {
    excelData = data?.map((item, index) => ({
      ...item,
      // ADMISSION_ID: item?.uniqueId || '',
      // ROLL_NO: item?.rollNo || '',
      // FIRST_NAME: item?.firstName
      //   ? item?.firstName?.toUpperCase()
      //   : item?.fullName?.toUpperCase(),
      // LAST_NAME: item?.lastName ? item?.lastName?.toUpperCase() : '',
      // GENDER: item?.gender || '',
      // EMAIL: item?.email || '',
      // MOBILE: item?.mobile || '',
      // BATCH: item?.batch || '',
      // COURSE: item?.course?.courseName || '',
      // PROGRAM: item?.program?.branchCode || '',
      // QUOTA: item?.quota?.name || '',
      // DOB: item?.personalDetails?.dob
      //   ? moment(item?.personalDetails?.dob).format('DD-MM-YYYY')
      //   : '',
      // MOTHER_TOUNGE: item?.personalDetails?.motherTongue || '',
      // PHASE:
      //   (item.phase1 && 1) || (item.phase2 && 2) || (item.phase3 && 3) || '',
      // AADHAR_NUMBER: item?.personalDetails?.aadhaarNo || '',
      // NIC: item?.personalDetails?.nicKey || '',
      // LATERAL_ENTRY: item?.lateralEntry == true ? 'Yes' : 'No' || '',
      // ADDRESS: item?.personalDetails?.address || '',
      // FATHER_NAME: item?.guardianDetails?.fatherName || '',
      // FATHER_MOBILE: item?.guardianDetails?.fatherMobile || '',
      // FATHER_OCCUPATION: item?.guardianDetails?.fatherOccupation || '',
      // MOTHER_NAME: item?.guardianDetails?.motherName || '',
      // MOTHER_MOBILE: item?.guardianDetails?.motherMobile || '',
      // MOTHER_OCCUPATION: item?.guardianDetails?.motherOccupation || '',
      // COUNSELLED_BY: item?.counselledBy?.fullName || '',
      // DATE: item?.confirmedAt
      //   ? moment(item?.confirmedAt).format('DD-MM-YYYY')
      //   : '',
      // ADMISSION_STATUS: item?.status || '',
      // IDENTIFICATION_MARK1: item?.personalDetails?.identificationMarks1 || '',
      // IDENTIFICATION_MARK2: item?.personalDetails?.identificationMarks2 || '',
      // TRANSFERED: item?.transferred ? 'yes' : 'no' || '',
      // PHYSICALLY_CHALLENGED: item?.personalDetails?.physicallyChallenged
      //   ? 'yes'
      //   : 'no' || '',
      // CASTE: item?.personalDetails?.caste || '',
      // SUB_CASTE: item?.personalDetails?.subCaste || '',
      // ALLOTED_CASTE: item?.personalDetails?.allottedCasteCategory || '',
      // ASSIGNED_CASTE: item?.personalDetails?.assignedCasteCategory || '',
      // REFERED_BY: item?.referredBy || '',
      // YEAR: item?.year,
      // SEM_NO: item?.semNo,
      // EAMCET_HALLTICKET_NUMBER:
      //   item?.interDetails?.eamcetHallTicketNumber || '',
      // EAMCET_RANK: item?.interDetails?.eamcetRank || '',
      // // inter details
      // INTER_HALL_TICKET_NUMBER: item?.interHallTicketNumber || '',
      // INTER_PERCENTAGE: item?.interDetails?.interPercentage || '',
      // INTER_CGPA: item?.interDetails?.interCgpa || '',
      // INTER_QUALIFYING_BOARD: item?.interDetails?.interQualifyingBoard || '',
      // INTER_TOTAL_MARKS: item?.interDetails?.interTotalMarks || '',
      // INTER_SECURED_MARKS: item?.interDetails?.interSecuredMarks || '',
      // INTER_LANG_MARKS: item?.interDetails?.interLangMarks || '',
      // INTER_GROUP_MARKS: item?.interDetails?.interGroupMarks || '',
      // INTER_GROUP_PERCENTAGE:
      //   item?.interDetails?.interGroupMarksPercentage || '',
      // IS_INTERMEDIATE_SUBMITTED: item?.interDetails?.isIntermediateSubmitted
      //   ? 'yes'
      //   : 'no' || '',
      // HAS_SCHOLARSHIP: item?.hasScholarship ? 'yes' : 'no',
      // // tehth details
      // TENTH_HALL_TICKET_NUMBER: item?.tenthDetails?.tenthHallTicketNumber || '',
      // TENTH_STUDENT_NAME: item?.tenthDetails?.tenthStudentName || '',
      // TENTH_PERCENTAGE: item?.tenthDetails?.tenthPercentage || '',
      // TENTH_CGPA: item?.tenthDetails?.tenthCgpa || '',
      // TENTH_QUALIFYING_BOARD: item?.tenthDetails?.tenthQualifyingBoard || '',
      // TENTH_TOTAL_MARKS: item?.tenthDetails?.tenthTotalMarks || '',
      // TENTH_SECURED_MARKS: item?.tenthDetails?.tenthSecuredMarks || '',
      // IS_SSC_SUBMITTED: item?.tenthDetails?.isSSCSubmitted ? 'yes' : 'no' || '',
    }))
  } else {
    excelData = [
      {
        ADMISSION_ID: '',
        ROLL_NO: '',
        FIRST_NAME: '',
        LAST_NAME: '',
        GENDER: '',
        EMAIL: '',
        MOBILE: '',
        BATCH: '',
        COURSE: '',
        PROGRAM: '',
        QUOTA: '',
        DOB: '',
        MOTHER_TOUNGE: '',
        AADHAR_NUMBER: '',
        LATERAL_ENTRY: '',
        ADDRESS: '',
        FATHER_NAME: '',
        FATHER_MOBILE: '',
        FATHER_OCCUPATION: '',
        MOTHER_NAME: '',
        MOTHER_MOBILE: '',
        MOTHER_OCCUPATION: '',
        COUNSELLED_BY: '',
        DATE: '',
        ADMISSION_STATUS: '',
        IDENTIFICATION_MARK1: '',
        IDENTIFICATION_MARK2: '',
        TRANSFERED: '',
        PHYSICALLY_CHALLENGED: '',
        CASTE: '',
        REFERED_BY: '',
        YEAR: '',
        SEM_NO: '',
        EAMCET_HALLTICKET_NUMBER: '',
        EAMCET_RANK: '',
        INTER_HALLTICKER_NUMBER: '',
        INTER_PERCENTAGE: '',
        INTER_CGPA: '',
        INTER_QUALIFYING_BOARD: '',
        INTER_TOTAL_MARKS: '',
        INTER_SECURED_MARKS: '',
        INTER_LANG_MARKS: '',
        INTER_GROUP_MARKS: '',
        INTER_GROUP_PERCENTAGE: '',
        IS_INTERMEDIATE_SUBMITTED: '',
        TENTH_HALL_TICKET_NUMBER: '',
        TENTH_STUDENT_NAME: '',
        TENTH_PERCENTAGE: '',
        TENTH_CGPA: '',
        TENTH_QUALIFYING_BOARD: '',
        TENTH_TOTAL_MARKS: '',
        TENTH_SECURED_MARKS: '',
        IS_SSC_SUBMITTED: '',
      },
    ]
  }

  const fileName = `AllAdmissions.xlsx`
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(
    wb,
    ws,
    `${moment().format('DD-MM-YYYY')} - Users`,
  )
  XLSX.writeFile(wb, fileName)
}

export const statusData = {
  ADMITTED: 'Admitted',
  IN_PROGRESS: 'In Progress',
  OPEN: 'Open',
  REJECTED: 'Rejected',
  CONFIRMED: 'Confirmed',
  CANCELLED: 'Cancelled',
}
