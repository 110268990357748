import {
  ActionButton,
  DialogButton,
  DrawerButton,
  FilterButton,
  PageContent,
  PageHeader,
  ReactTable,
  SearchBar,
  UploadFileDialog,
  UserStore,
  axiosErrorToast,
  useConfirm,
} from '@campxdev/shared'
import { queryClient } from '@campxdev/shared/src/contexts/QueryClientProvider'
import { Box, Button } from '@mui/material'
import RegisterExam from 'pages/LeadAdmissions/AdmissionDetails/RegisterExam'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import LeadRegistrationFilters from './LeadRegistrationFilters'
import {
  fetchLeadExamRegistrations,
  handleExportLeadRegistrations,
  leadRegistrationStore,
  thinkExamRegistrations,
} from './service'

function LeadRegistration() {
  const { isConfirmed } = useConfirm()
  const filters = leadRegistrationStore.useState((s) => s)
  const state = UserStore.useState()

  const [filtersApplied, setFiltersApplied] = useState(false)

  useEffect(() => {
    Object.keys(filters).some((key) => {
      if (
        !!filters[key] &&
        key != 'limit' &&
        key != 'offset' &&
        key != 'batch' &&
        key != 'page' &&
        filters[key] != '' &&
        key != 'sortOrder' &&
        key != 'search'
      ) {
        setFiltersApplied(true)
      }
    })

    if (sessionStorage.getItem('view-leads')) {
      sessionStorage.removeItem('view-leads')
    }
  }, [])

  const { data, isLoading, refetch } = useQuery(
    ['lead-exam-registration', filters],
    () => fetchLeadExamRegistrations({ ...filters }),
  )

  const { mutate } = useMutation(thinkExamRegistrations, {
    onSuccess: (res) => {
      queryClient.invalidateQueries('lead-exam-registration')
    },
    onError: (error) => {
      axiosErrorToast(error)
    },
  })

  const { mutate: ExportData } = useMutation(fetchLeadExamRegistrations, {
    onSuccess: (res) => {
      handleExportLeadRegistrations(res.result || [])
    },
  })

  const handleRegister = async (data, clear) => {
    const confirm = await isConfirmed(
      'Are you sure you want to register all the selected rows?',
    )
    if (!confirm) return

    mutate({ leadIds: data })
    clear()
  }

  const handleExport = () => {
    const exportFilters = {
      ...filters,
      skip: 0,
      limit: null,
    }
    ExportData(exportFilters)
  }

  const columns = [
    {
      title: 'Lead ID',
      dataIndex: 'uniqueId',
      key: 'uniqueId',
    },
    {
      title: 'Status',
      dataIndex: 'examStatus',
      key: 'examStatus',
      render: (_, row) => {
        return <div style={{ color: 'green' }}> {row.examStatus}</div>
      },
    },
    {
      title: 'Lead Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, row) => {
        return `${row?.firstName} ${row?.lastName}`
      },
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Group Name',
      dataIndex: 'groupName',
      key: 'groupName',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobile',
      key: 'mobile',
    },

    {
      title: 'Exam Mode',
      dataIndex: 'onlineTransaction.order.notes.examMode',
      key: 'onlineTransaction.order.notes.examMode',
    },
    {
      title: 'Start Date',
      dataIndex: 'examResults.startDate',
      key: 'startdate',
    },
    {
      title: 'End Date',
      dataIndex: 'examResults.endDate',
      key: 'endDate',
    },
    {
      title: 'Marks',
      dataIndex: 'examResults.marks',
      key: 'marks',
    },
  ]

  const handlePageLimit = (value: number) => {
    leadRegistrationStore.update((s) => {
      s.limit = value
      s.skip = 0
    })
  }
  const handlePagination = (value: number) => {
    leadRegistrationStore.update((s) => {
      s.skip = value
    })
  }

  return (
    <>
      <PageHeader
        title={'Lead Exam Registrations'}
        actions={
          <>
            <ActionButton onClick={handleExport}>Export </ActionButton>
            {state.user.isSuperUser && (
              <UploadFileDialog
                uploadUrl="/paymentx/leads/import-exam-results"
                buttonText="Import Marks"
                dialogTitle="Import Marks"
                successMessage="Marks Imported Successfully"
                refetchFn={refetch}
              />
            )}
          </>
        }
      />

      <PageContent>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'end',
            marginBottom: '20px',
          }}
        >
          <SearchBar
            onSearch={(v) => {
              leadRegistrationStore.update((s) => {
                s.search = v
              })
            }}
            textFieldProps={{
              defaultValue: filters.search ?? '',
              containerProps: { width: '500px' },
              label: 'Search by name /Email /Phone No.',
            }}
          />
          <DrawerButton
            anchor={({ open }) => (
              <FilterButton onClick={open} filtersApplied={filtersApplied} />
            )}
            content={({ close }) => (
              <>
                <LeadRegistrationFilters
                  hideDialog={close}
                  setFiltersApplied={setFiltersApplied}
                />
              </>
            )}
            title="Apply Filters"
          />
          {/* 
          <DropDownButton
            icon={{
              icon: <SortIcon />,
              outlined: true,
              iconProps: {
                sx: {
                  height: '50px',
                  width: '50px',
                  border: theme.borders.grayLight,
                },
              },
            }}
            menu={sortByOptions.map((item) => {
              return {
                label: `${item.label}`,
                onClick: () => {
                  leadStore.update((s) => {
                    s.sortBy = `${item.value}`
                  })
                },
              }
            })}
          /> */}
        </Box>
        <Box>
          <ReactTable
            dataSource={data?.result || []}
            columns={columns}
            loading={isLoading}
            pagination={{
              page: filters.skip / filters.limit,
              limit: filters.limit,
              onChange: handlePagination,
              totalCount: data?.count ?? 0,
              onChangeLimit: handlePageLimit,
            }}
            select={{
              enable: true,
              actions: [
                {
                  component: (ids, clear) => (
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <DialogButton
                        title="Register for Exam"
                        anchor={({ open }) => (
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={open}
                          >
                            Register for Exam
                          </Button>
                        )}
                        content={({ close }) => (
                          <RegisterExam
                            close={close}
                            ids={ids}
                            type={'think-exam-registrations'}
                          />
                        )}
                      />
                    </Box>
                  ),
                },
              ],
            }}
          />
        </Box>
        {/* <Table columns={columns} dataSource={data} loading={isLoading} /> */}
      </PageContent>
    </>
  )
}

export default LeadRegistration
