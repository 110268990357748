import { axios } from '@campxdev/shared'

export const studentServices = {
  async fetchStudentServices() {
    let res = await axios.get('/paymentx/student-services')
    return res.data
  },

  async deleteStudentService(id: any) {
    let res = await axios.delete(`/paymentx/student-services/${id}`)
    return res.data
  },
}
