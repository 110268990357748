import Box from '@mui/material/Box'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'

const steps = ['phase1', 'phase2', 'phase3']

const PhaseTimeline = (data) => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          '& .css-kvd9i-MuiSvgIcon-root-MuiStepIcon-root.Mui-active': {
            color: 'green',
          },
          '& .css-c3xs8e.Mui-active': {
            color: 'green',
          },
        }}
      >
        <Stepper activeStep={-1}>
          {steps.map((label) => (
            <Step key={label} active={getActiveStep(data, label)}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </>
  )
}

function getActiveStep(data, label) {
  return data.data[label]
}

export default PhaseTimeline
