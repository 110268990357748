import { IPhaseLogsFilter } from 'interfaces/admission'
import { Store } from 'pullstate'

export const PhaseLogsStore = new Store<IPhaseLogsFilter>({
  limit: 10,
  skip: 0,
  batch: '',
  search: '',
  phaseStatus: null,
  courseId: '',
  quotaId: '',
})
