import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import _, { find } from 'lodash'

const dynamicStatusValues = [
  {
    title: 'IN_PROGRESS',
    value: 'Applications Pending',
  },
  {
    title: 'OPEN',
    value: 'Applications Pending',
  },
  {
    title: 'ADMITTED',
    value: 'Admitted Students',
  },
  {
    title: 'CONFIRMED',
    value: 'Applications Pending',
  },
  {
    title: 'CANCELLED',
    value: 'Applications Rejected',
  },
  {
    title: 'REJECTED',
    value: 'Applications Rejected',
  },
]

export function BranchWiseTable({ data, course }) {
  const fixedColumns = ['Degree', 'Branch', 'Total Applied']

  const dynamicColumns = [
    'Applications Rejected',
    'Applications Pending',
    'Admitted Students',
  ]

  const columns = fixedColumns.concat(dynamicColumns)

  const rowCount = {}

  data?.forEach((item) => {
    if (!rowCount[item.branchCode]) {
      rowCount[item.branchCode] = {}
    }
    rowCount[item['branchCode']][
      find(dynamicStatusValues, { title: item.status })?.value
    ] = parseInt(item.count, 10)
    rowCount[item['branchCode']]['course'] = item.courseName
  })

  const totalStatusCount = {}

  dynamicColumns.forEach((status) => {
    totalStatusCount[status] = Object.values(rowCount).reduce(
      (total, branch) => {
        return total + (branch[status] || 0)
      },
      0,
    )
  })

  return (
    <>
      <Table>
        <TableHead>
          {columns?.map((col, index) => (
            <TableCell key={index}>{col}</TableCell>
          ))}
        </TableHead>

        <TableBody>
          {Object.keys(rowCount).map((branch) => (
            <TableRow key={branch}>
              <TableCell>{rowCount[branch]['course']}</TableCell>
              <TableCell>{branch}</TableCell>
              <TableCell>
                {dynamicColumns.reduce((total, status) => {
                  return total + (rowCount[branch][status] || 0)
                }, 0)}
              </TableCell>
              {dynamicColumns.map((status) => (
                <TableCell key={status}>
                  {rowCount[branch][status] ? rowCount[branch][status] : 0}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableBody style={{ backgroundColor: '#F2F2F2' }}>
          <TableRow>
            <TableCell colSpan={2}>Total</TableCell>
            <TableCell>{_.sum(_.values(totalStatusCount))}</TableCell>
            {dynamicColumns.map((col) => (
              <TableCell key={col}>{totalStatusCount[col]}</TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}
