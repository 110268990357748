import {
  ErrorBoundary,
  LayoutWrapper,
  withRouteWrapper,
} from '@campxdev/shared'
import AppLayout from 'layouts/AppLayout'

import { baseRoutes } from './baseRoutes'
import { Outlet } from 'react-router-dom'
import { dashboardMenu } from 'layouts/DashboardLayout/dashboardMenu'
import SettingsLayout from 'layouts/SettingsLayout/SettingsLayout'
import { settingsRoutes } from './settingsRoutes'
import PrintLayout from 'layouts/PrintLayout'
import { individualRoutes } from './individualRoutes'
import PublicFormsLayout from 'layouts/PublicFormsLayout'
import { publicFormsRoutes } from './publicFormsRoutes'

export const mainRoutes = [
  {
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: (
          <LayoutWrapper menu={dashboardMenu}>
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </LayoutWrapper>
        ),
        children: withRouteWrapper(baseRoutes),
      },
      {
        element: <SettingsLayout />,
        path: '/settings',
        children: withRouteWrapper(settingsRoutes),
      },
    ],
  },

  {
    path: '/print',
    element: <PrintLayout />,
    children: withRouteWrapper(individualRoutes),
  },

  {
    path: '/public-forms',
    element: <PublicFormsLayout />,
    children: publicFormsRoutes,
  },

  {
    path: '*',
    element: (
      <h1 style={{ textAlign: 'center', marginTop: '50px' }}>Page Not Found</h1>
    ),
  },
]
