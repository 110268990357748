import {
  ActionButton,
  DeleteButton,
  DrawerButton,
  EditButton,
  PageContent,
  PageHeader,
  Permission,
  ReactTable,
  UploadFileDialog,
  ValidateAccess,
  axios,
  axiosErrorToast,
  useConfirm,
  useModal,
} from '@campxdev/shared'
import { Box } from '@mui/material'
import { AxiosRequestConfig } from 'axios'
import { useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import CasteForm from './CasteForm'

function Caste() {
  const queryClient = useQueryClient()
  const modal = useModal()
  const { isConfirmed } = useConfirm()

  const { data, isLoading, refetch } = useQuery('caste-details', () =>
    axios.get('/paymentx/castes').then((res) => res.data),
  )

  const onDelete = async (id: any) => {
    const confirmed = await isConfirmed('Are you sure you want to delete?')
    if (!confirmed) return

    const config: AxiosRequestConfig = {
      method: 'DELETE',
      url: `/paymentx/castes/${id}`,
    }

    try {
      await axios(config)
      queryClient.invalidateQueries('caste-details')
      toast.success('caste Deleted Successfully')
    } catch (error) {
      axiosErrorToast(error)
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (_, row, index) => <>{index + 1}</>,
    },
    {
      title: 'Caste',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Sub Caste',
      dataIndex: 'subCaste',
      key: 'subCaste',
      render: (_, row) => {
        return <>{row?.subCaste.toString()}</>
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, row) => {
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            <ValidateAccess accessKey={Permission.CAN_CASTE_EDIT}>
              <EditButton
                onClick={() =>
                  modal({
                    title: 'Edit Caste',
                    content: ({ close }) => (
                      <CasteForm data={row} hideDialog={close} />
                    ),
                  })
                }
              />
            </ValidateAccess>
            <ValidateAccess key="3" accessKey={Permission.CAN_CASTE_DELETE}>
              <DeleteButton onClick={() => onDelete(row?.id)} />
            </ValidateAccess>
          </div>
        )
      },
    },
  ]

  return (
    <>
      <PageHeader
        title="Caste"
        actions={[
          <Box key={2}>
            <ValidateAccess accessKey={Permission.CAN_CASTE_ADD} key={1}>
              <UploadFileDialog
                buttonText={'Import'}
                dialogTitle={'Import Castes'}
                uploadUrl="/paymentx/castes/import"
                sampleFileUrl="/static_files/caste_import.xlsx"
                refetchFn={() => refetch()}
              />
            </ValidateAccess>
          </Box>,
          <ValidateAccess accessKey={Permission.CAN_CASTE_ADD} key={1}>
            <DrawerButton
              title="New Caste"
              anchor={({ open }) => (
                <ActionButton onClick={open}>New Caste</ActionButton>
              )}
              content={({ close }) => (
                <CasteForm data={null} hideDialog={close} />
              )}
            />
          </ValidateAccess>,
        ]}
      />
      <PageContent>
        <ReactTable columns={columns} dataSource={data} loading={isLoading} />
      </PageContent>
    </>
  )
}

export default Caste
