import {
  Chips,
  FormLabel,
  axios,
  axiosErrorToast,
  useConfirm,
} from '@campxdev/shared'
import { Chip, Stack, Typography, styled } from '@mui/material'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

function CasteList({ caste, close }) {
  const queryClient = useQueryClient()
  const { isConfirmed } = useConfirm()
  async function handleClick(value) {
    const confirmed = await isConfirmed('Are you sure you want to delete?')
    if (!confirmed) return
    try {
      await axios.post(`/paymentx/castes/remove-sub-caste`, {
        id: caste.id,
        subCaste: value,
      })
      toast.success('Sub Caste deleted successfully')
      close()
      queryClient.invalidateQueries('caste-details')
    } catch (e) {
      axiosErrorToast(e)
    }
  }

  return (
    <>
      <FormLabel
        label={'Assigned Sub Castes'}
        required={undefined}
        name={undefined}
      />
      <Stack gap={'8px'} direction={'row'} flexWrap={'wrap'}>
        {caste?.subCaste?.map((sub, index) => (
          <StyledChip
            key={index}
            variant="outlined"
            label={sub}
            onDelete={() => handleClick(sub)}
          />
        ))}
      </Stack>
    </>
  )
}

const StyledChip = styled(Chip)(({ theme }) => ({
  minWidth: '100px',
  '&.MuiButtonBase-root.MuiChip-filled': {
    background: theme.palette.secondary.light,
    color: theme.palette.text.primary,
  },
  height: '40px',
  fontSize: '14px',
  fontWeight: 500,
  borderRadius: '20px',
}))

export default CasteList
