import { admissions } from './admissions'
import { clusters } from './clusters'
import { concessions } from './concessions'
import { counsellors } from './counsellors'
import { courses, quotas } from './courses'
import { leads } from './leads'
import { programs } from './programs'
import { studentServiceRequests } from './student-service-requests'
import { studentServices } from './student-services'
import { users } from './users'

export const services = {
  users: users,
  counsellors: counsellors,
  leads: leads,
  clusters: clusters,
  admissions: admissions,
  concessions: concessions,
  courses: courses,
  programs: programs,
  quotas: quotas,
  studentServices: studentServices,
  studentServiceRequests: studentServiceRequests,
}
