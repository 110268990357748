import { SingleSelect, axios } from '@campxdev/shared'
import { Button, Stack } from '@mui/material'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useImmer } from 'use-immer'
import {
  OfflineDates,
  OfflineTimeSlots,
  OnlineDates,
  OnlineTimeSlots,
  centers,
  leadRegistrationStore,
} from './service'
const defaultState = {
  examMode: null,
  examDate: null,

  examSlot: null,
  center: null,
  groupName: null,
  examStatus: null,
}

function LeadRegistrationFilters({ hideDialog, setFiltersApplied }) {
  const [filters, setFilters] = useImmer(defaultState)
  const _filters = leadRegistrationStore.useState((s) => s)
  useEffect(() => {
    setFilters({
      center: _filters.center,
      examDate: _filters.examDate,
      examMode: _filters.examMode,
      examSlot: _filters.examSlot,
      examStatus: _filters.examStatus,
      groupName: _filters.groupName,
    })
  }, [])

  const { data } = useQuery('think-exam-group-data', () => {
    return axios
      .get('/paymentx/leads/think-exam-groups')
      .then((res) => res.data)
  })

  const filterLeadsRegistration = () => {
    leadRegistrationStore.update((s) => {
      s.examDate = filters.examDate
      s.examMode = filters.examMode
      s.examSlot = filters.examSlot
      s.center = filters.center
      s.examStatus = filters.examStatus
      s.groupName = filters.groupName
    })

    setFiltersApplied(false)
    Object.keys(filters).some((key) => {
      if (
        !!filters[key] &&
        key != 'limit' &&
        key != 'skip' &&
        filters[key] != ''
      ) {
        setFiltersApplied(true)
      }
    })

    hideDialog()
  }
  const clearFilters = () => {
    leadRegistrationStore.update((s) => {
      s.examDate = null
      s.examMode = null
      s.examSlot = null
      s.center = null
      s.groupName = null
      s.examStatus = null
    })
    setFiltersApplied(false)
    hideDialog()
  }
  return (
    <>
      <Stack gap={3}>
        <SingleSelect
          value={filters.examMode || _filters.examMode}
          options={[
            {
              label: 'Offline',
              value: 'offline',
            },
            {
              label: 'Online',
              value: 'online',
            },
          ]}
          label={'Select Exam Mode'}
          onChange={(e) => {
            setFilters((s) => {
              s.examMode = e.target.value
            })
          }}
        />

        {filters.examMode == 'offline' && (
          <>
            <SingleSelect
              value={filters.examDate || _filters.examDate}
              options={OfflineDates}
              label={'Select Exam Date'}
              onChange={(e) => {
                setFilters((s) => {
                  s.examDate = e.target.value
                })
              }}
            />

            <SingleSelect
              value={filters.examSlot || _filters.examSlot}
              options={OfflineTimeSlots}
              label={'Select Exam Slot'}
              onChange={(e) => {
                setFilters((s) => {
                  s.examSlot = e.target.value
                })
              }}
            />

            <SingleSelect
              value={filters.center || _filters.center}
              options={centers}
              label={'Select Center'}
              onChange={(e) => {
                setFilters((s) => {
                  s.center = e.target.value
                })
              }}
            />
          </>
        )}
        {filters.examMode == 'online' && (
          <>
            <SingleSelect
              value={filters.examDate || _filters.examDate}
              options={OnlineDates}
              label={'Select Exam Date'}
              onChange={(e) => {
                setFilters((s) => {
                  s.examDate = e.target.value
                })
              }}
            />

            <SingleSelect
              value={filters.examSlot || _filters.examSlot}
              options={OnlineTimeSlots}
              label={'Select Exam Slot'}
              onChange={(e) => {
                setFilters((s) => {
                  s.examSlot = e.target.value
                })
              }}
            />
          </>
        )}
        <SingleSelect
          onChange={(e) => {
            setFilters((s) => {
              s.examStatus = e.target.value
            })
          }}
          value={filters.examStatus || _filters.examStatus}
          options={[
            {
              label: 'Registered',
              value: 'registered',
            },
            {
              label: 'Un Registered',
              value: 'unregistered',
            },
          ]}
          name="examStatus"
          label={'Exam Status '}
        />
        <SingleSelect
          value={filters.groupName || _filters.groupName}
          options={data?.map((item) => ({
            label: item.groupName,
            value: item.groupName,
          }))}
          name="groupName"
          label={'Group Name '}
          onChange={(e) => {
            setFilters((s) => {
              s.groupName = e.target.value
            })
          }}
        />

        <Stack direction="row" gap={2} mt={0.5}>
          <Button fullWidth onClick={() => filterLeadsRegistration()}>
            Apply Filters
          </Button>
          <Button variant="outlined" fullWidth onClick={() => clearFilters()}>
            Clear Filters
          </Button>
        </Stack>
      </Stack>
    </>
  )
}

export default LeadRegistrationFilters
