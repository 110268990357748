import { PageHeader, PermissionsStore, useRouteMatch } from '@campxdev/shared'
import { Close, Menu } from '@mui/icons-material'
import {
  Box,
  Breadcrumbs,
  IconButton,
  List,
  ListItemText,
  Typography,
  styled,
} from '@mui/material'
import React, { useState } from 'react'
import { Link, Navigate, Route, Routes } from 'react-router-dom'
import { links } from '../links'
import {
  StyledDrawer,
  StyledListItemButton,
  StyledListSubheader,
  StyledPageContent,
  StyledStack,
} from './styles'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 0,
  marginRight: '5px',
}))

export default function ReportsViewLayout() {
  const permissions = PermissionsStore.useState((s) => s).permissions

  const match: any = useRouteMatch('reports/:reportName')

  const test = match.url.split('/')

  const pageTitle = test[test.length - 1].toUpperCase()

  const [open, setOpen] = useState(true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const getIsActiveLink = (link: string) => {
    return (
      link.split('/').slice(-1)[0] ===
      match.url.split('/')[match.url.split('/').length - 1]
    )
  }

  return (
    <Box display={'flex'}>
      <StyledDrawer open={open}>
        <Box
          sx={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0 10px',
          }}
        >
          <IconButton onClick={handleDrawerClose}>
            <Close />
          </IconButton>
        </Box>
        <List>
          {links.map((item, parentIndex) => (
            <React.Fragment key={parentIndex}>
              <StyledListSubheader>{item.title}</StyledListSubheader>
              {item.items.map((link, index) => {
                const hasAccess = permissions[link.permissionKey] // Moved inside items.map

                return hasAccess ? (
                  <Link to={link.to} key={index}>
                    <StyledListItemButton isActive={getIsActiveLink(link.to)}>
                      <ListItemText primary={link.label} />
                    </StyledListItemButton>
                  </Link>
                ) : null
              })}
            </React.Fragment>
          ))}
        </List>
      </StyledDrawer>

      <StyledPageContent open={open}>
        <PageHeader
          noPadding
          title={
            <StyledStack direction={'row'} alignItems="center">
              {open ? (
                <Box className="empty"></Box>
              ) : (
                <StyledIconButton onClick={handleDrawerOpen}>
                  <Menu />
                </StyledIconButton>
              )}

              <Breadcrumbs separator={<Typography variant="h3">/</Typography>}>
                <Typography variant="h3">{pageTitle}</Typography>
              </Breadcrumbs>
            </StyledStack>
          }
        />
        <Routes>
          <Route
            index={true}
            element={<Navigate to={'lead-counsellor-report'} />}
          />
          {links
            .map((item: any) => item.items)
            .flat()
            .map((route, index) => (
              <Route
                path={route.to}
                element={<route.component />}
                key={index}
              />
            ))}
        </Routes>
      </StyledPageContent>
    </Box>
  )
}
