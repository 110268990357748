import { PageNotFound, Permission } from '@campxdev/shared'
import { EnrollPermissions } from '@campxdev/shared/src/shared-state/PermissionsStore'
import ReportsViewLayout from 'layouts/ReportLayout/ReportsViewLayout'
import ClusterDashboard from 'pages/ClusterDashboard'
import { Concessions } from 'pages/Concessions'
import LeadRegistration from 'pages/LeadRegistration'
import { StudentServiceRequests } from 'pages/StudentServiceRequests'
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { reportRoutes } from './reportRoutes'
const AdmissionDashboard = lazy(() => import('pages/Dashboard'))
const AdmissionLead = lazy(() => import('pages/Leads'))
const CET = lazy(() => import('pages/CET'))
const ViewPHD = lazy(() => import('pages/Phd/ViewPhd'))
const AdmissionReport = lazy(() => import('pages/Reports/AdmissionReport'))
const CounsellorReport = lazy(() => import('pages/Reports/FacultyReport'))
const SemesterReport = lazy(() => import('pages/Reports/SemesterReport'))
const DeletedAdmissionReport = lazy(
  () => import('pages/Reports/DeletedAdmissionReport'),
)
const LeadAdmissions = lazy(() => import('pages/LeadAdmissions/LeadAdmissions'))
const LeadDetails = lazy(() => import('pages/Leads/LeadDetails'))
const ViewAdmissions = lazy(() => import('pages/LeadAdmissions/ViewAdmissions'))
const AuditLogs = lazy(() => import('pages/AuditLogs'))
const Phd = lazy(() => import('pages/Phd'))
const AgriCET = lazy(() => import('pages/AgriCET/Applications'))
const AdmissionNotificationDetails = lazy(
  () => import('pages/LeadAdmissions/AdmissionDetails'),
)
const Counsellors = lazy(() => import('pages/Counsellors'))
const StudentCertificates = lazy(
  () => import('pages/Certificates/StudentCertificates'),
)
const CertificatesTemplates = lazy(() => import('pages/Certificates/Templates'))

export const baseRoutes = [
  {
    index: true,
    element: <Navigate to="/dashboard" />,
    permissionKey: Permission.CAN_ADMISSIONS_DASHBOARD_VIEW,
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    element: <AdmissionDashboard />,
    permissionKey: Permission.CAN_ADMISSIONS_DASHBOARD_VIEW,
  },

  {
    name: 'Cluster Dashboard',
    path: '/cluster-dashboard',
    element: <ClusterDashboard />,
    permissionKey: EnrollPermissions.CAN_CLUSTERS_DASHBOARD_VIEW,
  },

  {
    name: 'Leads',
    path: '/leads',
    permissionKey: Permission.CAN_LEADS_VIEW,
    children: [
      {
        path: ':id',
        element: <LeadDetails />,
        permissionKey: Permission.CAN_LEADS_VIEW,
      },
      {
        index: true,
        element: <AdmissionLead />,
        permissionKey: Permission.CAN_LEADS_VIEW,
      },
    ],
  },
  {
    name: 'Counsellors',
    path: '/counsellors',
    element: <Counsellors />,
    permissionKey: Permission.CAN_COUNSELLOR_VIEW,
  },
  {
    name: 'Concessions',
    path: '/concessions',
    element: <Concessions />,
    permissionKey: Permission.CAN_ENROLL_X_CONCESSION_VIEW,
  },

  {
    name: 'CET',
    path: '/cet',
    element: <CET />,
    permissionKey: Permission.CAN_CET_VIEW,
  },

  {
    name: 'Reports',
    path: '/reports',
    element: <ReportsViewLayout />,
    children: reportRoutes,
  },
  // {
  //   name: 'Reports',
  //   path: '/reports',
  //   children: [
  //     {
  //       index: true,
  //       element: <Navigate to="counsellor-reports" />,
  //       permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  //     },
  //     {
  //       index: true,
  //       element: <CounsellorReport />,
  //       path: 'counsellor-reports',
  //       permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  //     },
  //     {
  //       path: 'semester-reports',
  //       element: <SemesterReport />,
  //       permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  //     },
  //     {
  //       path: 'deleted-admission-reports',
  //       element: <DeletedAdmissionReport />,
  //       permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  //     },
  //     {
  //       path: 'admission-intake-report',
  //       element: <AdmissionIntakeReport />,
  //       permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  //     },
  //   ],
  // },

  {
    name: 'All Admissions',
    path: '/admissions',
    permissionKey: Permission.ADMISSIONS_VIEW,
    children: [
      {
        path: ':id',
        element: <ViewAdmissions />,
        permissionKey: Permission.ADMISSIONS_VIEW,
      },
      {
        path: ':id/:admissionId',
        element: <AdmissionNotificationDetails />,
        permissionKey: Permission.ADMISSIONS_VIEW,
      },
      {
        index: true,
        element: <LeadAdmissions />,
        permissionKey: Permission.ADMISSIONS_VIEW,
      },
    ],
  },
  {
    element: <StudentServiceRequests />,
    path: '/student-service-requests',
    permissionKey: Permission.CAN_STUDENT_CERTIFICATES_VIEW,
  },
  {
    element: <StudentCertificates />,
    path: '/certificates/student-certificates',
    permissionKey: Permission.CAN_STUDENT_CERTIFICATES_VIEW,
  },
  {
    element: <CertificatesTemplates />,
    path: '/certificates/templates',
    // permissionKey: Permission.CAN_ADMIN_VIEW,
  },
  {
    element: <Phd />,
    path: '/phd',
    permissionKey: Permission.CAN_PHD_FORM_VIEW,
  },
  {
    element: <ViewPHD />,
    path: '/phd/:id',
    permissionKey: Permission.CAN_PHD_FORM_VIEW,
  },
  {
    element: <AuditLogs />,
    path: '/audit-logs',
    permissionKey: Permission.CAN_ENROLL_X_AUDIT_LOGS,
  },
  {
    element: <AgriCET />,
    path: '/agricet',
    permissionKey: Permission.CAN_CET_VIEW,
  },
  {
    element: <PageNotFound />,
    path: '*',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    name: 'Lead Registration',
    element: <LeadRegistration />,
    path: '/lead-registration',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
]
