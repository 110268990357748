import { SingleSelect, Spinner, TextField } from '@campxdev/shared'
import { Close, Done } from '@mui/icons-material'
import { Box, IconButton, Link, Popover, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { services } from 'services'

export default function LeadSources({ control, setValue }) {
  const [inputValue, setInputValue] = useState(null)
  const [customSource, setCustomSource] = useState('')
  const [popperAnchor, setPopperAnchor] = useState(null)
  const [options, setOptions] = useState([])

  const { data, isLoading } = useQuery(
    'leadSources',
    services.leads.fetchLeadSources,
    {
      onSuccess(data) {
        setOptions(
          data?.map((item) => ({
            label: item?.name,
            value: item?.name,
          })),
        )
      },
    },
  )

  useEffect(() => {
    setValue('source', inputValue)
  }, [inputValue])

  if (isLoading) return <Spinner />
  return (
    <div>
      <Box>
        <SingleSelect
          label={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '6px',
              }}
            >
              <Typography>
                Lead Source
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Link
                onClick={(e) => {
                  setPopperAnchor(e.currentTarget)
                }}
              >
                Add Custom
              </Link>
            </Box>
          }
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value)
          }}
          name={'source'}
          options={options}
          required
        />
        <Popover
          open={Boolean(popperAnchor)}
          anchorEl={popperAnchor}
          onClose={() => {
            setPopperAnchor(null)
            setCustomSource('')
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box
            minWidth={'400px'}
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 30px 30px',
              padding: '10px',
              gap: '10px',
            }}
          >
            <TextField
              placeholder="Add Custom Source"
              size="small"
              value={customSource}
              onBlur={() => {
                setOptions([
                  ...options,
                  {
                    label: customSource,
                    value: customSource,
                  },
                ])
              }}
              onChange={(e) => {
                setCustomSource(e.target.value)
              }}
            />
            <IconButton
              onClick={() => {
                setPopperAnchor(null)
                setInputValue(customSource)
              }}
            >
              <Done color="success" />
            </IconButton>
            <IconButton
              onClick={() => {
                setValue('source', '')
                setPopperAnchor(null)
              }}
            >
              <Close color="error" />
            </IconButton>
          </Box>
        </Popover>
      </Box>
    </div>
  )
}
