import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { StyledCardBorder } from './styles'

function FeeCard({ data, feeDetails }) {
  const studentFirstPart = [
    { label: 'Roll.No', value: data?.admission?.rollNo ?? '-' },
    { label: 'Branch', value: data?.admission?.program?.branchCode ?? '-' },
    { label: 'Seat', value: data?.admission?.quota?.name ?? '-' },
    { label: 'Student Mobile', value: data?.admission?.mobile ?? '-' },
  ]

  const studentSecondPart = [
    { label: 'Name', value: data?.admission?.fullName ?? '-' },
    {
      label: 'Semester',
      value: data?.admission?.detained
        ? 'Detained'
        : data?.admission?.discontinued
        ? 'Discontinued'
        : data?.admission?.semNo ?? '-',
    },
    {
      label: 'ScholarShip',
      value: data?.admission?.hasScholarship ? 'Yes' : 'No',
    },
    {
      label: 'Parent Mobile',
      value: data?.admission?.guardianDetails?.fatherMobile ?? '-',
    },
  ]

  const totalLateFinesPaid = feeDetails?.map((row) => {
    const latefines = row[1]?.map((latefee) => {
      const fines = latefee?.debits?.reduce((acc, curr) => {
        if (curr.isFine && curr.debit > 0) {
          return acc + curr.debit
        }
        return acc
      }, 0)
      return fines
    })
    return latefines
  })

  const lateFines = feeDetails?.map((row) => {
    const latefine = row[1]?.map((latefee) => {
      const fines = latefee?.credits?.reduce((acc, curr) => {
        if (curr.isFine && curr.credit > 0) {
          return acc + curr.credit
        }
        return acc
      }, 0)
      return fines
    })
    return latefine
  })
  function amountDue(row: any) {
    const debitTotal = row?.debits?.reduce((acc, curr) => {
      if (!curr.isFine) {
        acc += curr.debit
      }
      return acc
    }, 0)

    const creditTotal = row?.credits?.reduce((acc, curr) => {
      if (!curr.isFine) {
        acc += curr.credit
      }

      return acc
    }, 0)

    const difference =
      creditTotal - debitTotal > 0 ? creditTotal - debitTotal : 0
    return difference
  }
  const totalFees = {
    payable: 0,
    paid: 0,
    due: 0,
  }

  function getTotalValues(row: any) {
    const totalDebits = row?.reduce((acc, curr) => {
      const debitTotal = curr?.debits?.reduce((acc, curr) => {
        if (!curr.isFine) {
          acc += curr.debit
        }
        return acc
      }, 0)

      return acc + debitTotal
    }, 0)

    const totalCredits = row?.reduce((acc, curr) => {
      const creditTotal = curr?.credits?.reduce((acc, curr) => {
        if (!curr.isFine) {
          acc += curr.credit
        }
        return acc
      }, 0)

      return acc + creditTotal
    }, 0)

    totalFees.payable += totalCredits
    totalFees.paid += totalDebits
    totalFees.due += Math.abs(totalCredits - totalDebits)

    return {
      totalDebits: totalDebits,
      totalCredits: totalCredits,
    }
  }

  function getReciptAndReference(row: any, key: string) {
    const data = []
    row?.map((transaction) => {
      if (key == 'paidDate') {
        data.push(moment(transaction.paidDate).format('YYYY-MM-DD'))
      }
      if (key == 'receiptDate') {
        data.push(moment(transaction.paidDate).format('YYYY-MM-DD'))
      }
      if (key == 'receiptNo') {
        data.push(transaction[key])
      }
    })
    return data
  }

  return (
    <StyledCardBorder>
      <Box
        className="school-details"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Box>
          <img
            src={data?.institution?.image?.url}
            alt="school-logo"
            width={'200px'}
          />
        </Box>

        <Typography variant="h6" sx={{ fontSize: '22px' }}>
          {data?.institution?.name}
        </Typography>
        <Typography variant="h6" textAlign={'center'} sx={{ fontSize: '16px' }}>
          {data?.institution?.recognitionDetails}
        </Typography>
        <Typography variant="h6" textAlign={'center'} sx={{ fontSize: '16px' }}>
          {data?.institution?.address}
        </Typography>
      </Box>
      <Divider />
      <Typography
        variant="h6"
        textAlign={'center'}
        sx={{ margin: '10px', fontSize: '22px' }}
      >
        STUDENT FEE CARD
      </Typography>
      <Divider />
      <Box
        className="student-details"
        sx={{
          display: 'flex',
          padding: '10px',
          gap: '30px',
          justifyContent: 'space-around',
        }}
      >
        <Box sx={{ display: 'flex', gap: '22px' }}>
          <Box>
            {studentFirstPart?.map((item, index) => (
              <Box key={index} sx={{ mt: '10px' }}>
                <Typography variant="h6">{item.label}</Typography>
              </Box>
            ))}
          </Box>
          <Box>
            {studentFirstPart?.map((item, index) => (
              <Box key={index} sx={{ mt: '10px' }}>
                <Typography variant="subtitle1">{item.value}</Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: '12px' }}>
          <Box>
            {studentSecondPart?.map((item, index) => (
              <Box key={index} sx={{ mt: '10px' }}>
                <Typography variant="h6">{item.label}</Typography>
              </Box>
            ))}
          </Box>
          <Box>
            {studentSecondPart?.map((item, index) => (
              <Box key={index} sx={{ mt: '10px' }}>
                <Typography variant="subtitle1">{item.value}</Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <img
          src={data?.admission?.photo}
          alt="student-photo"
          width={'150px'}
          height={'120px'}
        />
      </Box>

      <Divider />

      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SI.No</TableCell>
              <TableCell>Fee</TableCell>
              <TableCell>Payable</TableCell>
              <TableCell>Paid</TableCell>
              <TableCell>Rec.No(s)</TableCell>
              <TableCell>Rec.Date(s)</TableCell>
              <TableCell>Bank Details</TableCell>
              <TableCell>Due</TableCell>
              <TableCell>Excess paid</TableCell>
            </TableRow>
          </TableHead>

          {feeDetails?.map((fee, index) => (
            <>
              <TableRow>
                <TableCell colSpan={9}>{fee[0]} Year</TableCell>
              </TableRow>
              <>
                {fee[1][0]?.fees?.map((item, i) => (
                  <TableBody key={i}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{item?.feeType?.name}</TableCell>
                    <TableCell>
                      {item?.credits?.reduce((acc, curr) => {
                        if (!curr.isFine) {
                          acc = acc + curr.credit
                        }
                        return acc
                      }, 0)}
                    </TableCell>
                    <TableCell>
                      {item?.debits?.reduce((acc, curr) => {
                        if (!curr.isFine) {
                          acc = acc + curr.debit
                        }
                        return acc
                      }, 0)}
                    </TableCell>
                    <TableCell style={{ width: '200px' }}>
                      {getReciptAndReference(
                        item?.transactions,
                        'receiptNo',
                      ).join(', ')}
                      {getReciptAndReference(item?.oldTransactions, 'receiptNo')
                        .length > 0 &&
                        getReciptAndReference(item?.transactions, 'receiptNo')
                          .length > 0 && <>{', '}</>}
                      {getReciptAndReference(
                        item?.oldTransactions,
                        'receiptNo',
                      ).join(', ')}
                    </TableCell>
                    <TableCell>
                      {getReciptAndReference(
                        item?.transactions,
                        'paidDate',
                      ).join(', ')}
                      {getReciptAndReference(
                        item?.oldTransactions,
                        'receiptDate',
                      ).length > 0 &&
                        getReciptAndReference(item?.transactions, 'paidDate')
                          .length > 0 && <>{', '}</>}
                      {getReciptAndReference(
                        item?.oldTransactions,
                        'receiptDate',
                      ).join(', ')}
                    </TableCell>
                    <TableCell>{'-'}</TableCell>

                    <TableCell>
                      {amountDue(item)}
                      {lateFines[index][i] > 0 &&
                        totalLateFinesPaid[index][i] == 0 && (
                          <Typography variant="caption" color={'red'}>
                            {'(+' +
                              `${
                                lateFines[index][i] -
                                totalLateFinesPaid[index][i]
                              }` +
                              ' Late fee)'}
                          </Typography>
                        )}
                    </TableCell>
                    <TableCell>
                      {item?.debits?.reduce((acc, curr) => {
                        if (curr.isFine) {
                          acc = acc + curr.debit
                        }
                        return acc
                      }, 0)}
                    </TableCell>
                  </TableBody>
                ))}
              </>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography variant="h6">{fee[0]} year total</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">
                      {getTotalValues(fee[1][0]?.fees).totalCredits}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={4}>
                    <Typography variant="h6">
                      {getTotalValues(fee[1][0]?.fees).totalDebits}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">
                      {fee[1][0].yearDue > 0 ? fee[1][0].yearDue : 0}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">{0}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
            </>
          ))}

          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <Typography variant="h6">Grand total</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">{totalFees.payable / 2}</Typography>
              </TableCell>
              <TableCell colSpan={4}>
                <Typography variant="h6">{totalFees.paid / 2} </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">{totalFees.due / 2}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">{0}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Box>
    </StyledCardBorder>
  )
}

export default FeeCard
