import { axios } from '@campxdev/shared'

export function fetchData(params: any) {
  return axios
    .get('/paymentx/admissions/deleted', {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
}

export const batchOptions = Array.from({ length: 12 }, (_, i) => {
  return `${new Date().getFullYear() - i} - ${new Date().getFullYear() - i + 1}`
})
