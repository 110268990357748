import { Permission } from '@campxdev/shared'
import { EnrollPermissions } from '@campxdev/shared/src/shared-state/PermissionsStore'
import { AdmissionsIcon } from './icons'

const basePath = '/settings'
export const settingsMenu = [
  {
    path: basePath + '/admissions',
    title: 'Admissions',
    icon: AdmissionsIcon,
    children: [
      {
        path: '/lead-statuses',
        title: 'Lead Statuses',
        permissionKey: Permission.CAN_LEAD_STATUS_VIEW,
      },
      {
        path: '/lead-sources',
        title: 'Lead Sources',
        permissionKey: Permission.CAN_LEAD_STATUS_VIEW,
      },
      {
        path: '/quotas',
        title: 'Quotas',
        permissionKey: Permission.CAN_QUOTAS_VIEW,
      },
      {
        path: '/caste-details',
        title: 'Caste',
        permissionKey: Permission.CAN_CASTE_VIEW,
      },
      {
        path: '/clusters',
        title: 'Clusters',
        permissionKey: EnrollPermissions.CAN_CLUSTERS_DASHBOARD_VIEW,
      },
    ],
  },
  {
    path: basePath + '/student-services',
    title: 'Student Services',
    icon: AdmissionsIcon,
    permissionKey: Permission.CAN_CASTE_VIEW,
  },
  {
    path: basePath + '/application-profiles',
    title: 'People',
    icon: AdmissionsIcon,
    permissionKey: Permission.CAN_MANAGE_ENROLL_X_PROFILE_PERMISSIONS_VIEW,
  },
]
