import { CourseSelector, QuotaSelector, SingleSelect } from '@campxdev/shared'
import { Box, Button, Stack } from '@mui/material'
import { IPhaseLogsFilter } from 'interfaces/admission'
import { useEffect, useState } from 'react'
import { phaseStatus } from 'utils/constants'
import { PhaseLogsStore } from '../store'

const defaultFilters: IPhaseLogsFilter = {
  limit: 10,
  skip: 0,
  batch: '',
  search: '',
  courseId: '',
  phaseStatus: null,
  quotaId: '',
}

function SubFilters({ hideDialog, setAppliedFilters }) {
  const _filters = PhaseLogsStore.useState((s) => s)
  const [subFilters, setSubFilters] = useState<IPhaseLogsFilter>(defaultFilters)

  useEffect(() => {
    setSubFilters({ ..._filters })
  }, [])

  const onApplyFilters = () => {
    hideDialog()
    PhaseLogsStore.update((s) => {
      s.phaseStatus = subFilters.phaseStatus
      s.courseId = subFilters.courseId
      s.quotaId = subFilters.quotaId
    })
    setAppliedFilters(true)
  }

  const clearFilters = () => {
    hideDialog()
    setSubFilters(defaultFilters)
    PhaseLogsStore.update((s) => {
      s.phaseStatus = defaultFilters.phaseStatus
      s.courseId = defaultFilters.courseId
      s.quotaId = defaultFilters.quotaId
    })
    setAppliedFilters(false)
  }

  return (
    <Stack gap={3}>
      <Box>
        <SingleSelect
          name="phaseStatus"
          label={'Filter by Status'}
          options={phaseStatus?.map((item) => ({
            label: item.label,
            value: item.value,
          }))}
          value={
            subFilters?.phaseStatus === null ? 'all' : subFilters?.phaseStatus
          }
          onChange={(e) => {
            setSubFilters((prevSubFilters) => ({
              ...prevSubFilters,
              phaseStatus: e.target.value === 'all' ? '' : e.target.value,
            }))
          }}
        />
      </Box>

      <CourseSelector
        label="Filter by Degree"
        name="course"
        value={subFilters?.courseId === '' ? 'all' : subFilters?.courseId}
        onChange={(e) => {
          setSubFilters((prevSubFilters) => ({
            ...prevSubFilters,
            courseId: e.target.value === 'all' ? '' : e.target.value,
            quotaId: '',
          }))
        }}
      />

      <Box>
        <QuotaSelector
          label="Filter by Quota"
          name="quota"
          value={subFilters?.quotaId === '' ? 'all' : subFilters?.quotaId}
          onChange={(e) => {
            setSubFilters((prevSubFilters) => ({
              ...prevSubFilters,
              quotaId: e.target.value === 'all' ? '' : e.target.value,
            }))
          }}
          api="/paymentx/quotas"
          filters={{ courseId: subFilters.courseId }}
        />
      </Box>

      <Stack direction="row" gap={2} mt={0.5}>
        <Button fullWidth onClick={() => onApplyFilters()}>
          Apply Filters
        </Button>
        <Button variant="outlined" fullWidth onClick={() => clearFilters()}>
          Clear Filters
        </Button>
      </Stack>
    </Stack>
  )
}

export default SubFilters
