import {
  Chips,
  DateRangePicker,
  SingleCheckbox,
  SingleSelect,
  Spinner,
} from '@campxdev/shared'
import { IOption } from '@campxdev/shared/src/components/Input/types'
import { Box, Button, Stack, Typography } from '@mui/material'
import { batchOptions } from 'constants/UIConstants'
import { Store } from 'pullstate'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { services } from 'services'
import { useImmer } from 'use-immer'

export const admissionsStore = new Store({
  search: '',
  batch: batchOptions[0],
  quotaId: '',
  status: '',
  courseId: '',
  OCourseId: '',
  programId: '',
  branchCode: '',
  counselledById: '',
  startDate: null,
  lateralEntry: null,
  endDate: null,
  offset: 0,
  limit: 10,
  sortBy: '',
  sortOrder: 'desc',
  paidFee: null,
  admissionType: '',
  phase1: null,
  phase2: null,
  phase3: null,
})

const defaultState = {
  quotaId: '',
  status: '',
  courseId: '',
  programId: '',
  branchCode: '',
  counselledById: null,
  endDate: null,
  startDate: null,
  paidFee: null,
  admissionType: '',
  phase: '',
  lateralEntry: false,
}

export const AdmissionStatus = {
  OPEN: 'OPEN',
  IN_PROGRESS: 'IN_PROGRESS',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  CONFIRMED: 'CONFIRMED',
  ADMITTED: 'ADMITTED',
  DELETED: 'DELETED',
}

async function getInitialData() {
  let coursesData = await services.courses.fetchDomainCourses()
  let branchesData = await services.programs.fetchPrograms()
  let quotaData = await services.quotas.fetchDomainQuotas()
  let statusData = Object.values(AdmissionStatus)
  let counsellersData = await services.users.fetchCounsellors()
  const phasesData: IOption[] = [
    { label: 'Phase-1', value: 'Phase-1' },
    { label: 'Phase-2', value: 'Phase-2' },
    { label: 'Phase-3', value: 'Phase-3' },
  ]
  return {
    courses: coursesData,
    branches: branchesData,
    quotas: quotaData,
    statuses: statusData,
    counsellers: counsellersData,
    phases: phasesData,
  }
}

export default function AdmissionFilters({
  hideDialog,
  refetch,
  filtersApplied,
  isAllAdmissions,
}) {
  const [filters, setFilters] = useImmer(defaultState)
  const _filters = admissionsStore.useState((s) => s)

  const urlParams = new URLSearchParams(window.location.search)
  const selectedCourse = urlParams.get('course')

  const { data, isLoading, error } = useQuery('courses', () => getInitialData())

  const filterAdmissions = () => {
    admissionsStore.update((s) => {
      s.quotaId = filters.quotaId
      s.status = filters.status
      s.courseId = filters.courseId == 'all' ? '' : filters.courseId
      s.programId = filters.programId
      s.branchCode = filters.branchCode
      s.counselledById = filters.counselledById
      s.endDate = filters.endDate
      s.startDate = filters.startDate
      s.paidFee = filters.paidFee != null ? filters.paidFee : undefined
      s.offset = 0
      s.admissionType = filters.admissionType
      s.phase1 = filters?.phase
        ? filters.phase == 'Phase-1'
          ? true
          : false
        : undefined
      s.phase2 = filters?.phase
        ? filters.phase == 'Phase-2'
          ? true
          : false
        : undefined
      s.phase3 = filters?.phase
        ? filters.phase == 'Phase-3'
          ? true
          : false
        : undefined
      s.lateralEntry = filters.lateralEntry
    })

    filtersApplied(false)
    Object.keys(filters).some((key) => {
      if (
        (!!filters[key] &&
          key != 'limit' &&
          key != 'batch' &&
          key != 'offset') ||
        filters.status != '' ||
        filters.paidFee == true ||
        filters.lateralEntry == true
      ) {
        filtersApplied(true)
      }
    })
    hideDialog()
  }
  const clearFilters = () => {
    admissionsStore.update((s) => {
      s.quotaId = ''
      s.status = ''
      s.courseId = ''
      s.OCourseId = ''
      s.programId = ''
      s.branchCode = ''
      s.counselledById = ''
      s.endDate = null
      s.startDate = null
      s.paidFee = null
      s.admissionType = ''
      s.phase1 = null
      s.phase2 = null
      s.phase3 = null
      s.lateralEntry = false
    })
    filtersApplied(false)
    hideDialog()
  }

  useEffect(() => {
    setFilters({
      ..._filters,
      phase:
        _filters?.phase1 == true
          ? 'Phase-1'
          : _filters?.phase2 == true
          ? 'Phase-2'
          : _filters?.phase3 == true
          ? 'Phase-3'
          : '',
    })
  }, [])

  const onSearch = (v) => {
    // setFilters({ ...filters, search: v })
  }

  if (isLoading) {
    return <Spinner />
  }

  const handlePhaseChange = (e) => {
    const newValue = e.target.value
    setFilters({ ...filters, phase: newValue })
  }

  const phaseOptions: IOption[] = data?.phases
    ? [
        ...data.phases.map((item: IOption) => ({
          label: item.label,
          value: item.value,
        })),
      ]
    : []

  const programData = isAllAdmissions
    ? data?.branches?.filter((item) => item.courseId == filters.courseId)
    : data?.branches?.filter((item) => item.courseId == selectedCourse)

  return (
    <>
      <Stack gap={3}>
        <Box>
          {isAllAdmissions && (
            <Box>
              <SingleSelect
                value={filters?.courseId === '' ? 'all' : filters?.courseId}
                label="Filter by Degree(s)"
                onChange={(e) => {
                  setFilters((s) => {
                    s.courseId = e.target.value === 'all' ? '' : e.target.value
                  })
                }}
                options={
                  data && data?.courses
                    ? [
                        {
                          label: 'All',
                          value: 'all',
                        },
                        ...data?.courses?.map((item) => ({
                          label: item?.courseName,
                          value: item?.id,
                        })),
                      ]
                    : []
                }
              />
            </Box>
          )}

          <>
            {programData?.length > 0 && (
              <>
                <Box>
                  <SingleSelect
                    value={
                      filters?.programId === '' ? 'all' : filters?.programId
                    }
                    label="Filter by Programs"
                    onChange={(e) => {
                      setFilters((s) => {
                        s.programId =
                          e.target.value === 'all' ? '' : e.target.value
                      })
                    }}
                    options={
                      programData
                        ? [
                            {
                              label: 'All',
                              value: 'all',
                            },

                            ...programData?.map((item) => ({
                              label: item?.branchName,
                              value: item?.id,
                            })),
                          ]
                        : []
                    }
                  />
                </Box>
              </>
            )}

            {(filters.courseId !== '' || !isAllAdmissions) && (
              <Box>
                <Typography variant="subtitle2" my={1}>
                  Filter by Quota
                </Typography>
                <Chips
                  selected={filters?.quotaId}
                  onSelected={(newState) => {
                    setFilters((s) => {
                      s.quotaId = newState
                    })
                  }}
                  list={
                    isAllAdmissions
                      ? data?.quotas
                          ?.filter((item) => {
                            return item?.courseId == filters.courseId
                          })
                          ?.map((item) => ({
                            label: item?.name,
                            value: item?.id,
                          })) ?? []
                      : data?.quotas
                          ?.filter((item) => {
                            return item?.courseId == selectedCourse
                          })
                          ?.map((item) => ({
                            label: item?.name,
                            value: item?.id,
                          })) ?? []
                  }
                />
              </Box>
            )}
          </>
        </Box>

        <Box>
          <SingleSelect
            value={filters?.phase}
            label="Filter by Phase"
            onChange={handlePhaseChange}
            options={phaseOptions}
          />
        </Box>

        <Box>
          <Typography variant="subtitle2" my={1}>
            Filter by Status
          </Typography>
          <Chips
            selected={filters?.status}
            onSelected={(newState) => {
              setFilters((s) => {
                s.status = newState
              })
            }}
            list={data?.statuses?.map((item) => ({
              label: item,
              value: item,
            }))}
          />
        </Box>

        <Box>
          <Typography variant="subtitle2" my={1}>
            Filter by Admission Type
          </Typography>
          <Chips
            selected={filters?.admissionType}
            onSelected={(newState) => {
              setFilters((s) => {
                s.admissionType = newState
              })
            }}
            list={[
              { label: 'ATS', value: 'ATS' },
              { label: 'Walk In', value: 'Walk In' },
            ]}
          />
        </Box>

        <Box>
          <SingleSelect
            value={filters?.counselledById}
            label="Filter by Counseller"
            onChange={(e) => {
              setFilters((s) => {
                s.counselledById = e.target.value
              })
            }}
            options={data?.counsellers?.counsellors?.map((item) => ({
              label: item?.fullName,
              value: item?._id,
            }))}
          />
        </Box>

        <Box>
          <SingleCheckbox
            name={'lateralEntry'}
            label="Lateral Students"
            onChange={(e) => {
              setFilters((s) => {
                s.lateralEntry = !filters.lateralEntry
              })
            }}
            checked={filters.lateralEntry}
          />
        </Box>

        <Box>
          <DateRangePicker
            label="Filter by Date"
            size="medium"
            value={{ start: filters?.startDate, end: filters?.endDate }}
            onChange={(newValue) => {
              setFilters((s) => {
                s.endDate = newValue.end
                s.startDate = newValue.start
              })
            }}
          />
        </Box>

        <Stack direction="row" gap={2} mt={0.5}>
          <Button fullWidth onClick={() => filterAdmissions()}>
            Apply Filters
          </Button>
          <Button variant="outlined" fullWidth onClick={() => clearFilters()}>
            Clear Filters
          </Button>
        </Stack>
      </Stack>
    </>
  )
}
