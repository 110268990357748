import { ActionButton, Spinner, axios, axiosErrorToast } from '@campxdev/shared'
import { queryClient } from '@campxdev/shared/src/contexts/QueryClientProvider'
import { Box, Typography, styled } from '@mui/material'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

function MovetoNotification({ clear, ids, close }) {
  const [state, setState] = useState({
    selected: null,
  })
  let { id } = useParams()

  const urlParams = new URLSearchParams(window.location.search)
  const course = urlParams.get('course')
  const isAllAdmissions = id == 'all-admissions'

  const { data, isLoading } = useQuery('admission-notifications', () =>
    axios
      .get('/paymentx/admission-notifications', {
        params: { courseId: isAllAdmissions ? null : course },
      })
      .then((res) => res.data.notifications),
  )

  const onSelected = (data) => {
    setState((prev) => ({
      ...prev,
      selected: data,
    }))
  }

  const handleSubmit = async () => {
    try {
      await axios.put('/paymentx/admissions/assign-notification', {
        admissionIds: ids?.map((i) => i),
        notificationId: state.selected.id,
      })
      queryClient.invalidateQueries('notification-admissions')
      toast.success('Updated Successfully')
      close()
      clear()
    } catch (err) {
      close()
      clear()
      axiosErrorToast(err)
    }
  }

  return (
    <>
      <Box sx={{ height: '500px', overflowY: 'auto' }}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Box padding="16px 10px">
            {data?.map((item, index) => (
              <SearchResultCard
                key={index}
                data={item}
                onSelected={onSelected}
                selected={state.selected}
              />
            ))}
          </Box>
        )}
      </Box>
      <Box display="flex" gap="20px" padding={'10px 0'}>
        <ActionButton onClick={close} variant="outlined" fullWidth>
          Cancel
        </ActionButton>
        <ActionButton onClick={handleSubmit} fullWidth>
          Submit
        </ActionButton>
      </Box>
    </>
  )
}

const SearchResultCard = ({ onSelected, data, selected }) => {
  return (
    <StyledResultCard
      selected={data?.id === selected?.id}
      onClick={() => {
        onSelected(data)
      }}
    >
      <Typography>{data?.name}</Typography>
      <Typography variant="subtitle1">Batch: {data?.batch}</Typography>
      <Typography variant="subtitle1">{data?.course?.courseName}</Typography>
    </StyledResultCard>
  )
}

const StyledResultCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>(({ theme, selected }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 200px',
  outline: selected
    ? `2px solid ${theme.palette.primary.main}`
    : theme.borders.grayLight,
  padding: '16px',
  borderRadius: '20px',
  cursor: 'pointer',
  marginTop: '10px',
}))

export default MovetoNotification
