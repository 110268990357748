import { ActionButton, axios, axiosErrorToast } from '@campxdev/shared'
import { urlTenantKey } from '@campxdev/shared/src/contexts/Providers'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, LinearProgress, Typography } from '@mui/material'
import { AxiosRequestConfig } from 'axios'
import { Store } from 'pullstate'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Step1Form from './Step1Form'
import Step2Form from './Step2Form'
import Step3Form from './Step3Form'
import { paymentMethods } from './paymentMethods'
import { updateLeadDetails } from './services'
import {
  StyledFooter,
  StyledFormContainer,
  StyledHeading,
  StyledLogoContainer,
} from './styles'
import { fetchDynamicSchema } from './validation'
import moment from 'moment'

export const formStore = new Store({
  step: 1,
  leadDetails: null,
  payment: {
    paymentMethod: paymentMethods[0].value,
  },
  rzrPayRes: null,
  clientLogo: null,
  rzyPayKey: null,
  type: null,
  formErrors: null,
  collegeName: null,
})

function FormFooter({ onSubmit, posting }) {
  const { step, formErrors } = formStore.useState((s) => s)
  return (
    <>
      <StyledFooter>
        <Box className="content">
          <LinearProgress
            variant="determinate"
            value={step * 25}
            sx={{ height: '8px', borderRadius: '5px' }}
          />
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              padding: '10px 0',
            }}
          >
            {step === 1 ? (
              <Box width="100%"></Box>
            ) : (
              <ActionButton
                fullWidth
                variant="outlined"
                onClick={() => {
                  if (step === 1) return
                  formStore.update((s) => {
                    s.step = s.step - 1
                  })
                }}
              >
                Back
              </ActionButton>
            )}

            <ActionButton
              loading={posting}
              fullWidth
              onClick={async () => {
                onSubmit()
              }}
            >
              Submit
            </ActionButton>
          </Box>
        </Box>
      </StyledFooter>
    </>
  )
}

function AdmissionsForm() {
  let { id, collegeName } = useParams()
  const queryClient = useQueryClient()
  const [search] = useSearchParams()
  const { step } = formStore.useState()
  const state = formStore.useState()
  const navigate = useNavigate()

  const { handleSubmit, control, watch, setValue, clearErrors } = useForm({
    resolver: yupResolver(fetchDynamicSchema(step)),
  })

  const { mutate, isLoading: posting } = useMutation(updateLeadDetails, {
    onSuccess() {
      queryClient.invalidateQueries('lead-details')
      if (step == 3) {
        formStore.update((s) => {
          s.step = 4
        })
      }
    },
    onError(error: any) {
      axiosErrorToast(error, 'Unable to save details')
    },
  })

  const [disabled, setDisabled] = useState(true)

  async function onSubmit(formData) {
    formStore.update((s) => {
      s.leadDetails = {
        ...formData,
      }
    })

    if (step <= 2) {
      formStore.update((s) => {
        s.step = s.step + 1
      })
    }

    if (step == 3) {
      handleCreateAdmission(formData)
    }
  }
  const submitForm = handleSubmit(onSubmit, (err) => {
    // eslint-disable-next-line no-console
    console.log(err)
  })

  const validateFormData = async () => {
    await axios
      .get('/square/open-admissions/validate-open-admission', {
        params: {
          interHallTicket: state?.leadDetails?.interHallTicketNumber,
          courseId: state?.leadDetails?.courseId,
          programId: state?.leadDetails?.branchCode,
          mobile: state?.leadDetails?.mobile,
        },
      })
      .then((res) => {
        // handleCreateAdmission()
        setDisabled(false)
      })
      .catch((err) => {
        setDisabled(true)
        axiosErrorToast(err)
      })
  }

  var institutionVal = window.location.pathname.split('/')[2]
  async function handleCreateAdmission(formData) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/square/open-admissions/submit-application-form',
      data: {
        ...state.leadDetails,
        dob: moment(state.leadDetails.dob).format('YYYY-MM-DD'),
        admissionNotificationId: id,
        parentName: state.leadDetails.fatherName,
        courseId: formData?.courseId,
        branchCode: formData?.branchCode,
        batch: formData?.batch,
        choice2: formData?.choice2,
        choice3: formData?.choice3,
        remarks: 'Admission Created From Open Admissions Link',
        admissionType: 'Walk In',
        instituteId: institutionVal,
      },
    }

    try {
      await axios(config)
        .then((res) => {
          toast.success('Admission created successfully')
          queryClient.invalidateQueries('admission')
          queryClient.invalidateQueries('notification-admissions')
          window.location.href = `/${urlTenantKey}/${institutionVal}/public-forms/admission-form/${res.data.id}/thank-you`
        })
        .catch((err) => {
          axiosErrorToast(err)
        })
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? 'Unable to create admission',
      )
    }
  }

  return (
    <StyledFormContainer>
      <StyledLogoContainer>
        <img src={''} width={180} />
        <Typography variant="h6">{collegeName} Admission Form</Typography>
      </StyledLogoContainer>
      <Box sx={{ paddingBottom: '80px' }}>
        <StyledHeading variant="h1">{`Step ${step}`}</StyledHeading>
        {step === 1 && <Step1Form control={control} watch={watch} />}
        {step === 2 && (
          <Step2Form
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
          />
        )}
        {step === 3 && <Step3Form control={control} watch={watch} />}
      </Box>
      <FormFooter onSubmit={submitForm} posting={posting} />
    </StyledFormContainer>
  )
}

export default AdmissionsForm
