import { Permission } from '@campxdev/shared'
import AdmissionIntakeReport from 'pages/Reports/AdmissionIntakeReport/AdmissionIntakeReport'
import AdmissionPhaseReport from 'pages/Reports/AdmissionPhaseReport'
import AdmissionStatusReport from 'pages/Reports/AdmissionStatusReport/AdmissionStatusReport'
import BranchWiseAdmissions from 'pages/Reports/BranchWiseAdmissions/BranchWiseAdmissions'
import DeletedAdmissionReport from 'pages/Reports/DeletedAdmissionReport'
import CounsellorReport from 'pages/Reports/FacultyReport/FacultyReport'
import LeadAdmissionReport from 'pages/Reports/LeadAdmissionReport'
import LeadCounsellorReport from 'pages/Reports/LeadCounsellorReport'
import SemesterReport from 'pages/Reports/SemesterReport'
export const links = [
  {
    title: 'Reports',
    items: [
      {
        label: 'Lead Counsellor Report',
        to: `lead-counsellor-report`,
        component: LeadCounsellorReport,
        permissionKey: Permission.CAN_LEAD_COUNSELLOR_REPORT_VIEW,
      },
      {
        label: 'Counsellor Reports',
        to: `counsellor-reports`,
        component: CounsellorReport,
        permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
      },
      {
        label: 'Semester Reports',
        to: `semester-reports`,
        component: SemesterReport,
        permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
      },
      {
        label: 'Deleted Admissions',
        to: `deleted-admission-reports`,
        component: DeletedAdmissionReport,
        permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
      },
      {
        label: 'Lead-Admissions Report',
        to: `lead-admissions-reports`,
        component: LeadAdmissionReport,
        permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
      },
    ],
  },
  {
    title: 'Admission Reports',
    items: [
      {
        label: 'Admissions Intake',
        to: `admission-intake-report`,
        component: AdmissionIntakeReport,
        permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
      },
      {
        label: 'Branch Wise Admissions',
        to: `branch-wise-admissions`,
        component: BranchWiseAdmissions,
        permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
      },
      {
        label: 'Admission Status Report',
        to: `admission-status-report`,
        component: AdmissionStatusReport,
        permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
      },
      {
        label: 'Phase Log Report',
        to: `phase-logs-report`,
        component: AdmissionPhaseReport,
        permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
      },
    ],
  },
]
