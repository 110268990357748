import { Box, styled } from '@mui/material'

export const StyledCardBorder = styled(Box)(({ theme }) => ({
  border: theme.borders.grayLight,
  display: 'inline-block',
  width: '100%',
  height: 'auto',
}))

export const StyledSchoolHeader = styled(Box)({
  textAlign: 'center',
})
