import { axios } from '@campxdev/shared'
import { AxiosRequestConfig } from 'axios'
import moment from 'moment'
import * as XLSX from 'xlsx'

export const fetchLeads = async (params) => {
  const data = {
    ...params,
    startDate: params.startDate
      ? moment(params.startDate).format('YYYY-MM-DD')
      : undefined,
    endDate: params.endDate
      ? moment(params.endDate).format('YYYY-MM-DD')
      : undefined,
    source: params.source.map((obj) => obj.value),
  }
  let leadData = await axios.get('/paymentx/leads', {
    params: { ...data },
  })
  return leadData.data
}

export const deleteLead = async ({ leadIds }) => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `/paymentx/leads/bulk-delete`,
    data: {
      leadIds: leadIds,
    },
  }

  return await axios(config)
}

export const createLead = async ({ data }) => {
  const config: AxiosRequestConfig = {
    method: 'POST',
    url: '/paymentx/leads',
    data: {
      ...data,
      permissions: [],
    },
  }

  return await axios(config)
}

export async function exportToExcel({ skip, limit, ...params }) {
  let excelData
  const leads = await fetchLeads(params)
  if (leads?.count !== 0) {
    excelData = leads?.result?.map((item, index) => {
      return {
        'Lead ID': item?.uniqueId || '',
        firstName: item?.firstName || '',
        lastName: item?.lastName || '',
        email: item?.email || '',
        'Student Name': item?.fullName || '',
        mobile: item?.mobile || '',
        'Alternate Mobile': item?.alternateMobile || '',
        source: item?.source || '',
        'Lead Type': item?.type || '',
        'Interested Degree': item?.course?.courseName || '',
        'Interested Program': item?.program?.branchName || '',
        'Assigned to': item?.counsellor?.fullName || '',
        'Last updated in': moment(item?.updatedAt).format('DD-MM-YYYY') || '',
        Status: item?.status || '',
        Remarks: item?.activities?.map((item) => item.remarks)?.join('  ||  '),
      }
    })
  } else {
    excelData = [
      {
        'Lead ID': '',
        'Student Name': '',
        Source: '',
        'Lead Type': '',
        'Interested Degree': '',
        'Interested Program': '',
        'Assigned to': '',
        'Last updated in': '',
        Status: '',
      },
    ]
  }
  const fileName = `Leads.xlsx`
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(
    wb,
    ws,
    `${moment().format('DD-MM-YYYY')} - Users`,
  )
  XLSX.writeFile(wb, fileName)
}

export const fetchClusterHeadData = () => {
  return axios.get('/paymentx/clusters').then((res) => res.data)
}
