import { axios, axiosErrorToast } from '@campxdev/shared'
import { AxiosRequestConfig } from 'axios'
import { services } from 'services'
import * as XLSX from 'xlsx'

export const fetchData = async () => {
  let courses = await services.courses.fetchDomainCourses()
  let quotas = await services.quotas.fetchDomainQuotas()

  return {
    courses: courses,
    quotas: quotas,
  }
}

export const getCounsellorReport = async (formData) => {
  let counsellorsData = await axios.get(
    '/paymentx/admissions/counsellor-report',
    {
      params: {
        courseId: formData.courseId,
        batch: formData.batch,
        quotaId: formData.quotaId && formData.quotaId,
      },
    },
  )
  return counsellorsData.data
}

export const handleExport = async (data) => {
  const excelData = data?.map((item) => ({
    Counsellor: item?.counsellorName,
    ASSIGNED: item?.ASSIGNED,
    'IN-PROGRESS': item?.IN_PROGRESS,

    CONFIRMED: item?.CONFIRMED,
    TOTAL: item?.IN_PROGRESS + item?.ASSIGNED + item?.CONFIRMED,
  }))

  const fileName = `CounsellorReport.xlsx`
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'report')
  XLSX.writeFile(wb, fileName)
}

export const getLeadsByStatus = async ({ params, filters }) => {
  const movedToAdmissionsBody = {
    ...params,
    status: [params.status],
  }
  const config: AxiosRequestConfig = {
    method: 'GET',
    url:
      filters.status != 'Moved to admissions'
        ? `/paymentx/admissions`
        : `/paymentx/leads`,
    params:
      filters.status != 'Moved to admissions' ? params : movedToAdmissionsBody,
  }
  try {
    return await axios(config)
  } catch (error) {
    axiosErrorToast(error)
  }
}
