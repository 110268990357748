import {
  ActionButton,
  FormSingleSelect,
  Spinner,
  axios,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import { batchOptions } from 'constants/UIConstants'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import * as yup from 'yup'
import { importStore } from './Leads'

function fetchCourseData() {
  return axios.get('/paymentx/domain/courses').then((res) => res.data)
}

const schema = yup.object().shape({
  courseId: yup.string().required('Degree is required'),
})

function ImportLead({ open }) {
  const filters = importStore.useState((s) => s)
  const { data, isLoading, error } = useQuery(['courseData'], () =>
    fetchCourseData(),
  )
  const { control, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  })

  const onSubmit = async (formData) => {
    importStore.update((s) => {
      s.courseId = formData?.courseId
      s.batch = formData?.batch
    })
    open()
  }
  const onError = (e) => {
    // eslint-disable-next-line no-console
    console.log(e)
  }

  if (isLoading) {
    return <Spinner />
  }
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit, onError)}
        style={{ width: '100%' }}
      >
        <Stack gap={3}>
          <FormSingleSelect
            label={'Degree'}
            name={'courseId'}
            control={control}
            options={
              data?.map((item) => ({
                label: item.courseName,
                value: item.id,
              })) ?? []
            }
            required
            fullWidth
          />

          <FormSingleSelect
            label={'Batch'}
            name={'batch'}
            control={control}
            options={
              batchOptions?.map((item) => ({
                label: item,
                value: item,
              })) ?? []
            }
            fullWidth
          />

          <ActionButton type="submit" fullWidth>
            Next
          </ActionButton>
        </Stack>
      </form>
    </>
  )
}

export default ImportLead
