import {
  ActionButton,
  FormRadioGroup,
  FormSingleSelect,
  FormTextField,
  Spinner,
  axiosErrorToast,
} from '@campxdev/shared'
import {
  instituitionKey,
  urlTenantKey,
} from '@campxdev/shared/src/contexts/Providers'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Container, Stack, Typography } from '@mui/material'
import { AxiosRequestConfig } from 'axios'
import { currentYearBatchOptions } from 'constants/UIConstants'
import { useForm } from 'react-hook-form'
import { useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import axios from './utils/axios'

const collegeNames = {
  aupulse: 'Anurag University',
  campx_dev: 'Anurag University',
}

async function fetchCourseData() {
  let courseData = await axios.get('/paymentx/domain/courses')
  let branchData = await axios.get('/paymentx/domain/programs')
  return {
    course: courseData.data,
    branch: branchData.data,
  }
}
const mobileRegx: any = new RegExp(/^[6-9]\d{9}$/)

const aadharRegex = new RegExp(
  '^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}s[0-9]{4}s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)',
)

const schema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  mobile: yup
    .string()
    .matches(mobileRegx, 'Enter a valid 10 digit mobile number')
    .nullable()
    .required('Mobile number is required'),
  gender: yup.string().required('Gender is required'),
  courseId: yup.string().required('Degree is required'),
  programId: yup.string().required('Program is required'),
  email: yup.string().required('Email is required'),
  batch: yup.string().required('Batch is required'),
  parentName: yup.string().required("Father's name is required"),
  parentMobile: yup
    .string()
    .matches(mobileRegx, 'Enter a valid 10 digit mobile number')
    .nullable()
    .required("Father's mobile number is required"),

  aadhaarNo: yup
    .string()
    .required('Aadhar number is required')
    .matches(aadharRegex, 'Enter a valid aadhar number')
    .nullable(),
  interHallTicketNumber: yup
    .string()
    .required('Inter Hall Ticket number is required'),
})

function OpenAdmissionForm({
  data,
  notificationId,
}: {
  data?: null
  notificationId?: null
}) {
  const queryClient = useQueryClient()
  const { control, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(schema),
  })

  const {
    isLoading,
    data: courseData,
    error,
  } = useQuery(['courses'], () => fetchCourseData())

  const courseId = watch().courseId
  const programData = courseId
    ? courseData?.branch?.filter((item) => {
        return item?.courseId === courseId
      })
    : []

  const onSubmit = async (formData) => {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/paymentx/admissions/open-admission',
      data: {
        ...formData,
        guardianDetails: {
          fatherName: formData.parentName,
          fatherMobile: formData.parentMobile,
        },
        personalDetails: {
          aadhaarNo: formData.aadhaarNo,
        },
        admissionType: 'Walk In',
        remarks: 'Admission Created from open admissions',
      },
    }

    try {
      await axios(config)
        .then((res) => {
          toast.success('Admission created successfully')
          window.location.href = `/${urlTenantKey}/${instituitionKey}/public-forms/admission-form/${res?.data?.uniqueId}/thank-you`
        })
        .catch((err) => {
          axiosErrorToast(err)
        })
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? 'Unable to create admission',
      )
    }
  }
  const onError = (e) => {
    axiosErrorToast(e)
  }

  const handleChange = (e, field) => {
    setValue(field, e.target.value?.toUpperCase())
  }

  if (isLoading) {
    return <Spinner />
  }
  return (
    <Box sx={{ margin: '20px 0px' }}>
      <Container maxWidth={'sm'}>
        <Typography variant="h6" textAlign={'center'} sx={{ margin: '10px' }}>
          {instituitionKey.toUpperCase() ?? 'CampX'} ADMISSION FORM
        </Typography>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Stack gap={2.5}>
            <FormTextField
              label={'First Name'}
              name={'firstName'}
              control={control}
              onChange={(e) => {
                handleChange(e, 'firstName')
              }}
              required
            />
            <FormTextField
              label={'Last Name'}
              name={'lastName'}
              control={control}
              onChange={(e) => {
                handleChange(e, 'lastName')
              }}
              required
            />
            <FormTextField
              label={'Mobile Number'}
              name={'mobile'}
              control={control}
              required
            />
            <FormTextField
              label={'Email'}
              name={'email'}
              control={control}
              required
            />
            <FormTextField
              label={'Aadhaar Number'}
              name={'aadhaarNo'}
              control={control}
              required
            />
            <FormRadioGroup
              row
              required
              label={'Gender'}
              name={'gender'}
              control={control}
              options={[
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
              ]}
            />
            <FormSingleSelect
              label={'Course'}
              name={'courseId'}
              control={control}
              options={courseData?.course?.map((item) => {
                return { label: item.courseName, value: item.id }
              })}
              required
            />
            <FormSingleSelect
              label={'Program'}
              name={'programId'}
              control={control}
              options={
                courseId
                  ? programData?.map((item) => {
                      return {
                        label: item?.branchName,
                        value: item?.id,
                      }
                    })
                  : []
              }
              required
            />

            <FormSingleSelect
              label={'Batch'}
              name={'batch'}
              control={control}
              options={currentYearBatchOptions?.map((item, index) => ({
                label: item,
                value: item,
              }))}
              required
            />
            <FormTextField
              label={"Father's Name"}
              name={'parentName'}
              control={control}
              required
            />
            <FormTextField
              label={"Father's Mobile"}
              name={'parentMobile'}
              control={control}
              required
            />
            <FormTextField
              label={'10+2 Ht.No'}
              name={'interHallTicketNumber'}
              control={control}
              required
            />
            <FormTextField
              minRows={5}
              multiline
              label={'Comments'}
              name={'remarks'}
              control={control}
            />

            <Stack direction="row" gap={2} sx={{ margin: '10px' }}>
              <ActionButton type="submit">Submit Application</ActionButton>
            </Stack>
          </Stack>
        </form>
      </Container>
    </Box>
  )
}

export default OpenAdmissionForm
