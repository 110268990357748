import {
  ActionButton,
  FormTextField,
  axios,
  axiosErrorToast,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import { AxiosRequestConfig } from 'axios'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import CasteList from './CasteList'

const schema = yup.object().shape({
  name: yup.string().required('Caste is required'),
  subCaste: yup.string().required('Sub Caste is required'),
})

export default function CasteForm({ data, hideDialog }) {
  const [enableTextField, setEnableTextField] = useState(false)
  const [existedData, setExistedData] = useState(data?.subCaste ?? [])

  const { control, watch, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: data?.name ?? '',
    },
  })
  const queryClient = useQueryClient()

  async function onSubmit(formData) {
    const config: AxiosRequestConfig = {
      method: data ? 'PUT' : 'POST',
      url: data ? `/paymentx/castes/${data.id}` : '/paymentx/castes',
      data: {
        ...formData,
        subCaste: formData.subCaste.replace(/\s*,\s*/g, ',').split(','),
      },
    }
    try {
      await axios(config)
      toast.success(
        data ? 'Caste Updated Successfully' : 'Caste Created Successfully',
      )
      queryClient.invalidateQueries('caste-details')
      hideDialog()
    } catch (error) {
      axiosErrorToast(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2.5}>
        <FormTextField
          control={control}
          name="name"
          required
          label={'Caste'}
          disabled={data ? true : false}
        />
        {enableTextField && (
          <>
            <FormTextField
              control={control}
              name="otherCaste"
              label="Enter Caste Name"
            />
          </>
        )}
        {data && (
          <>
            <CasteList caste={data} close={hideDialog} />
          </>
        )}
        <FormTextField
          control={control}
          required
          name="subCaste"
          label={'Sub Caste'}
        />

        <Stack direction="row" gap={2} mt={2}>
          <ActionButton type="submit" fullWidth>
            {data ? 'Update Caste' : 'Create Caste'}
          </ActionButton>
          <ActionButton
            variant="outlined"
            fullWidth
            onClick={() => hideDialog()}
          >
            Cancel
          </ActionButton>
        </Stack>
      </Stack>
    </form>
  )
}
