import { Permission } from '@campxdev/shared'
import AdmissionIntakeReport from 'pages/Reports/AdmissionIntakeReport/AdmissionIntakeReport'
import AdmissionStatusReport from 'pages/Reports/AdmissionStatusReport/AdmissionStatusReport'
import BranchWiseAdmissions from 'pages/Reports/BranchWiseAdmissions/BranchWiseAdmissions'
import DeletedAdmissionReport from 'pages/Reports/DeletedAdmissionReport'
import LeadAdmissionReport from 'pages/Reports/LeadAdmissionReport'
import LeadCounsellorReport from 'pages/Reports/LeadCounsellorReport'
import SemesterReport from 'pages/Reports/SemesterReport'
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import CounsellorReport from '../pages/Reports/FacultyReport/FacultyReport'

const AdmissionLogReport = lazy(
  () => import('pages/Reports/AdmissionPhaseReport/AdmissionPhaseReport'),
)

export const reportRoutes = [
  {
    index: true,
    element: <Navigate to={'lead-counsellor-report'} />,
    permissionKey: Permission.CAN_LEAD_COUNSELLOR_REPORT_VIEW,
  },
  {
    element: <LeadCounsellorReport />,
    path: 'lead-counsellor-report',
    permissionKey: Permission.CAN_LEAD_COUNSELLOR_REPORT_VIEW,
  },
  {
    element: <CounsellorReport />,
    path: 'counsellor-reports',
    permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  },
  {
    element: <SemesterReport />,
    path: 'semester-reports',
    permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  },
  {
    element: <DeletedAdmissionReport />,
    path: 'deleted-admission-reports',
    permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  },
  {
    element: <AdmissionIntakeReport />,
    path: 'admission-intake-report',
    permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  },
  {
    element: <BranchWiseAdmissions />,
    path: 'branch-wise-admissions',
    permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  },
  {
    element: <AdmissionStatusReport />,
    path: 'admission-status-report',
    permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  },
  {
    element: <AdmissionLogReport />,
    path: 'phase-logs-report',
    permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  },
  {
    element: LeadAdmissionReport,
    path: 'lead-admissions-reports',
    permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  },
]
