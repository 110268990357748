import { Box, styled, Card, Typography, alpha } from '@mui/material'

export const StyledHeader = styled('header')(({ theme }) => ({
  marginTop: '32px',
  padding: '10px 25px',
  flexShrink: 0,
  '& .headings': {
    marginTop: '24px',
  },
  '& .MuiTypography-root': {
    textAlign: 'center',
    marginBottom: '10px',
  },
  '& .MuiTypography-h1': {
    fontSize: '32px',
    fontWeight: 600,
  },
  '&  .MuiTypography-h1.subheader': {
    fontSize: '18px',
  },
  '& .MuiTypography-h3': {
    fontSize: '16px',
    fontWeight: 500,
  },
}))

export const StyledPageContent = styled(Box)(({ theme }) => ({
  padding: '25px',
  width: '100%',
  paddingBottom: '200px',
  '& .form': {
    margin: '0 auto',
    maxWidth: '600px',
  },
}))

export const StyledFormContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '600px',
  margin: '0 auto',
  padding: '30px 20px',
}))

export const StyledFooter = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '72px',
  background: 'white',
  boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.1)',
  '& .content': {
    maxWidth: '600px',
    padding: '0 20px',
    margin: '0 auto',
  },
}))
export const StyledLogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
}))
export const StyledHeading = styled(Typography)(({ theme }) => ({
  margin: '20px 0',
  textAlign: 'center',
}))

export const StyledPaymentCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }) => ({
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  ...(isActive && {
    border: `1px solid ${theme.palette.primary.main}`,
    background: alpha(theme.palette.primary.main, 0.07),
  }),
}))
