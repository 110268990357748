import { ActionButton, FormSingleSelect, axios } from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Stack } from '@mui/material'
import { batchOptions } from 'constants/UIConstants'
import _ from 'lodash'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import * as yup from 'yup'

const schema = yup.object().shape({
  batch: yup.string().required('Academic Year is required'),
  courseId: yup.string().required('Degree is required'),
})

function SemesterFilter({ state, setState }) {
  const { data: course, isLoading: courseLoading } = useQuery(
    'fetch-data',
    () => axios.get('/paymentx/domain/courses').then((res) => res.data),
  )

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  })

  async function onSubmit(formData) {
    setState((s) => {
      s.loading = true
    })

    const data = await axios
      .get('/paymentx/admissions/semester-report', {
        params: {
          academicYear: formData.batch.split(' - ')[0],
          courseId: formData.courseId,
        },
      })
      .then((res) => {
        setState((s) => {
          s.courseId = formData.courseId
          s.data = res.data
          s.enabled = true
          s.loading = false
          s.year = formData.batch
          s.courseName = _.find(course, { id: formData.courseId })?.courseName
        })
      })
  }

  return (
    <Box sx={{ width: '60%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction={'row'} alignItems={'flex-end'} gap={2}>
          <FormSingleSelect
            name="batch"
            label={'Academic Year'}
            control={control}
            required
            options={
              batchOptions?.map((item, index) => ({
                label: item,
                value: item,
              })) || []
            }
          />

          <FormSingleSelect
            name="courseId"
            label={'Degree'}
            control={control}
            required
            options={
              course?.map((item) => ({
                label: item?.courseName,
                value: item?.id,
              })) || []
            }
          />
          <ActionButton
            type="submit"
            sx={{ whiteSpace: 'nowrap', height: '50px' }}
            loading={state.loading}
          >
            Get Report
          </ActionButton>
        </Stack>
      </form>
    </Box>
  )
}

export default SemesterFilter
