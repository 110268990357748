import {
  ActionButton,
  axios,
  axiosErrorToast,
  DeleteButton,
  DialogButton,
  DrawerButton,
  DropDownButton,
  FilterButton,
  PageContent,
  PageHeader,
  Permission,
  ReactTable,
  SearchBar,
  SingleSelect,
  useConfirm,
  useHistory,
  ValidateAccess,
  ViewButton,
} from '@campxdev/shared'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import {
  Box,
  Button,
  IconButton,
  styled,
  Typography,
  useTheme,
} from '@mui/material'
import { batchOptions } from 'constants/UIConstants'
import { SortIcon } from 'layouts/SettingsLayout/icons'
import moment from 'moment'
import RegisterExam from 'pages/LeadAdmissions/AdmissionDetails/RegisterExam'
import MovetoInstitution from 'pages/LeadAdmissions/MovetoInstitution'
import { Store } from 'pullstate'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { services } from 'services'
import BulkAssignCounsellor from './BulkAssignCounsellor'
import ImportLead from './ImportLead'
import LeadFilters from './LeadFilters'
import LeadForm from './LeadForm'
import { deleteLead, exportToExcel, fetchLeads } from './services'
import UploadFileDialog from './uploadFileDialog'

const sortByOptions = [
  { label: 'Source', value: 'source' },
  { label: 'Lead Type', value: 'type' },
  { label: 'Interested degree', value: 'courseId' },
  { label: 'Interested Program', value: 'programId' },
  { label: 'Assigned to', value: 'counsellorId' },
  { label: 'Status', value: 'status' },
]

export const leadStore = new Store({
  search: '',
  status: [],
  courseId: '',
  programId: '',
  batch: '',
  startDate: null,
  endDate: null,
  type: '',
  counsellorId: '',
  noCounsellor: false,
  source: [],
  sortBy: '',
  documents: null,
  sortOrder: 'DESC',
  skip: 0,
  limit: 10,
  isATS: false,
  excludeExamRegisteredLeads: null,
  clusterId: null,
})

export const importStore = new Store({
  courseId: null,
  batch: null,
})

function Leads() {
  const history = useHistory()
  const queryClient = useQueryClient()
  const { isConfirmed } = useConfirm()
  const filters = leadStore.useState((s) => s)
  const importState = importStore.useState((s) => s)
  const theme = useTheme()
  const [filtersApplied, setFiltersApplied] = useState(false)

  const [files, setFiles] = useState([])

  const { isLoading: progLoading, data: progData } = useQuery(
    'program-data',
    () => services.programs.fetchPrograms(),
  )

  const {
    data: leads,
    isLoading,
    refetch,
  } = useQuery(['leads', filters], () => fetchLeads(filters))

  const { data: leadStatuses } = useQuery(['Lead-Status'], () => {
    return axios.get('/paymentx/lead-status').then((res) => res.data)
  })

  const sessionFilters = JSON.parse(sessionStorage.getItem('view-leads'))

  const handlePagination = (offset: number) => {
    leadStore.update((s) => {
      s.skip = sessionFilters?.offset ?? offset
    })
  }
  const handlePageLimit = (value: number) => {
    leadStore.update((s) => {
      s.limit = sessionFilters?.limit ?? value
      s.skip = sessionFilters?.offset ?? 0
    })
  }

  const handleSort = (sort) => {
    leadStore.update((s) => {
      s.sortBy = sort?.sortBy
      s.sortOrder = sort?.sortOrder
    })
  }

  const { mutate, isLoading: leadDelete } = useMutation(deleteLead, {
    onSuccess() {
      queryClient.invalidateQueries('leads')
      toast.success('Lead Deleted Successfully')
    },

    onError(err) {
      axiosErrorToast(err ?? 'Something went wrong')
    },
  })

  const onDelete = async (id: any) => {
    const confirmed = await isConfirmed('Are you sure you want to delete?')
    if (!confirmed) return
    mutate({ leadIds: [id] })
  }

  const handleBulkDelete = async (data, clear) => {
    const confirm = await isConfirmed(
      'Are you sure you want to delete all the selected rows?',
    )
    if (!confirm) return

    mutate({ leadIds: data.map((id) => String(id)) })
    clear()
  }

  const columns = [
    {
      title: 'Lead ID',
      dataIndex: 'uniqueId',
      key: 'uniqueId',
    },
    {
      title: 'Lead Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, row) => {
        return `${row?.firstName} ${row?.lastName}`
      },
    },

    {
      title: 'Source',
      dataIndex: 'utmSource',
      key: 'source',
      render: (_, row) => {
        return row?.utmSource ? row.utmSource : row?.source
      },
    },

    {
      title: 'Medium',
      dataIndex: 'utmMedium',
      key: 'medium',
    },

    {
      title: 'Lead Type',
      dataIndex: 'type',
      key: 'type',
      render: (cellData) => (
        <StyledLeadType leadType={cellData}>{cellData}</StyledLeadType>
      ),
    },
    {
      title: 'Interested Degree',
      dataIndex: 'course.courseName',
      key: 'course',
    },
    {
      title: 'Interested Program',
      dataIndex: 'program.branchCode',
      key: 'program',
      // render: (r, row) => {
      //   return row?.program
      //     ? row.program
      //     : progData?.find((item) => item.id == row?.choiceOne)?.branchName
      // },
    },
    {
      title: 'Assigned to',
      dataIndex: 'counsellor.fullName',
      key: 'counsellor.fullName',
    },
    {
      title: 'Follow up date',
      dataIndex: 'followUpDate',
      key: 'followUpDate',
      render: (cellData) =>
        cellData ? moment(cellData).format('DD/MM/YYYY') : null,
      sort: true,
    },
    {
      title: 'Last updated',
      dataIndex: 'activities',
      key: 'last_activity',
      render: (col, row) => {
        return row?.updatedAt
          ? moment(row?.updatedAt).format('DD/MM/YYYY')
          : 'NA'
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (cellData) =>
        cellData ? moment(cellData).format('DD/MM/YYYY') : null,
    },
    {
      title: 'Files',
      dataIndex: 'files',
      key: 'files',
      render: (r, row) => {
        const leadDocs = row?.attachments?.filter(
          (item) => item?.subtype === 'attachment',
        )
        if (!!leadDocs) {
          return (
            <Box sx={{ width: '70px', display: 'flex', flexWrap: 'wrap' }}>
              {leadDocs?.map((item, index) => (
                <>
                  <IconButton onClick={() => window.open(item.url, '_blank')}>
                    <DescriptionOutlinedIcon />
                  </IconButton>
                </>
              ))}
            </Box>
          )
        }
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (cellData) => {
        let colorCode = leadStatuses?.find(
          (statusData) => statusData?.name === cellData,
        )
        return (
          <Typography
            sx={{ fontWeight: '700', color: `${colorCode?.colorCode}` }}
          >
            {cellData}
          </Typography>
        )
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, row) => {
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            <ValidateAccess accessKey={Permission.CAN_LEADS_VIEW}>
              <div style={{ display: 'flex', gap: 10 }}>
                <ViewButton
                  onClick={() => {
                    history.push(`/leads/${row?.id}`)
                    sessionStorage.setItem(
                      'view-leads',
                      JSON.stringify({
                        limit: filters.limit,
                        offset: filters.skip,
                        page: filters.skip / filters.limit,
                      }),
                    )
                  }}
                />
              </div>
            </ValidateAccess>
            <ValidateAccess key="3" accessKey={Permission.CAN_LEADS_DELETE}>
              <DeleteButton onClick={() => onDelete(row?.id)} />
            </ValidateAccess>
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    Object.keys(filters).some((key) => {
      if (
        !!filters[key] &&
        key != 'limit' &&
        key != 'offset' &&
        key != 'batch' &&
        key != 'page' &&
        filters[key] != '' &&
        key != 'sortOrder' &&
        key != 'search'
      ) {
        setFiltersApplied(true)
      }
    })

    if (sessionStorage.getItem('view-leads')) {
      sessionStorage.removeItem('view-leads')
    }
  }, [])

  return (
    <>
      <PageHeader
        title="Leads"
        actions={[
          <ValidateAccess key={0} accessKey={Permission.CAN_LEADS_VIEW}>
            <Button variant="outlined" onClick={() => exportToExcel(filters)}>
              Export
            </Button>
          </ValidateAccess>,
          <ValidateAccess key={1} accessKey={Permission.CAN_LEADS_IMPORT}>
            <UploadFileDialog
              uploadUrl="/paymentx/leads/bulk-import"
              buttonText="Import"
              dialogTitle="Import Leads"
              sampleFileUrl="/static_files/Leads_Sample.xlsx"
              successMessage="Leads Imported Successfully"
              refetchFn={() => {
                refetch()
              }}
              initialComponent={({ open }) => <ImportLead open={open} />}
              postBody={{
                courseId: importState.courseId,
                batch: importState.batch,
              }}
            />
          </ValidateAccess>,
          <ValidateAccess key={2} accessKey={Permission.CAN_LEADS_ADD}>
            <DrawerButton
              anchor={({ open }) => (
                <ActionButton onClick={open}>New Lead</ActionButton>
              )}
              content={({ close }) => (
                <LeadForm data={null} hideDialog={close} />
              )}
              title="New Lead"
            />
          </ValidateAccess>,
        ]}
      />
      <PageContent>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'end',
            marginBottom: '20px',
          }}
        >
          <SingleSelect
            label={'Batch'}
            name={'batch'}
            containerProps={{ width: '200px' }}
            options={batchOptions.map((item, index) => ({
              label: item,
              value: item,
            }))}
            onChange={(e) => {
              leadStore.update((s) => {
                s.batch = e.target.value
              })
            }}
            value={filters.batch}
          />
          <SearchBar
            onSearch={(v) => {
              leadStore.update((s) => {
                s.search = v
              })
            }}
            textFieldProps={{
              defaultValue: filters.search ?? '',
              containerProps: { width: '400px' },
              label: 'Search by name /Email /Phone No.',
            }}
          />
          <DrawerButton
            anchor={({ open }) => (
              <FilterButton onClick={open} filtersApplied={filtersApplied} />
            )}
            content={({ close }) => (
              <LeadFilters
                hideDialog={close}
                refetch={refetch}
                setFiltersApplied={setFiltersApplied}
              />
            )}
            title="Apply Filters"
          />
          <DropDownButton
            icon={{
              icon: <SortIcon />,
              outlined: true,
              iconProps: {
                sx: {
                  height: '50px',
                  width: '50px',
                  border: theme.borders.grayLight,
                },
              },
            }}
            menu={sortByOptions.map((item) => {
              return {
                label: `${item.label}`,
                onClick: () => {
                  leadStore.update((s) => {
                    s.sortBy = `${item.value}`
                  })
                },
              }
            })}
          />
        </Box>

        <Box>
          <ReactTable
            key={'admission'}
            dataSource={
              leads?.result.map((item) => ({
                ...item,
                id: item?.id,
              })) ?? []
            }
            rowKey={'id'}
            columns={columns}
            loading={isLoading}
            onSort={handleSort}
            pagination={{
              page: filters.skip / filters.limit,
              limit: filters.limit,
              onChange: handlePagination,
              totalCount: leads?.count,
              onChangeLimit: handlePageLimit,
              handlePageInput: true,
            }}
            select={{
              enable: true,
              actions: [
                {
                  component: (ids, clear) => (
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <DialogButton
                        title="Register for Exam"
                        anchor={({ open }) => (
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={open}
                          >
                            Register for Exam
                          </Button>
                        )}
                        content={({ close }) => (
                          <RegisterExam
                            close={close}
                            ids={ids}
                            type={'create-exam-registration'}
                          />
                        )}
                      />
                      <ValidateAccess
                        accessKey={Permission.CAN_ASSIGN_COUNSELLOR_LEADS}
                      >
                        <DialogButton
                          title="Move to Institution"
                          anchor={({ open }) => (
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={open}
                            >
                              Move to Institution
                            </Button>
                          )}
                          content={({ close }) => (
                            <MovetoInstitution
                              clear={clear}
                              ids={ids}
                              close={close}
                              type="leads"
                            />
                          )}
                        />
                      </ValidateAccess>
                      <ValidateAccess
                        accessKey={Permission.CAN_ASSIGN_COUNSELLOR_LEADS}
                      >
                        <DialogButton
                          title="Bulk Assign Counsellor"
                          anchor={({ open }) => (
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={open}
                            >
                              Bulk Assign Counsellor
                            </Button>
                          )}
                          content={({ close }) => (
                            <BulkAssignCounsellor
                              clear={clear}
                              leadIds={ids}
                              close={close}
                            />
                          )}
                        />
                      </ValidateAccess>

                      <ValidateAccess accessKey={Permission.CAN_LEADS_DELETE}>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            handleBulkDelete(ids, clear)
                          }}
                        >
                          Delete Selected
                        </Button>
                      </ValidateAccess>
                    </Box>
                  ),
                },
              ],
            }}
          />
        </Box>
      </PageContent>
    </>
  )
}

export default Leads

export const StyledLeadType = styled(Typography)<{
  leadType: 'Hot' | 'Warm' | 'Cold'
}>(({ leadType }) => ({
  fontWeight: '700',
  color:
    leadType === 'Hot'
      ? '#F2353C'
      : leadType === 'Warm'
      ? '#EE772F'
      : leadType === 'Cold'
      ? '#4BAABE'
      : '',
}))
