import { ErrorBoundary, LayoutWrapper, SideMenuHeader } from '@campxdev/shared'
import { Outlet } from 'react-router-dom'
import { settingsMenu } from './SettingsMenu'

export default function SettingsLayout() {
  return (
    <LayoutWrapper
      menu={settingsMenu}
      sideMenuHeader={<SideMenuHeader path="/dashboard" title={'Settings'} />}
    >
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </LayoutWrapper>
  )
}
