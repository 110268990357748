import { Permission } from '@campxdev/shared'
import Certificate from 'pages/Certificates/StudentCertificates/Certificate'
import PrintStudentCard from 'pages/Concessions/PrintStudentCard'
import { lazy } from 'react'

const ConfirmedAdmissionPrint = lazy(
  () => import('pages/LeadAdmissions/AdmissionDetails/ConfirmedAdmissionPrint'),
)
const AnuragCetAttendanceSheet = lazy(
  () => import('pages/AnuragCetAttendanceSheet'),
)
const ViewPhdPrint = lazy(() => import('pages/Phd/ViewPhdPrint'))
const PhdHallTickets = lazy(() => import('pages/PublicForms/PhdHallTickets'))

export const individualRoutes = [
  {
    element: <ConfirmedAdmissionPrint />,
    exact: true,
    name: 'Confirmed Admission',
    path: 'confirmed-admission/:admissionId/print',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },
  {
    element: <AnuragCetAttendanceSheet />,
    exact: true,
    name: 'AnuragCetAttendanceSheet',
    path: 'anuragcet-attendance-sheets',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },
  {
    element: <ViewPhdPrint />,
    exact: true,
    name: 'Phd view',
    path: 'phd/:id',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },
  {
    element: <PhdHallTickets />,
    exact: true,
    name: 'Phd Hall Ticket',
    path: 'phd-hallticket',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },
  {
    element: <PrintStudentCard />,
    path: 'student-card',
    permissionKey: Permission.CAN_INDIVIDUAL_PAGES_VIEW,
  },
  {
    element: <Certificate />,
    path: 'student-certificates/:id',
    permissionKey: Permission.CAN_STUDENT_CERTIFICATES_VIEW,
  },
]
