import {
  DrawerButton,
  FilterButton,
  SearchBar,
  SingleSelect,
  batchOptions,
} from '@campxdev/shared'

import { Box } from '@mui/material'
import { useState } from 'react'
import { PhaseLogsStore } from '../store'
import SubFilters from './SubFilters'

const Filters = () => {
  const logFilters = PhaseLogsStore.useState((s) => s)
  const [isAppliedFilters, setIsAppliedFilters] = useState(false)
  const handleBatchChange = (e: any) => {
    PhaseLogsStore.update((s) => {
      s.batch = e.target.value
    })
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'end', marginTop: '20px' }}>
      <Box sx={{ width: '150px' }}>
        <SingleSelect
          name="batch"
          label={'Select Batch'}
          value={logFilters.batch}
          options={batchOptions?.map((item) => ({
            label: item,
            value: item,
          }))}
          onChange={(e) => {
            PhaseLogsStore.update((s) => {
              s.batch = e.target.value
            })
          }}
        />
      </Box>
      <Box mx={2}>
        <SearchBar
          onSearch={(v) => {
            PhaseLogsStore.update((s) => {
              s.search = v
            })
          }}
          textFieldProps={{
            label: 'Search by name / ID',
          }}
        />
      </Box>

      <Box sx={{ marginTop: '25px' }}>
        <DrawerButton
          anchor={({ open }) => (
            <FilterButton onClick={open} filtersApplied={isAppliedFilters} />
          )}
          content={({ close }) => (
            <SubFilters
              hideDialog={close}
              setAppliedFilters={setIsAppliedFilters}
            />
          )}
          title="Fee Filters"
        />
      </Box>
    </Box>
  )
}

export default Filters
