import { Permission } from '@campxdev/shared'
import { EnrollPermissions } from '@campxdev/shared/src/shared-state/PermissionsStore'
import Caste from 'pages/Caste/Caste'
import { LeadSources } from 'pages/LeadSources'
import Clusters from 'pages/Settings/Admissions/Clusters/Clusters'
import { lazy } from 'react'
const LeadStatuses = lazy(
  () => import('pages/Settings/Admissions/LeadStatuses'),
)
const Quotas = lazy(() => import('pages/Settings/Admissions/Quotas'))

import { Navigate } from 'react-router-dom'

export const admissionRoutes = [
  {
    index: true,
    element: <Navigate to="lead-statuses" />,
    permissionKey: Permission.CAN_LEAD_STATUS_VIEW,
  },
  {
    index: true,
    element: <LeadStatuses />,
    path: 'lead-statuses',
    permissionKey: Permission.CAN_LEAD_STATUS_VIEW,
  },
  {
    element: <Quotas />,
    path: 'quotas',
    permissionKey: Permission.CAN_QUOTAS_VIEW,
  },

  {
    element: <LeadSources />,
    path: 'lead-sources',
    permissionKey: Permission.CAN_LEAD_SOURCES_VIEW,
  },

  {
    element: <Caste />,
    path: 'caste-details',
    permissionKey: Permission.CAN_CASTE_VIEW,
  },
  {
    index: true,
    element: <Clusters />,
    path: 'clusters',
    permissionKey: EnrollPermissions.CAN_CLUSTERS_VIEW,
  },
]
