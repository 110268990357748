import {
  axios,
  axiosErrorToast,
  DeleteButton,
  EditButton,
} from '@campxdev/shared'
import { Add, Close } from '@mui/icons-material'
import {
  alpha,
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  styled,
  Typography,
} from '@mui/material'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
const animatedImage = require('./AnimatedUploadFile.gif')

const StyledDialogHeader = styled(Box)(({ theme }) => ({
  height: '64px',
  backgroundColor: alpha(theme.palette.text.secondary, 0.1),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.6rem 1rem',
}))
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  alignItems: 'center',
  padding: '40px',
}))

const StyledImage = styled((props: any) => (
  <Box>
    <img {...props} />
  </Box>
))(({ theme }) => ({
  height: '90px',
  width: '90px',
  '> img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}))

const StyledChooseFileButton = styled(Button)(({ theme }) => ({
  padding: '15px 20px',
  height: '50px',
  '> .MuiBox-root': {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

const StyledFileLabel = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.light,
  borderRadius: '5px',
  padding: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

interface UploadFileDialogProps {
  buttonProps?: ButtonProps
  dialogProps?: DialogProps
  buttonText: string | ReactNode
  children?: ReactNode
  dialogTitle: ReactNode | string
  sampleFileUrl?: string
  uploadUrl: string
  successMessage?: string
  refetchFn?: () => void
  initialComponent?: (props: { open: () => void }) => ReactNode
  postBody?: { [key: string]: string }
}

export default function UploadFileDialog({
  buttonProps,
  dialogProps,
  buttonText,
  dialogTitle,
  uploadUrl,
  sampleFileUrl,
  successMessage,
  refetchFn,
  initialComponent,
  postBody = {},
}: UploadFileDialogProps) {
  const [step, setStep] = useState(0)
  const [open, setOpen] = useState(false)
  const onClose = () => {
    setOpen(false)
  }
  const onStepChange = () => {
    setStep(1)
  }

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true)
        }}
        variant="outlined"
        {...buttonProps}
      >
        {buttonText}
      </Button>
      <Dialog
        open={open}
        onClose={() => {
          onClose()
          setStep(0)
        }}
        maxWidth="sm"
        fullWidth
        {...dialogProps}
      >
        <StyledDialogHeader>
          <Typography fontWeight={600}>{dialogTitle}</Typography>
          <IconButton
            onClick={() => {
              onClose()
              setStep(0)
            }}
          >
            <Close />
          </IconButton>
        </StyledDialogHeader>
        <StyledDialogContent>
          {initialComponent ? (
            <>
              {step === 0 ? (
                initialComponent({ open: onStepChange })
              ) : (
                <UploadComponent
                  dialogTitle={dialogTitle}
                  postBody={postBody}
                  uploadUrl={uploadUrl}
                  setOpen={setOpen}
                  setStep={setStep}
                  sampleFileUrl={sampleFileUrl}
                  successMessage={successMessage}
                  refetchFn={refetchFn}
                />
              )}
            </>
          ) : (
            <UploadComponent
              dialogTitle={dialogTitle}
              postBody={postBody}
              uploadUrl={uploadUrl}
              setOpen={setOpen}
              setStep={setStep}
              sampleFileUrl={sampleFileUrl}
              successMessage={successMessage}
              refetchFn={refetchFn}
            />
          )}
        </StyledDialogContent>
      </Dialog>
    </>
  )
}

export const UploadComponent = ({
  dialogTitle,
  postBody,
  uploadUrl,
  setOpen,
  sampleFileUrl,
  successMessage,
  refetchFn,
  setStep,
}) => {
  const inputRef: any = useRef(null)
  const [loading, setLoading] = useState(false)

  const [file, setFile] = useState(null)

  const triggerUpload = () => {
    if (!inputRef.current) return
    inputRef?.current?.click()
  }

  const handleImageChange = (e) => {
    setFile(e.target.files[0])
  }

  const handleDownload = async () => {
    try {
      const response = await axios.get('/square/leads/lead-sample-file', {
        responseType: 'blob',
      })

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'lead_sample_file.xlsx')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      axiosErrorToast(error)
    }
  }

  const handleOnUpload = () => {
    if (!file) return ``
    const formData = new FormData()
    formData.append('file', file)
    if (postBody) {
      Object.keys(postBody).forEach((key) => {
        formData.append(key, postBody[key])
      })
    }
    setLoading(true)
    axios
      .post(uploadUrl, formData)
      .then((res) => {
        setLoading(false)
        refetchFn()
        setOpen(false)
        toast.success(successMessage ?? 'Successful')
        setFile(null)
        setStep(0)
      })
      .catch((err) => {
        setLoading(false)
        axiosErrorToast(err)
        setStep(0)
      })
  }

  useEffect(() => {
    return () => {
      setFile(null)
      inputRef.current = null
    }
  }, [])

  return (
    <>
      <StyledImage src={animatedImage} alt="Upload Image" />
      <Typography variant="h6">{dialogTitle}</Typography>
      <Typography textAlign={'center'}>
        Before importing, download Sample CSV File or Sample XLSX file and
        compare it with your sheet or edit the sample sheet
      </Typography>
      <Box sx={{ minWidth: '400px', marginBottom: '20px' }}>
        {!file ? (
          <>
            <input
              onChange={handleImageChange}
              hidden
              type="file"
              ref={inputRef}
            />
            <StyledChooseFileButton
              variant="outlined"
              fullWidth
              onClick={triggerUpload}
            >
              <Box>
                <Typography variant="h6" color="primary">
                  Choose File
                </Typography>
                <Add />
              </Box>
            </StyledChooseFileButton>
          </>
        ) : (
          <StyledFileLabel>
            <Typography variant="body2">{file?.name}</Typography>
            <Box display={'flex'} gap="16px">
              <EditButton
                onClick={() => {
                  triggerUpload()
                }}
              />
              <DeleteButton onClick={() => setFile(null)} />
            </Box>
          </StyledFileLabel>
        )}
      </Box>

      <Button
        fullWidth
        sx={{ maxWidth: '255px' }}
        onClick={handleOnUpload}
        endIcon={
          loading ? (
            <CircularProgress size={20} sx={{ color: 'white' }} />
          ) : null
        }
      >
        Upload File
      </Button>
      {sampleFileUrl && (
        <a onClick={handleDownload} rel="noreferrer">
          <Button fullWidth sx={{ maxWidth: '255px' }} variant="outlined">
            Download Sample File
          </Button>
        </a>
      )}
    </>
  )
}
