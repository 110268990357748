import {
  ActionButton,
  FormMultiSelect,
  FormTextField,
  Spinner,
  axiosErrorToast,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { services } from 'services'
import * as yup from 'yup'

const schema = yup.object().shape({
  clusterName: yup.string().required('Name is required'),
  globalUserIds: yup
    .array()
    .of(
      yup.object({
        label: yup.string(),
        value: yup.string().required(),
      }),
    )
    .min(1, 'Select at least one Co-ordinator')
    .required('Co-ordinator is required'),
  clusterHeadId: yup
    .object({
      label: yup.string(),
      value: yup.string().required(),
    })
    .required('Cluster Head is required'),
})

export default function ClusterForm({ close, data }) {
  const queryClient = useQueryClient()
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      clusterName: data?.name ?? '',
      clusterHeadId: {
        label: data?.head?.fullName ?? '',
        value: data?.head?._id ?? '',
      },
      globalUserIds:
        data?.coordinators?.map((coordinator) => ({
          label: coordinator?.fullName ?? '',
          value: coordinator?._id ?? '',
        })) ?? [],
    },
  })

  const { data: globalUserData, isLoading } = useQuery('globalUser', () =>
    services.users.fetchUsers({ type: 'staff_user' }),
  )

  const { isLoading: posting, mutate } = useMutation(
    services.clusters.createCluster,
    {
      onSuccess() {
        queryClient.invalidateQueries('clusters')
        close()
      },
      onError(error) {
        axiosErrorToast(error)
      },
    },
  )

  const onSubmit = (formData) => {
    if (data) {
      mutate({
        name: formData.clusterName,
        id: data?.id,
        clusterHeadId: formData.clusterHeadId.value,
        globalUserIds: formData.globalUserIds.map((user) => user.value),
      })
    } else {
      mutate({
        name: formData.clusterName,
        clusterHeadId: formData.clusterHeadId.value,
        globalUserIds: formData.globalUserIds.map((user) => user.value),
      })
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2.5}>
        <FormTextField
          control={control}
          name="clusterName"
          label="Name"
          required
        />
        <FormMultiSelect
          label="Cluster Head"
          name="clusterHeadId"
          control={control}
          options={globalUserData.data?.map((user) => ({
            label: user.fullName,
            value: user._id,
          }))}
          multiple={false}
          required
        />
        <FormMultiSelect
          label="Co-ordinators"
          name="globalUserIds"
          control={control}
          options={globalUserData.data?.map((user) => ({
            label: user.fullName,
            value: user._id,
          }))}
          multiple={true}
          required
        />
        <Box display="flex" gap="20px" marginTop="20px">
          <ActionButton onClick={close} fullWidth variant="outlined">
            Cancel
          </ActionButton>
          <ActionButton type="submit" loading={posting} fullWidth>
            Submit
          </ActionButton>
        </Box>
      </Stack>
    </form>
  )
}
