import { PageContent, axios } from '@campxdev/shared'
import { urlTenantKey } from '@campxdev/shared/src/contexts/Providers'
import { Box, Divider, Typography, styled } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { BranchWiseFilters } from './BranchWiseFilters'
import { BranchWiseTable } from './BranchWiseTable'

function BranchWiseAdmissions() {
  const [intakeData, setIntakeData] = useState(null)

  const { data: course, isLoading: courseLoading } = useQuery('courses', () =>
    axios.get('/paymentx/domain/courses').then((res) => res.data),
  )

  return (
    <>
      <PageContent>
        <StyledHeaderBox>
          <Typography variant="h6">{_.upperCase(urlTenantKey)}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Divider orientation="horizontal" sx={{ width: '100px' }} />
            <Typography variant="h6" sx={{ fontSize: '24px' }}>
              Branch Wise Admissions
            </Typography>
            <Divider orientation="horizontal" sx={{ width: '100px' }} />
          </Box>
          <BranchWiseFilters setIntakeData={setIntakeData} course={course} />
        </StyledHeaderBox>
        {intakeData && (
          <Box sx={{ marginTop: '20px' }}>
            <BranchWiseTable data={intakeData} course={course} />
          </Box>
        )}
      </PageContent>
    </>
  )
}
export default BranchWiseAdmissions

export const StyledHeaderBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))
