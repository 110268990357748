import {
  ActionButton,
  DateRangePicker,
  FormSingleCheckbox,
  FormSingleSelect,
  axios,
  axiosErrorToast,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { Stack } from '@mui/system'
import moment from 'moment'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import * as yup from 'yup'
import { fetchQuotas } from './services'

const schema = yup.object().shape({
  courseId: yup.string().required('Degree is required'),
})

export function AdmissionIntakeFilters({ setIntakeData, course }) {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [error, setError] = useState(false)
  const { data: quota, isLoading } = useQuery('fetch-quotas', fetchQuotas)

  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      courseId: '',
    },
  })

  function onSubmit(formData) {
    if (!startDate && !endDate) {
      setError(true)
      return
    }
    setError(false)
    const data = axios
      .get('/paymentx/admissions/student-intake-report', {
        params: {
          ...formData,
          fromDate: moment(startDate).format('YYYY-MM-DD'),
          toDate: moment(endDate).format('YYYY-MM-DD'),
        },
      })
      .then((res) => {
        setIntakeData(res.data)
      })
      .catch((err) => {
        axiosErrorToast(err)
      })
  }

  return (
    <Box sx={{ marginTop: '20px' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction={'row'} alignItems={'flex-start'} gap="10px">
          <Box sx={{ width: '200px' }}>
            <FormSingleSelect
              name="courseId"
              label="Course"
              size="small"
              control={control}
              required
              options={
                course?.map((item) => ({
                  label: item?.courseName,
                  value: item?.id,
                })) ?? []
              }
            />
          </Box>
          <Box sx={{ width: '200px' }}>
            <FormSingleSelect
              name={'quotaId'}
              size="small"
              label={'Quota'}
              control={control}
              firstItemEmpty
              options={quota
                ?.filter((item) => item?.courseId == watch('courseId'))
                ?.map((item) => ({
                  label: item?.name,
                  value: item?.id,
                }))}
            />
          </Box>

          <Box>
            <DateRangePicker
              label="Filter by Date"
              size="small"
              required
              helperText="Please select date"
              value={{
                start: startDate,
                end: endDate,
              }}
              onChange={(newValue) => {
                setStartDate(!!newValue.start && newValue.start)
                setEndDate(!!newValue.end && newValue.end)
              }}
              containerProps={{
                sx: { width: '400px' },
              }}
              error={error}
            />
          </Box>
          <Box sx={{ minWidth: '150px', marginTop: '28px' }}>
            <FormSingleCheckbox
              name="lateralEntry"
              control={control}
              label="Lateral Entry"
            />
            <ActionButton size="small" type="submit">
              Get Report
            </ActionButton>
          </Box>
        </Stack>
      </form>
    </Box>
  )
}
