import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import _, { find, reduce } from 'lodash'

export function AdmissionIntakeTable({ data, course }) {
  const fixedColumns = ['Parameter', 'Total Intake']

  const dynamicColumns =
    !!data && data?.inTakeData?.map((item) => item?.branchCode)

  const columns = fixedColumns.concat(dynamicColumns)

  return (
    <>
      {dynamicColumns && (
        <>
          <Table>
            <TableHead>
              {columns?.map((col, index) => (
                <TableCell key={index}>{col}</TableCell>
              ))}
            </TableHead>

            <TableBody style={{ backgroundColor: '#F8F8F8' }}>
              <TableCell>Total Admissions</TableCell>
              <TableCell>
                {reduce(
                  data?.inTakeData,
                  (sum, obj) => sum + parseInt(obj.count, 10),
                  0,
                )}
              </TableCell>
              {!!dynamicColumns &&
                dynamicColumns?.map((branchCode, index) => (
                  <TableCell key={index}>
                    {find(data.inTakeData, { branchCode })?.count}
                  </TableCell>
                ))}
            </TableBody>

            {data?.casteData?.length > 0 && (
              <GetDynamicData
                data={data?.casteData}
                type="caste"
                columns={columns}
                dynamicColumns={dynamicColumns}
                title="Category Wise"
              />
            )}

            {data?.genderData?.length > 0 && (
              <>
                <GetDynamicData
                  data={data?.genderData}
                  type="gender"
                  columns={columns}
                  dynamicColumns={dynamicColumns}
                  title="Gender Wise"
                />
              </>
            )}

            {data?.admissionModeData?.length > 0 && (
              <>
                <GetDynamicData
                  data={data?.admissionModeData}
                  type="admissionMode"
                  columns={columns}
                  dynamicColumns={dynamicColumns}
                  title="Admission Mode"
                />
              </>
            )}

            {data?.quotaData?.length > 0 && (
              <>
                <GetDynamicData
                  data={data?.quotaData}
                  type="quota"
                  columns={columns}
                  dynamicColumns={dynamicColumns}
                  title="Quota Data"
                />
              </>
            )}
          </Table>
        </>
      )}
    </>
  )
}

export function GetDynamicData({ data, type, columns, dynamicColumns, title }) {
  const rowCount = {}

  data?.forEach((item) => {
    if (!rowCount[item[type]]) {
      rowCount[item[type]] = {}
    }
    rowCount[item[type]][item.branchCode] = parseInt(item.count, 10)
  })

  const totalCount = _.reduce(
    rowCount,
    (result, value, key) => {
      result.push({ rowData: key, count: _.sum(Object.values(value)) })
      return result
    },
    [],
  )

  return (
    <>
      <TableBody style={{ backgroundColor: '#F8F8F8' }}>
        <TableCell colSpan={columns?.length}>{title}</TableCell>
      </TableBody>
      <TableBody>
        {Object.keys(rowCount).map((rowData) => (
          <TableRow key={rowData}>
            <TableCell>{rowData}</TableCell>
            <TableCell>{find(totalCount, { rowData })?.count || 0}</TableCell>
            {dynamicColumns.map((branch, index) => (
              <TableCell key={index}>
                {rowCount[rowData][branch] || 0}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </>
  )
}
