import { PageContent, Spinner, axios } from '@campxdev/shared'
import { urlTenantKey } from '@campxdev/shared/src/contexts/Providers'
import { Box, Divider, Typography, styled } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { AdmissionIntakeFilters } from './AdmissionIntakeFilter'
import { AdmissionIntakeTable } from './AdmissionIntakeTable'

function AdmissionIntakeReport() {
  const [intakeData, setIntakeData] = useState(null)

  const { data: course, isLoading: courseLoading } = useQuery(
    'intake-courses',
    () => axios.get('/paymentx/domain/courses').then((res) => res?.data),
  )
  if (courseLoading) return <Spinner />

  return (
    <>
      <PageContent>
        <StyledHeaderBox>
          <Typography variant="h6">{_.upperCase(urlTenantKey)}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Divider orientation="horizontal" sx={{ width: '100px' }} />
            <Typography variant="h6" sx={{ fontSize: '24px' }}>
              Admission Intake Report
            </Typography>
            <Divider orientation="horizontal" sx={{ width: '100px' }} />
          </Box>
          <AdmissionIntakeFilters
            setIntakeData={setIntakeData}
            course={course}
          />
        </StyledHeaderBox>
        <Box sx={{ marginTop: '20px' }}>
          <AdmissionIntakeTable data={intakeData} course={course} />
        </Box>
      </PageContent>
    </>
  )
}
export default AdmissionIntakeReport

export const StyledHeaderBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))
