import { format } from 'date-fns'

export const batchOptions = Array.from({ length: 13 }, (_, i) => {
  return `${2012 + i} - ${2012 + i + 1}`
}).reverse()

export const currentYearBatchOptions = Array.from({ length: 1 }, (_, i) => {
  return `${new Date().getFullYear()} - ${new Date().getFullYear() + 1}`
}).reverse()

export const examGroupBatches = Array.from({ length: 10 }, (_, i) => {
  return `${2012 + i} - ${2012 + i + 1}`
})
export const currentYear = new Date().getFullYear()

const startYear = 2012

export const yearOptions = Array.from(
  { length: currentYear - startYear + 1 },
  (_, i) => {
    return `${startYear + i}`
  },
)

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const religionOptions = [
  'Hinduism',
  'Islam',
  'Christianity',
  'Sikhism',
  'Buddhism',
  'Jainism',
]

export const casteOptions = [
  'OC',
  'BC_A',
  'BC_B',
  'BC_C',
  'BC_D',
  'BC_E',
  'SC',
  'ST',
]

export const admissionStatus = {
  ADMITTED: 'ADMITTED',
  CONFIRMED: 'CONFIRMED',
  IN_PROGRESS: 'IN_PROGRESS',
  OPEN: 'OPEN',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
}

export const nationalityOptions = ['Indian', 'Other']

export const examTimings = [
  '09:00 AM',
  '10:00 AM',
  '11:00 AM',
  '01:00 PM',
  '02:00 PM',
  '03:00 PM',
]

export const USER_TYPES = {
  'Staff User': 'staff_user',
  'Faculty User': 'non_staff_user',
  'Student User': 'student',
}

export function getRandomColor(name) {
  const firstAlphabet = name.charAt(0).toLowerCase()

  const asciiCode = firstAlphabet.charCodeAt(0)

  const colorNum =
    asciiCode.toString() + asciiCode.toString() + asciiCode.toString()

  var num = Math.round(0xffffff * parseInt(colorNum))
  var r = (num >> 16) & 145
  var g = (num >> 8) & 145
  var b = num & 145

  return {
    color: 'rgb(' + r + ', ' + g + ', ' + b + ', 0.8)',
    character: firstAlphabet?.toUpperCase(),
  }
}

export const monthNames = new Array(12).fill(0).map((_, i) => ({
  label: format(new Date(2021, i, 1), 'MMMM'),
  value: i,
}))

export const recentYears = new Array(4).fill(0).map((_, i) => ({
  label: new Date().getFullYear() - i,
  value: new Date().getFullYear() - i,
}))

export const getRandomColors = (length: number) => {
  //get random bright hex colors
  const colors = []
  for (let i = 0; i < length - 5; i++) {
    colors.push(
      '#' +
        Math.floor(Math.random() * 0xffffff)
          .toString(16)
          .padEnd(6, '0'),
    )
  }

  return ['#FFD300', '#573DAB', '#4BAABE', '#88B053', '#EA4A6B', ...colors]
}

export const getAcademicBatches = (duration: number) => {
  const length = 20
  const currentYear = new Date().getFullYear()
  const batches = []
  for (let i = 0; i < length; i++) {
    batches.push(`${currentYear - i} - ${currentYear - i + duration}`)
  }
  return batches
}
