import { Permission } from '@campxdev/shared'

import { Navigate } from 'react-router-dom'

import { lazy } from 'react'
import { admissionRoutes } from './admissionRoutes'
const People = lazy(() => import('../pages/Settings/People'))
const StudentServices = lazy(() => import('../pages/Settings/StudentServices'))
export const settingsRoutes = [
  {
    index: true,
    element: <Navigate to={'admissions'} />,
    permissionKey: Permission.CAN_ADMISSIONS_DASHBOARD_VIEW,
  },

  {
    path: 'admissions',
    permissionKey: Permission.CAN_ADMISSIONS_DASHBOARD_VIEW,
    children: admissionRoutes,
  },
  {
    path: 'student-services',
    element: <StudentServices />,
    permissionKey: Permission.CAN_ADMISSIONS_DASHBOARD_VIEW,
  },
  {
    path: 'application-profiles',
    element: <People />,
    permissionKey: Permission.CAN_MANAGE_ENROLL_X_PROFILE_PERMISSIONS_VIEW,
  },

  {
    path: '*',
    element: <h1>Page Not found</h1>,
  },
]
