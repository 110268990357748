import { ActionButton, PageContent, ReportPageHeader } from '@campxdev/shared'
import { urlTenantKey } from '@campxdev/shared/src/contexts/Providers'
import { Box } from '@mui/material'
import _ from 'lodash'
import { useQuery } from 'react-query'
import { services } from 'services'
import * as XLSX from 'xlsx'
import AdmissionLogsTable from './AdmissionPhaseTable'
import Filters from './Filters/Filters'
import { PhaseLogsStore } from './store'

const AdmissionPhaseReport = () => {
  const logFilters = PhaseLogsStore.useState((s) => s)

  const { data, isLoading } = useQuery(['admission-logs', logFilters], () =>
    services.admissions.fetchAdmissions(logFilters),
  )

  const handleAdmissionLogExport = async (filters: any) => {
    const changedParams = {
      ...filters,
      limit: undefined,
      offset: undefined,
    }

    const data = await services.admissions.fetchAdmissions(changedParams)

    const programs = await services.programs.fetchPrograms()
    const programsById = _.groupBy(programs, 'id')
    const excelData = data.admissions?.map((item) => ({
      AdmissionId: item?.uniqueId,
      Name: item?.fullName,
      Batch: item?.batch,
      Phase1: item?.phase1,
      Phase2: item?.phase2,
      Phase3: item?.phase3,
      Status: item?.phaseStatus,
      Phase1Branch: item?.phaseLogs?.find((item) => item.phase === 1)?.programId
        ? programsById[
            item?.phaseLogs?.find((item) => item.phase === 1)?.programId
          ][0].branchCode
        : null,
      Phase2Branch: item?.phaseLogs?.find((item) => item.phase === 2)?.programId
        ? programsById[
            item?.phaseLogs?.find((item) => item.phase === 2)?.programId
          ][0].branchCode
        : null,
      Phase3Branch: item?.phaseLogs?.find((item) => item.phase === 3)?.programId
        ? programsById[
            item?.phaseLogs?.find((item) => item.phase === 3)?.programId
          ][0].branchCode
        : null,
    }))
    const fileName = `Admisssion-log-report.xlsx`
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'admissions')
    XLSX.writeFile(wb, fileName)
  }

  return (
    <>
      {logFilters.batch && (
        <ActionButton
          sx={{ position: 'absolute', right: '3%', top: '2%' }}
          variant="outlined"
          onClick={() => handleAdmissionLogExport(logFilters)}
        >
          Export
        </ActionButton>
      )}
      <PageContent>
        <ReportPageHeader
          urlTenantKey={urlTenantKey}
          title={'Phase Log Report'}
        />
        <Filters />
        <Box sx={{ marginTop: '30px' }}>
          <AdmissionLogsTable data={data} isLoading={isLoading} />
        </Box>
      </PageContent>
    </>
  )
}

export default AdmissionPhaseReport
