import { axios } from '@campxdev/shared'

const paymentxCounsellors = '/paymentx/counsellors'

export const counsellors = {
  async fetchCounsellors(params?: any) {
    const res = await axios.get(paymentxCounsellors, {
      params,
    })
    return res.data
  },

  async addCounsellor(body: { globalUserId: string }) {
    return axios.post(paymentxCounsellors, body)
  },

  async deleteCounsellor(body: { globalUserId: string }) {
    return axios.delete(`${paymentxCounsellors}/${body.globalUserId}`)
  },
}
