import {
  Drawer,
  DrawerProps,
  ListItemButton,
  ListSubheader,
  Stack,
  styled,
} from '@mui/material'

import { Link } from 'react-router-dom'

const drawerWidth = 240

export const headerHeight = '64px'
export const sideNavWidth = '220px'

export const StyledPageContent = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  paddingLeft: '16px',
  paddingRight: '16px',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}))

export const StyledDrawer = styled((props: DrawerProps) => (
  <Drawer
    {...props}
    variant='persistent'
    anchor='left'
    PaperProps={{
      sx: {
        left: sideNavWidth,
        top: headerHeight,
      },
    }}
  />
))(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    background: theme.palette.secondary.light,
  },
}))

export const StyledListItemButton = styled(ListItemButton)<{
  isActive: boolean
}>(({ theme, isActive }) => ({
  ...(isActive && {
    background: theme.palette.secondary.lighter,
    ':hover': {
      background: theme.palette.secondary.lighter,
    },
  }),
}))

export const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  color: theme.palette.common.yellow,
  background: 'transparent',
}))

export const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    fontSize: '28px',
  },
  '& .MuiTypography-root': {
    fontSize: '18px',
    fontWeight: 600,
  },
}))

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  transition: 'color 0.2s ease-in-out',
  fontFamily: 'Poppins',
  '&:hover': {
    color: theme.palette.common.yellow,
  },
}))
