import {
  FormDatePicker,
  FormRadioGroup,
  FormSingleSelect,
  FormTextField,
} from '@campxdev/shared'
import { Box, Stack, Typography } from '@mui/material'
import { stateCity } from 'constants/stateCity'

export default function Step1Form({ control, watch }) {
  return (
    <Stack gap={2}>
      <FormTextField
        control={control}
        name="firstName"
        label="First Name"
        required
      />
      <FormTextField
        control={control}
        name="lastName"
        label="Last Name"
        required
      />
      <FormTextField
        control={control}
        name="fullName"
        label="Full Name"
        required
      />

      <FormRadioGroup
        control={control}
        name="gender"
        label="Gender"
        options={[
          { value: 'male', label: 'Male' },
          { value: 'female', label: 'Female' },
        ]}
        required
      />
      <FormDatePicker
        control={control}
        name="dob"
        required
        label="Date of Birth"
      />
      <FormTextField
        name="mobile"
        control={control}
        label="Mobile number"
        required
      />

      <FormTextField
        name="email"
        control={control}
        label="Email address"
        required
      />

      <Stack gap={2} margin={'30px 0'}>
        <FormTextField
          control={control}
          name="fatherName"
          label="Father's Name"
        />
        <FormTextField
          control={control}
          name="motherName"
          label="Mother's Name"
        />
        <Typography variant="subtitle2" textAlign="center">
          Or
        </Typography>
        <FormTextField
          control={control}
          name="guardianName"
          label="Guardian's Name"
        />
        <FormTextField
          control={control}
          name="guardianRelation"
          label="Guardian Relation"
        />
      </Stack>
      <FormTextField
        name="parentOccupation"
        label="Parent/Guardian's Occupation"
        control={control}
      />
      <FormTextField
        name="parentMobile"
        control={control}
        label="Parent/Guardian Mobile"
        required
      />
      <FormTextField
        name="permanentAddress"
        control={control}
        label="Permanent Address"
        minRows={4}
        multiline
        required
      />

      <FormSingleSelect
        control={control}
        name="state"
        label="State"
        options={Object.keys(stateCity)?.map((item) => ({
          label: item,
          value: item,
        }))}
      />
      <FormSingleSelect
        control={control}
        name="city"
        label="City"
        options={stateCity[watch('state')]?.map((item) => ({
          label: item,
          value: item,
        }))}
      />
    </Stack>
  )
}
