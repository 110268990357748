import { ReactTable } from '@campxdev/shared'
import { Typography } from '@mui/material'

function StudentReportDialog({
  isLoading,
  close,
  status,
  filters,
  setFilters,
}) {
  const columns = [
    {
      title: 'Student Name',
      dataIndex: 'fullName',
      key: status == 'Moved to admissions' ? 'name' : 'fullName',
      render: (cellData) => {
        return cellData?.toUpperCase()
      },
    },
    {
      title: 'Degree',
      dataIndex: 'course.courseName',
      key: status == 'Moved to admissions' ? 'course' : 'course.courseName',
    },
    {
      title: 'Program',
      dataIndex: 'program.branchCode',
      key: status != 'Moved to admissions' ? 'program' : 'branchCode',
    },

    {
      title: 'Batch',
      dataIndex: 'batch',
      key: 'batch',
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'REMARKS',
      dataIndex: 'remarks',
      key: 'remarks',
      render: (r, row) => {
        if (row?.remarks?.length > 0) {
          return (
            <Typography>
              {row?.remarks[row?.remarks?.length - 1]['notes'] ?? row?.remarks}
            </Typography>
          )
        }

        if (row?.activities?.length > 0) {
          return (
            <Typography>
              {row.activities[row?.activities?.length - 1]['remarks'] ??
                row?.remarks}
            </Typography>
          )
        }
      },
    },
  ]

  const handlePagination = (offset: number) => {
    setFilters((s) => {
      s.offset = offset
      s.enabled = true
    })
  }

  const handlePageLimit = (value: number) => {
    setFilters((s) => {
      s.limit = value
      s.enabled = true
    })
  }

  return (
    <ReactTable
      dataSource={filters.data ?? []}
      columns={columns}
      loading={isLoading}
      pagination={{
        limit: filters.limit,
        onChange: handlePagination,
        totalCount: filters.count,
        onChangeLimit: handlePageLimit,
      }}
    />
  )
}

export default StudentReportDialog
