import { axios } from '@campxdev/shared'
import * as XLSX from 'xlsx'

export const dynamicStatusValues = [
  {
    value: 'IN_PROGRESS',
    title: 'IN PROGRESS',
  },
  {
    title: 'OPEN',
    value: 'OPEN',
  },
  {
    title: 'ADMITTED',
    value: 'ADMITTED',
  },
  {
    title: 'CONFIRMED',
    value: 'CONFIRMED',
  },
  {
    title: 'CANCELLED',
    value: 'CANCELLED',
  },
  {
    title: 'REJECTED',
    value: 'REJECTED',
  },
]

export const fetchLeads = async (params) => {
  const data = {
    ...params,
  }
  let leadData = await axios.get('/paymentx/admissions/leads-to-admissions', {
    params: { ...data },
  })
  return leadData.data
}

export const handleExport = (data) => {
  const excelData = data?.map((item) => ({
    leadId: item?.leadUniqueId,
    'Student Name': item?.fullName,
    'Counsellor Name': item?.leadCounsellorName ?? '-',
    'ConvertedBy UserName': item?.createdByName ?? '-',
    'ConfirmedBy UserName': item?.confirmedByName ?? '-',
    'Admission Status':
      item?.status == 'IN_PROGRESS' ? 'IN PROGRESS' : item?.status,
  }))

  const fileName = `lead-to-Admission.xlsx`
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'admissions')
  XLSX.writeFile(wb, fileName)
}
