import { PageContent, PageHeader, axios } from '@campxdev/shared'
import React from 'react'
import { useQuery } from 'react-query'
import SemesterFilter from './SemesterFilter'
import SemesterTable from './SemesterTable'
import { useImmer } from 'use-immer'

const defaultState = {
  academicYear: null,
  year: null,
  courseId: null,
  courseName: null,
  data: [],
  enabled: false,
  loading: false,
}

function SemesterReport() {
  const [state, setState] = useImmer(defaultState)

  return (
    <>
      <PageHeader title={'Semester Wise Report'} />
      <PageContent>
        <SemesterFilter state={state} setState={setState} />
        <SemesterTable state={state} />
      </PageContent>
    </>
  )
}

export default SemesterReport
