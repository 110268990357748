import { axios } from '@campxdev/shared'
import { AxiosRequestConfig } from 'axios'
import { set } from 'date-fns'

const LEADS = '/paymentx/leads'
const LEADSSOURCES = '/paymentx/lead-sources'

export const leads = {
  async bulkAssignCounsellor(body: {
    leadIds: number[]
    counsellorUserId: number
  }) {
    return await axios.post(LEADS + '/bulk-assign-counsellor', body)
  },

  async assignCounsellor(body: {
    leadIds: number[]
    counsellorUserId: number
  }) {
    return axios.post(LEADS + '/bulk-assign-counsellor', body)
  },

  async convertLeadToAdmission(body: {
    leadId: string
    phase: string
    quotaId: string
    lateralEntry: boolean
    admissionType?: string
    admissionNotificationId: number
    courseId: string
  }) {
    return axios.post(LEADS + '/convert-to-admission', body)
  },

  async fetchLeadSources() {
    let res = await axios.get(LEADSSOURCES)
    return res.data
  },

  async createLeadSource(body: { name: string; id?: number }) {
    let config: AxiosRequestConfig = {
      data: body,
      url: body?.id ? `${LEADSSOURCES}/${body.id}` : `${LEADSSOURCES}`,
      method: body?.id ? 'PUT' : 'POST',
    }
    return await axios(config)
  },

  async deleteLeadSource(id: number) {
    return await axios.delete(`${LEADSSOURCES}/${id}`)
  },

  //dashboard
  async fetchLeadsCount({ batch }: { batch: string }) {
    let leadData = await axios.get(`${LEADS}/dashboard`, {
      params: { batch },
    })
    return leadData.data
  },

  async fetchDailyLeads({ month, year }: { month?: number; year?: number }) {
    if (!month) month = new Date().getMonth()
    if (!year) year = new Date().getFullYear()

    const startOfMonth = set(new Date(), { month, date: 1, year, hours: 0 })
    const endOfMonth = set(new Date(), {
      month: month + 1,
      date: 0,
      year,
      hours: 23,
      minutes: 59,
    })

    let leadData = await axios.get(`${LEADS}/dashboard/graphs`, {
      params: {
        fromDate: startOfMonth,
        toDate: endOfMonth,
      },
    })
    return leadData.data
  },
}
