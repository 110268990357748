import { Box, Button, Typography } from '@mui/material'
import tickGif from './assets/tick.gif'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

function Thankyou() {
  const { admissionId } = useParams()
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={tickGif} width={'200px'} />
      <Typography variant="h6">Thank You for submitting the form</Typography>
      <Typography>
        Please reach out your admission's department with the provided admission
        ID
      </Typography>
      <Typography variant="h6">Admission ID - {admissionId}</Typography>

      <Button sx={{ mt: 2 }} variant="outlined" onClick={() => navigate(-1)}>
        Home
      </Button>
    </Box>
  )
}

export default Thankyou
