import {
  ActionButton,
  FormCourseSelector,
  FormSingleSelect,
  PageHeader,
  Table,
} from '@campxdev/shared'
import { TableColumn } from '@campxdev/shared/src/components/Tables/BasicTable/Table'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Stack } from '@mui/material'
import { batchOptions } from 'constants/UIConstants'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import * as yup from 'yup'
import { getLeadCounsellorReport, handleExport } from './service'
const schema = yup.object().shape({
  batch: yup.string().required('Batch is required'),
})

function LeadCounsellorReport() {
  const [filters, setFilters] = useState({
    batch: null,
    courseId: null,
    // quotaId: null,
  })
  const [leadCounsellorsData, setLeadCounsellorsData] = useState(null)
  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      courseId: '',
      batch: '',
      // quotaId: null,
    },
  })

  const { mutate, isLoading } = useMutation(getLeadCounsellorReport, {
    onSuccess: (res) => {
      setLeadCounsellorsData(res)
    },
  })
  const onSubmit = async (formData) => {
    setFilters((s) => ({
      ...s,
      batch: formData.batch,
      courseId: formData.courseId,
      // quotaId: formData.quotaId,
    }))

    mutate(formData)
  }

  const columns: TableColumn<unknown>[] = [
    {
      dataIndex: 'name',
      key: 'id',
      title: 'Counsellor',
    },
  ]

  if (leadCounsellorsData != null) {
    leadCounsellorsData.leadStatus.forEach((leadStatus) => {
      columns.push({
        title: `${leadStatus.name}`,
        dataIndex: `data.${leadStatus.name}.count`,
        key: `${leadStatus.name}`,
      })
    })

    columns.push({
      title: 'Total',
      dataIndex: 'count',
      key: 'total',
      render: (_, row) => {
        return (
          <>
            {Object.values(row.data).reduce((total, status: any) => {
              return total + status.count
            }, 0)}
          </>
        )
      },
    })
  }

  return (
    <>
      <PageHeader title={'Lead Counsellor Report'} />
      <Box sx={{ width: '60%' }} p={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={'row'} alignItems={'flex-end'} gap={2}>
            <FormSingleSelect
              name="batch"
              label={'Batch'}
              control={control}
              required
              options={
                batchOptions?.map((item, index) => ({
                  label: item,
                  value: item,
                })) || []
              }
            />
            <FormCourseSelector
              name="courseId"
              label={'Degree'}
              control={control}
              allowAll={false}
            />
            {/* <FormQuotaSelector
              name="quotaId"
              label={'Quota'}
              control={control}
              filters={{ courseId: watch('courseId') }}
              api="paymentx/quotas"
            /> */}
            <ActionButton type="submit" fullWidth>
              Submit
            </ActionButton>
            {leadCounsellorsData && (
              <ActionButton
                variant="outlined"
                fullWidth
                onClick={() => handleExport(leadCounsellorsData)}
              >
                Export
              </ActionButton>
            )}
          </Stack>
        </form>
      </Box>

      <Box p={3}>
        <Table
          columns={columns}
          dataSource={leadCounsellorsData?.result ?? []}
          loading={isLoading}
          sx={{
            TableCell: {
              borderRightColor: '#1212121A',
              borderRightStyle: 'solid',
            },
          }}
        />
      </Box>
    </>
  )
}

export default LeadCounsellorReport
