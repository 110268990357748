import {
  Breadcrumbs,
  DeleteButton,
  DialogButton,
  DrawerButton,
  FilterButton,
  PageContent,
  PageHeader,
  Permission,
  ReactTable,
  SearchBar,
  SingleSelect,
  ValidateAccess,
  ViewButton,
  axios,
  axiosErrorToast,
  useConfirm,
  useHistory,
} from '@campxdev/shared'
import { Box, Button, Typography, styled } from '@mui/material'
import { AxiosRequestConfig } from 'axios'
import { admissionStatus, batchOptions } from 'constants/UIConstants'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import AdmissionFilters, { admissionsStore } from './AdmissionFilters'
import AdmissionForm from './AdmissionForm'
import BulkAssignCounsellor from './AssignCounsellor'
import { DeleteAdmissions } from './DeleteAdmissions'
import ImportDialog from './ImportDialog'
import MovetoInstitution from './MovetoInstitution'
import MovetoNotification from './MovetoNotification'
import { getAdmissionExport } from './services'

export const statusData = {
  ADMITTED: 'Admitted',
  IN_PROGRESS: 'In Progress',
  OPEN: 'Open',
  REJECTED: 'Rejected',
  CANCELLED: 'Cancelled',
  CONFIRMED: 'Confirmed',
}

export const fetchNotificationAdmissions = (id, params) => {
  return axios
    .get(`/paymentx/admissions`, {
      params: {
        ...params,
        admissionNotificationId: id == 'all-admissions' ? undefined : id,
        skip: params.offset,
      },
    })
    .then((res) => res.data)
}

function ViewAdmissions() {
  let { id } = useParams()
  const [admissionsData, setAdmissionsData] = useState([])
  const [admissionCount, setAdmissionCount] = useState(null)
  const queryClient = useQueryClient()
  const { isConfirmed } = useConfirm()
  const [filtersApplied, setFiltersApplied] = useState(false)
  const [open, setOpen] = useState(false)
  const [admissionId, setAdmissionId] = useState('')
  const history = useHistory()
  const filters = admissionsStore.useState((s) => s)

  const urlParams = new URLSearchParams(window.location.search)
  const batch = urlParams.get('batch')

  const isAllAdmissions = id == 'all-admissions'
  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['notification-admissions', filters],
    () =>
      fetchNotificationAdmissions(id, {
        ...filters,
        lateralEntry:
          filters.lateralEntry == false ? undefined : filters.lateralEntry,
        startDate: filters?.startDate
          ? moment(filters.startDate).format('YYYY-MM-DD')
          : undefined,
        endDate: filters?.endDate
          ? moment(filters.endDate).format('YYYY-MM-DD')
          : undefined,
      }),
    {
      onSuccess(data) {
        const modifiedData = data?.admissions.map((item) => ({
          ...item,
          id: item.id,
        }))
        setAdmissionsData(modifiedData)
        setAdmissionCount(data?.count)
      },
    },
  )

  const sessionFilters = JSON.parse(sessionStorage.getItem('view-admissions'))

  const handlePagination = (offset: number) => {
    admissionsStore.update((s) => {
      s.offset = sessionFilters?.offset ?? offset
    })
  }
  const handlePageLimit = (value: number) => {
    admissionsStore.update((s) => {
      s.limit = sessionFilters?.limit ?? value
      s.offset = sessionFilters?.offset ?? 0
    })
  }

  const onDelete = async (id: any) => {
    const confirmed = await isConfirmed('Are you sure you want to delete?')
    if (!confirmed) return

    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `/paymentx/admissions/delete`,
      data: {
        admissionIds: [id],
        reason: 'Need to delete The Admission',
      },
    }

    try {
      await axios(config)
      queryClient.invalidateQueries('notification-admissions')
      toast.success('Admission Deleted Successfully')
    } catch (error) {
      axiosErrorToast(error)
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'uniqueId',
      key: 'id',
    },
    {
      title: 'Roll. No.',
      dataIndex: 'rollNo',
      key: 'rollNo',
    },

    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (cellData) => {
        return cellData?.toUpperCase()
      },
    },
    {
      title: 'Quota',
      dataIndex: 'quota.name',
      key: 'quota.name',
    },
    {
      title: 'Degree',
      dataIndex: 'course.courseName',
      key: 'course.courseName',
    },
    {
      title: 'Program',
      dataIndex: 'program.branchCode',
      key: 'branchCode',
    },
    {
      title: 'Counselled By',
      dataIndex: 'counselledBy.fullName',
      key: 'counselledBy.fullName',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (cellData, row) => moment(cellData).format('DD/MM/YYYY'),
      // sort: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (cellData) => (
        <StyledStatus statusType={cellData}>
          {statusData[cellData]}
        </StyledStatus>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'admissionType',
      key: 'type',
    },
    {
      title: 'Confirmed Date',
      dataIndex: 'confirmedAt',
      key: 'confirmedAt',
      render: (cellData, row) =>
        cellData != null ? moment(cellData).format('DD/MM/YYYY') : '--',
      sort: true,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, row) => {
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            <ValidateAccess key="3" accessKey={Permission.ADMISSIONS_VIEW}>
              <ViewButton
                onClick={() => {
                  history.push(`/admissions/${id}/${row?.id}`)
                  sessionStorage.setItem(
                    'view-admissions',
                    JSON.stringify({
                      limit: filters.limit,
                      offset: filters.offset,
                      page: filters.offset / filters.limit,
                    }),
                  )
                }}
              />
            </ValidateAccess>
            <ValidateAccess key="3" accessKey={Permission.ADMISSIONS_DELETE}>
              {row?.status != admissionStatus.ADMITTED && (
                <DeleteButton
                  onClick={() => {
                    setOpen(true)
                    setAdmissionId(row?.id)
                  }}
                />
              )}
            </ValidateAccess>
          </div>
        )
      },
    },
  ]

  const handleSort = (sort) => {
    const sortBy = sort?.sortBy.length ? sort.sortBy : 'createdAt'
    const sortOrder = sort?.sortOrder ? sort.sortOrder : 'desc'
    admissionsStore.update((s) => {
      s.sortBy = sortBy
      s.sortOrder = sortOrder
    })
  }
  const setNotificationFilters = () => {
    admissionsStore.update((s) => {
      s.quotaId = ''
      s.programId = ''
      s.courseId = ''
      s.batch = batch
    })
  }
  useEffect(() => {
    if (!isAllAdmissions) {
      setNotificationFilters()
    }
  }, [isAllAdmissions])
  const handleBatchDelete = async (ids, clearSelection) => {
    const confirm = await isConfirmed(
      'Are you sure you want to delete all the selected rows?',
    )

    if (!confirm) return

    try {
      await axios.post(`/paymentx/admissions/delete`, {
        admissionIds: ids.map((id) => id),
        reason: 'Batch delete',
      })
      queryClient.invalidateQueries('notification-admissions')
      toast.success('Deleted Successfully')
      clearSelection()
    } catch (error) {
      axiosErrorToast(error)
    }
  }

  useEffect(() => {
    Object.keys(filters).some((key) => {
      if (
        (!!filters[key] &&
          key != 'limit' &&
          key != 'batch' &&
          key != 'sortBy' &&
          key != 'sortOrder' &&
          key != 'offset' &&
          key != 'page') ||
        filters.status != '' ||
        filters.paidFee != null
      ) {
        setFiltersApplied(true)
      }
    })

    if (sessionStorage.getItem('view-admissions')) {
      sessionStorage.removeItem('view-admissions')
    }
  }, [])

  return (
    <>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { to: `/admissions`, name: 'All Admissions ' },
              { to: null, name: 'View' },
            ]}
          />
        }
        actions={[
          <ValidateAccess key="1" accessKey={Permission.ADMISSIONS_EXPORT}>
            <Button
              variant="outlined"
              onClick={() => getAdmissionExport(id, filters)}
              sx={{ fontSize: '14px', fontWeight: 600 }}
            >
              Export
            </Button>
          </ValidateAccess>,
          <ValidateAccess key="2" accessKey={Permission.ADMISSIONS_IMPORT}>
            <ImportDialog
              uploadUrl="/paymentx/admissions/import-admissions"
              buttonText="Import"
              dialogTitle="Import Admissions"
              sampleFileUrl="/static_files/admissions_Sample_file.xlsx"
              successMessage="Admissions Imported Successfully"
              refetchFn={() => {
                refetch()
              }}
              buttonProps={{ sx: { fontSize: '14px', fontWeight: 600 } }}
              admissionNotificationId={id}
            />
          </ValidateAccess>,
          <ValidateAccess key="3" accessKey={Permission.ADMISSIONS_ADD}>
            <DrawerButton
              anchor={({ open }) => (
                <Button onClick={open} variant="outlined">
                  New Admission
                </Button>
              )}
              content={({ close }) => (
                <AdmissionForm
                  hideDialog={close}
                  data={null}
                  notificationId={id}
                />
              )}
              title="New Admission"
            />
          </ValidateAccess>,
        ]}
      />
      <PageContent>
        <Box sx={{ display: 'flex', alignItems: 'end', marginBottom: '20px' }}>
          {isAllAdmissions && (
            <Box sx={{ width: '180px' }}>
              <SingleSelect
                label={'Batch'}
                name={'batch'}
                value={filters.batch}
                options={batchOptions.map((item, index) => ({
                  label: item,
                  value: item,
                }))}
                onChange={(e) => {
                  admissionsStore.update((s) => {
                    s.batch = e.target.value
                  })
                }}
              />
            </Box>
          )}

          <Box mx={2}>
            <SearchBar
              onSearch={(v) => {
                admissionsStore.update((s) => {
                  s.search = v
                })
              }}
              textFieldProps={{
                label: 'Search by name / ID /Roll no',
              }}
            />
          </Box>
          <DrawerButton
            anchor={({ open }) => (
              <FilterButton onClick={open} filtersApplied={filtersApplied} />
            )}
            content={({ close }) => (
              <AdmissionFilters
                hideDialog={close}
                refetch={refetch}
                filtersApplied={setFiltersApplied}
                isAllAdmissions={isAllAdmissions}
              />
            )}
            title="Apply Filters"
          />
        </Box>

        <Box>
          <ReactTable
            key={'admission'}
            dataSource={admissionsData ?? []}
            columns={columns}
            loading={isLoading || isRefetching}
            pagination={{
              page: filters.offset / filters.limit,
              limit: filters.limit,
              totalCount: admissionCount as number,
              onChange: handlePagination,
              onChangeLimit: handlePageLimit,
            }}
            onSort={handleSort}
            select={{
              enable: true,
              actions: [
                {
                  component: (ids, clear) => (
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      {filters?.status != admissionStatus.ADMITTED && (
                        <>
                          <ValidateAccess
                            accessKey={Permission.ADMISSIONS_EDIT}
                          >
                            <DialogButton
                              title="Select Institution"
                              anchor={({ open }) => (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  onClick={open}
                                >
                                  Move to Institution
                                </Button>
                              )}
                              content={({ close }) => (
                                <MovetoInstitution
                                  clear={clear}
                                  ids={ids}
                                  close={close}
                                  type="admission"
                                />
                              )}
                            />
                          </ValidateAccess>
                          <ValidateAccess
                            accessKey={Permission.ADMISSIONS_EDIT}
                          >
                            <DialogButton
                              title="Move to Notification"
                              anchor={({ open }) => (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  onClick={open}
                                >
                                  Move to Notification
                                </Button>
                              )}
                              content={({ close }) => (
                                <MovetoNotification
                                  clear={clear}
                                  ids={ids}
                                  close={close}
                                />
                              )}
                            />
                          </ValidateAccess>

                          <ValidateAccess
                            accessKey={Permission.ADMISSIONS_EDIT}
                          >
                            <DialogButton
                              title="Bulk Assign Counsellor"
                              anchor={({ open }) => (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  onClick={open}
                                >
                                  Assign Counsellor
                                </Button>
                              )}
                              content={({ close }) => (
                                <BulkAssignCounsellor
                                  clear={clear}
                                  ids={ids}
                                  close={close}
                                />
                              )}
                            />
                          </ValidateAccess>
                          <ValidateAccess
                            accessKey={Permission.ADMISSIONS_DELETE}
                          >
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={() => {
                                handleBatchDelete(ids, clear)
                              }}
                            >
                              Delete Selected
                            </Button>
                          </ValidateAccess>
                        </>
                      )}
                    </Box>
                  ),
                },
              ],
            }}
          />
        </Box>
        <DeleteAdmissions
          open={open}
          admissionId={admissionId}
          setOpen={setOpen}
        />
      </PageContent>
    </>
  )
}

export const StyledStatus = styled(Typography)<{
  statusType:
    | 'ADMITTED'
    | 'IN_PROGRESS'
    | 'OPEN'
    | 'REJECTED'
    | 'CONFIRMED'
    | 'CANCELLED'
}>(({ statusType }) => ({
  fontWeight: '700',
  color:
    statusType === 'ADMITTED'
      ? '#609D52'
      : statusType === 'IN_PROGRESS'
      ? '#EE9C38'
      : statusType === 'OPEN'
      ? '#5BB9D1'
      : statusType === 'REJECTED'
      ? '#F2353C'
      : statusType === 'CANCELLED'
      ? '#F2353C'
      : statusType === 'CONFIRMED'
      ? '#1E19F5'
      : '',
}))

export default ViewAdmissions
