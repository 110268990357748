import { axios } from '@campxdev/shared'
import { Store } from 'pullstate'
import * as XLSX from 'xlsx'

export const fetchLeadExamRegistrations = async (params) => {
  const res = await axios.get('/paymentx/leads/lead-exam-registration', {
    params: {
      ...params,
    },
  })
  return res.data
}

export const thinkExamRegistrations = async ({ leadIds }) => {
  const res = await axios.post('/paymentx/leads/think-exam-registrations', {
    leadIds,
  })
  return res.data
}

export const leadRegistrationStore = new Store({
  search: '',
  examMode: null,
  examDate: null,
  examSlot: null,
  skip: 0,
  limit: 10,
  center: null,
  groupName: null,
  examStatus: null,
})

export const handleExportLeadRegistrations = (data) => {
  console.log(data)
  const excelData = data?.map((item) => ({
    LeadID: item?.uniqueId,
    Status: item?.examStatus ?? '-',
    'Lead Name': item?.fullName,
    Email: item?.email,
    'Mobile Number': item?.mobile ?? '-',
    'Exam Mode': item?.onlineTransaction?.order?.notes?.examMode ?? '-',
    'Start Date': item?.examResults?.startDate,
    'End Date': item?.examResults?.endDate,
    Marks: item?.examResults?.marks,
  }))

  const fileName = `LeadExamRegistration.xlsx`
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'leadExamRegistration')
  XLSX.writeFile(wb, fileName)
}

export const OfflineDates = [
  {
    label: '29-04-2024',
    value: '29-04-2024',
  },
  {
    label: '30-04-2024',
    value: '30-04-2024',
  },
  {
    label: '01-05-2024',
    value: '01-05-2024',
  },
  {
    label: '02-05-2024',
    value: '02-05-2024',
  },
  {
    label: '03-05-2024',
    value: '03-05-2024',
  },
  {
    label: '06-05-2024',
    value: '06-05-2024',
  },
  {
    label: '07-05-2024',
    value: '07-05-2024',
  },
  {
    label: '08-05-2024',
    value: '08-05-2024',
  },
]

export const OnlineDates = [
  {
    label: '03-05-2024',
    value: '03-05-2024',
  },
  {
    label: '06-05-2024',
    value: '06-05-2024',
  },
  {
    label: '07-05-2024',
    value: '07-05-2024',
  },
  {
    label: '08-05-2024',
    value: '08-05-2024',
  },
]

export const OfflineTimeSlots = [
  {
    label: '10:00 AM - 12:00 PM',
    value: '10:00 AM - 12:00 PM',
  },

  {
    label: '12:30 PM - 02:30 PM',
    value: '12:30 PM - 02:30 PM',
  },

  {
    label: '03:00 PM - 05:00 PM ',
    value: '03:00 PM - 05:00 PM ',
  },
]

export const OnlineTimeSlots = [
  {
    label: '10:10 AM - 12:10 PM',
    value: '10:10 AM - 12:10 PM',
  },

  {
    label: '12:40 PM - 02:40 PM',
    value: '12:40 PM - 02:40 PM',
  },

  {
    label: '03:10 PM - 05:10 PM ',
    value: '03:10 PM - 05:10 PM ',
  },
  {
    label: '05:30 PM - 07:30 PM ',
    value: '05:30 PM - 07:30 PM ',
  },
]

export const centers = [
  {
    label: 'Rajahmundry',
    value:
      'Godavari Institute of Engineering and Technology,  NH - 16, Chaitanya Knowledge City, Rajahmundry - 533296',
  },
  {
    label: 'Visakhapatnam',
    value:
      'Chaitanya Engineering College,  Kommadi Village, Road, Chaitanya Valley, Madhurawada, Visakhapatnam, Andhra Pradesh 530048',
  },
  {
    label: 'Amalapuram',
    value:
      'Konaseema Inst. of Medical Sciences & Research Foundation, Chaitanya Health City, NH216, Amalapuram, Andhra Pradesh 533201',
  },
]
