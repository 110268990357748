import { ErrorBoundary, Spinner, useAuth } from '@campxdev/shared'
import { Outlet } from 'react-router-dom'

function PrintLayout() {
  const { data, loading } = useAuth({
    permissionsEndpoint: '/auth-server/auth/my-permissions',
  })

  if (loading) return <Spinner />
  return (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  )
}

export default PrintLayout
