import { IOption } from '@campxdev/shared/src/components/Input/types'

export const phasesData: IOption[] = [
  { label: 'Phase-1', value: 'Phase-1' },
  { label: 'Phase-2', value: 'Phase-2' },
  { label: 'Phase-3', value: 'Phase-3' },
]

export const phaseOptions: IOption[] = phasesData
  ? [
      ...phasesData.map((item: IOption) => ({
        label: item.label,
        value: item.value,
      })),
    ]
  : []

export const phaseStatus = [
  { label: 'JOINED', value: 'JOINED' },
  { label: 'CHANGED_BRANCH', value: 'CHANGED_BRANCH' },
  { label: 'RETAINED', value: 'RETAINED' },
  { label: 'LEFT', value: 'LEFT' },
]
