import { axios } from '@campxdev/shared'

const ADMISSONS = '/paymentx/admissions'
export const admissions = {
  async fetchQuotas() {
    const res = await axios.get('/square/quotas')
    return res.data
  },

  async fetchCourses() {
    const res = await axios.get('/square/courses')
    return res.data
  },

  async fetchDomainCourses() {
    const res = await axios.get('/paymentx/domain/courses')
    return res.data
  },

  async fetchFeeOptions(admissionId: string) {
    const res = await axios.get(`${ADMISSONS}/${admissionId}/fee-options`)
    return res.data
  },

  async fetchDomainData({ id }) {
    let courseData = await axios.get(`/paymentx/domain/courses`, {
      params: { institutionId: id },
    })
    let progData = await axios.get('/paymentx/domain/programs', {
      params: { institutionId: id },
    })
    let quotaData = await axios.get('/paymentx/quotas', {
      params: { institutionId: id },
    })
    let notificationData = await axios.get(
      '/paymentx/admission-notifications',
      {
        params: { institutionId: id },
      },
    )
    return {
      courses: courseData.data,
      notifications: notificationData.data.notifications,
      programs: progData.data,
      quotas: quotaData.data?.quotas,
    }
  },

  async fetchCourseAndNotificationData({ id, sId }) {
    let courseData = await axios.get('/square/courses/list', {
      params: { institutionId: id },
    })
    let notificationsData = await axios.get(
      '/paymentx/admission-notifications',
      {
        params: { institutionId: sId },
      },
    )
    return {
      courses: courseData.data.courses,
      notifications: notificationsData.data.notifications,
    }
  },

  async fetchQuotaAndPrograms({ id, courseId, sId, sCourseId }) {
    let branchData = await axios.get('/square/branches/all', {
      params: { institutionId: id, courseId: courseId },
    })
    let quotaData = await axios.get('/paymentx/quotas', {
      params: { institutionId: sId, courseId: sCourseId },
    })

    return {
      branches: branchData.data,
      quotas: quotaData.data?.quotas,
    }
  },

  async createFees(body: {
    admissionId: number
    remarks: string
    image: string
    tuitionFees: {
      id?: number
      year: number
      originalAmount: number
      concessionFee: number
      scholarshipAmount: number
    }[]
  }) {
    return axios.post(`${ADMISSONS}/create-fees`, body)
  },

  async fetchAdmissionNotifications() {
    const res = await axios.get(`/square/admission-notifications`)
    return res.data
  },

  async fetchDoaminAdmissionNotifications() {
    const res = await axios.get(`/paymentx/admission-notifications`)
    return res.data
  },

  //dashboard
  async fetchAdmissionsCount({ batch }: { batch: string }) {
    let counts = await axios.get(`${ADMISSONS}/dashboard-stats`, {
      params: { batch },
    })
    return counts.data
  },

  async fetchAdmissions(filters: any) {
    try {
      const response = await axios.get('/paymentx/admissions', {
        params: filters,
      })

      return response.data
    } catch (error) {
      throw error
    }
  },
}
