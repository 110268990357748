import { axios } from '@campxdev/shared'
import { Store } from 'pullstate'

export const CertificateType = [
  {
    label: 'Bonafide Certificate',
    value: 'bonafide',
  },
]

export const CertificateSubtypes = {
  bonafide: [
    {
      label: 'PassPort',
      value: 'passport',
    },
    {
      label: 'Driving License',
      value: 'driving-license',
    },
    {
      label: 'Scholarship',
      value: 'scholarship',
    },
    {
      label: 'LLR',
      value: 'llr',
    },
    {
      label: 'Job',
      value: 'job',
    },
    {
      label: 'sangam(Dwakra Group)',
      value: 'sangam(Dwakra Group)',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ],
}

interface IStore {
  selectedStudent: any
  students: any[]
  type: string
}
export const formStore = new Store<IStore>({
  students: [],
  selectedStudent: null,
  type: 'student',
})

export const searchStudents = ({
  type,
  search,
}: {
  type: 'rollNumber' | string
  search: string
}) => {
  return axios
    .get(`/paymentx/admissions/all-admissions`, {
      params: {
        type,
        search,
      },
    })
    .then((res) => res.data.admissions)
}

export const searchStudentWithName = ({
  type,
  search,
}: {
  type: 'name' | string
  search: string
}) => {
  return axios.get(`/square/students`, {
    params: {
      type,
      search,
    },
  })
}

export const getStudentCertificate = (id: string) => {
  return axios.get(`/square/student-certificates/${id}`).then((res) => res.data)
}

export const getStudentCertificates = ({
  ...rest
}: {
  limit: number
  skip: number
}) => {
  return axios
    .get(`/square/student-certificates`, {
      params: { ...rest },
    })
    .then((res) => res?.data)
}

interface CreateCertificate {
  studentId: number
  certificate: string
  purpose: string
  customAdmissionId?: string
  academicBatch?: string
}

export const createStudentCertificate = (postBody: CreateCertificate) => {
  return axios.post('/square/student-certificates', postBody)
}
export function intToRomanSmall(num) {
  const numerals = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X']

  if (num < 1 || num > 10) {
    return 'Number out of range (1 to 10)'
  }

  return numerals[num - 1]
}
