import { axios } from '@campxdev/shared'
import { AxiosRequestConfig } from 'axios'
import { set } from 'date-fns'

const CLUSTERS = '/paymentx/clusters'

export const clusters = {
  async getClusters() {
    let res = await axios.get(CLUSTERS)
    return res.data
  },

  async createCluster(body: {
    name: string
    id?: number
    clusterHeadId?: string
    globalUserIds?: string[]
  }) {
    let config: AxiosRequestConfig = {
      data: body,
      url: body?.id ? `${CLUSTERS}/${body.id}` : `${CLUSTERS}`,
      method: body?.id ? 'PUT' : 'POST',
    }
    return await axios(config)
  },

  async deleteCluster(id: number) {
    return await axios.delete(`${CLUSTERS}/${id}`)
  },

  async assignCounsellors(body: {
    clusterId: string
    globalUserIds: string[]
  }) {
    return await axios.put(`${CLUSTERS}/${body.clusterId}/assign-counsellors`, {
      globalUserIds: body.globalUserIds,
    })
  },
}
