import { Spinner, axios } from '@campxdev/shared'
import { Container } from '@mui/material'
import _ from 'lodash'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import FeeCard from './FeeCard'

function PrintStudentCard() {
  const [search] = useSearchParams()
  const { data, isLoading } = useQuery('print-student-card', () =>
    axios
      .get('/paymentx/ledger/academic-fee-details', {
        params: {
          id: search.get('id'),
          idType: search.get('idType'),
        },
      })
      .then((res) => res.data),
  )

  if (isLoading) return <Spinner />

  const feeDetails = Object.entries(_.groupBy(data?.feeDetails, 'year'))?.map(
    (item) => {
      return item
    },
  )

  return (
    <>
      <Container>
        <FeeCard data={data} feeDetails={feeDetails} />
      </Container>
    </>
  )
}

export default PrintStudentCard
