import { axios } from '@campxdev/shared'
import { services } from 'services'
import * as XLSX from 'xlsx'

export const fetchData = async () => {
  let courses = await services.courses.fetchDomainCourses()
  let quotas = await services.quotas.fetchDomainQuotas()

  return {
    courses: courses,
    quotas: quotas,
  }
}

export const handleExport = async (data) => {
  const excelData = data?.result?.map((item) => {
    const leadStatusCounts: { [key: string]: number } = {}

    for (const leadStatus in item.data) {
      leadStatusCounts[leadStatus.toUpperCase()] =
        item.data[leadStatus].count || 0
    }
    const totalCount = Object.values(leadStatusCounts).reduce(
      (acc, count) => acc + count,
      0,
    )

    return {
      Counsellor: item?.name,
      ...leadStatusCounts,
      Total: String(totalCount),
    }
  })

  const fileName = `LeadCounsellorReport.xlsx`
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
  const wb: XLSX.WorkBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'leadcounsellorreport')
  XLSX.writeFile(wb, fileName)
}

export const getLeadCounsellorReport = async (formData) => {
  let counsellorsData = await axios.get('/paymentx/leads/counsellor-report', {
    params: {
      courseId: formData.courseId,
      batch: formData.batch,
      quotaId: formData.quotaId && formData.quotaId,
    },
  })
  return counsellorsData.data
}
