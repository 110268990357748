import {
  ActionButton,
  PageContent,
  Table,
  batchOptions,
} from '@campxdev/shared'
import { Box } from '@mui/material'
import { useQuery } from 'react-query'
import { useImmer } from 'use-immer'
import LeadAdmissionFilter from './LeadAdmissionFilter'
import { fetchLeads, handleExport } from './service'
const defaultState = {
  status: '',
  courseId: '',
  programId: '',
  batch: batchOptions[0],
}
export const defaultObj = {
  page: 0,
  limit: 10,
  data: [],
}
function LeadAdmissionReport() {
  const [filters, setFilters] = useImmer(defaultState)
  const [state, setState] = useImmer(defaultObj)

  const {
    data: leads,
    isLoading,
    refetch,
  } = useQuery(['leads', filters], () => fetchLeads(filters), {
    onSuccess: (res) => {
      setState((s) => {
        s.data = res?.result?.slice(0, 10)
      })
    },
  })

  const handlePagination = (value: number) => {
    setState((s) => {
      s.page = value - 1
      s.data = leads?.result?.slice((value - 1) * s.limit, value * s.limit)
    })
  }
  const handlePageLimit = (value: number) => {
    setState((s) => {
      s.limit = value
      s.data = leads?.result?.slice(0, value)
    })
  }

  const columns = [
    {
      title: 'Lead ID',
      dataIndex: 'leadUniqueId',
      key: 'leadId',
    },
    {
      title: 'Lead Name',
      dataIndex: 'fullName',
      key: 'studentName',
    },

    {
      title: 'Converted By',
      dataIndex: 'createdByName',
      key: 'convertedByUserName',
      render: (_, row) => {
        return row?.createdByName ?? '-'
      },
    },

    {
      title: 'Counsellor Name',
      dataIndex: 'counselledByName',
      key: 'counsellorName',
      render: (_, row) => {
        return row?.counselledByName ?? '-'
      },
    },
    {
      title: 'ConfirmedBy',
      dataIndex: 'confirmedByUserName',
      key: 'confirmedByUserName',
      render: (_, row) => {
        return row?.confirmedByName ?? '-'
      },
    },

    {
      title: 'Admission Status',
      dataIndex: 'status',
      key: 'admissionStatus',
    },
  ]
  return (
    <>
      <ActionButton
        sx={{ position: 'absolute', right: '3%', top: '2%' }}
        variant="outlined"
        onClick={() => handleExport(leads?.result)}
      >
        Export
      </ActionButton>
      <PageContent>
        <LeadAdmissionFilter setFilters={setFilters} />
        <Box sx={{ marginTop: '50px' }}>
          <Table
            dataSource={state?.data || []}
            rowKey={'id'}
            loading={isLoading}
            columns={columns}
            pagination={{
              totalCount: leads?.count || 0,
              page: state.page,
              limit: state.limit,
              onChange: handlePagination,
              onChangeLimit: handlePageLimit,
            }}
          />
        </Box>
      </PageContent>
    </>
  )
}

export default LeadAdmissionReport
