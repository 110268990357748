import { TextField } from '@campxdev/shared'
import { Box, Button, Divider } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { toast } from 'react-toastify'
import axios from './utils/axios'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'lightgrey',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export default function PhdHallTicket() {
  const [loading, setLoading] = useState(false)
  const [rollNo, setRollNo] = useState(null)
  const [student, setStudent] = useState(null)

  const tenant = window.location.pathname.split('/')[1]

  async function handleSubmit() {
    setLoading(true)
    await axios
      .get(`/square/phd/get-hallticket/${rollNo}`)
      .then((res) => {
        setLoading(false)
        setStudent(res.data)
      })
      .catch((err) => {
        toast.error('Something went wrong')
      })
  }

  async function handlePrint() {
    window.print()
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '30px',
          alignItems: 'flex-end',
          padding: '30px',
          '@media print': {
            display: 'none',
          },
        }}
      >
        <Box sx={{ width: '300px' }}>
          <TextField
            name="rollNo"
            label={'Enter Roll Number'}
            onChange={(e) => setRollNo(e.target.value)}
          />
        </Box>
        <Box>
          <Button
            onClick={handleSubmit}
            sx={{ marginRight: '10px' }}
            disabled={loading}
          >
            {' '}
            Submit
          </Button>
          {student && (
            <Button variant="outlined" onClick={handlePrint}>
              Print
            </Button>
          )}
        </Box>
      </Box>
      {student && (
        <>
          <Box
            sx={{
              width: '900px',
              minHeight: '200px',
              margin: 'auto',
              flexDirection: 'column',
              border: '1px solid black',
              fontWeight: 'bold',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              <img
                style={{
                  width: '200px',
                  height: '80px',
                  objectFit: 'contain',
                  alignContent: 'center',
                }}
                src={student.institution.images?.url}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: '10px 20px',
                }}
              >
                <Box sx={{ marginLeft: '200px' }}>
                  <h1 style={{ fontWeight: 'bold' }}>
                    {student.exam ?? 'PhD Eligibility Test'}
                  </h1>
                  <h2
                    style={{
                      textDecoration: 'underline',
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    Hall Ticket
                  </h2>
                </Box>
                <img
                  src={student.photo}
                  alt="some image"
                  style={{
                    height: '80px',
                    width: '80px',
                    marginLeft: '100px',
                  }}
                />
              </Box>
            </Box>

            <TableContainer
              component={Paper}
              sx={{ overflow: 'hidden', boxShadow: 'none', border: 'none' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Table
                  sx={{ maxWidth: 700, fontWeight: 'bold' }}
                  aria-label="customized table"
                >
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell align="left">
                        <h3 style={{ fontSize: '17px', fontWeight: 'bold' }}>
                          Hall Ticket Number :
                        </h3>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                          {student.rollNo}
                        </span>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell align="left">
                        <h3 style={{ fontSize: '17px', fontWeight: 'bold' }}>
                          Name of the Applicant :
                        </h3>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                          {student.name}
                        </span>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell align="left">
                        <h3 style={{ fontSize: '17px', fontWeight: 'bold' }}>
                          Department :
                        </h3>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                          {student.school}
                        </span>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell align="left">
                        <h3 style={{ fontSize: '17px', fontWeight: 'bold' }}>
                          Date & Time of Test :
                        </h3>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                          18.11.2023 <br />
                          02.00 PM to 04.00 PM
                        </span>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </Box>
            </TableContainer>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '30px',
                alignItems: 'center',
                maxWidth: '90%',
                marginLeft: '35px',
              }}
            >
              <Box sx={{ height: '60px', textAlign: 'center' }}>
                <h4 style={{ margin: '60px 0px 0px 0px', fontWeight: 'bold' }}>
                  Signature of Applicant
                </h4>
              </Box>
              <Box sx={{ height: '60px', textAlign: 'center' }}>
                <img
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  src={
                    'https://d2ru9bn02q0adc.cloudfront.net/aupulse/exam-config/exam-controller-sign/2e928585-183e-4730-9b0b-e9cd1ee0917d.jpg'
                  }
                />
                <h4 style={{ fontWeight: 'bold' }}>
                  Controller of Examinations
                </h4>
              </Box>

              <Box sx={{ height: '60px', textAlign: 'center' }}>
                <img
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  src={
                    'https://d2ru9bn02q0adc.cloudfront.net/aupulse/exam-config/dean-sign/7f7be5ef-679e-423d-bf84-56ee554dcf1f.JPG'
                  }
                />
                <h4 style={{ fontWeight: 'bold' }}>
                  {tenant === 'vpulse' || tenant === 'stpec'
                    ? 'Principal'
                    : 'Dean Examinations'}
                </h4>
              </Box>
            </Box>

            <Divider
              sx={{
                border: '2px solid black',
                width: '90%',
                marginLeft: '33px',
                marginTop: '30px',
              }}
            />

            <div style={{ textAlign: 'center', marginTop: '5px' }}>
              <img
                style={{
                  width: '200px',
                  height: '80px',
                  objectFit: 'contain',
                  alignContent: 'center',
                  marginLeft: '10px',
                }}
                src={student.institution.images?.url}
              />
            </div>
          </Box>
        </>
      )}
    </>
  )
}
