import {
  DrawerButton,
  DropDownButton,
  EditButton,
  PageContent,
  PageHeader,
  Table,
  ValidateAccess,
  axiosErrorToast,
  useConfirm,
} from '@campxdev/shared'
import { EnrollPermissions } from '@campxdev/shared/src/shared-state/PermissionsStore'
import { MoreVert } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { services } from 'services'
import AssignCounsellers from './AssignCounsellors'
import ClusterForm from './ClusterForm'

export default function Clusters() {
  const queryClient = useQueryClient()
  const { data, isLoading } = useQuery(
    'clusters',
    services.clusters.getClusters,
  )
  const { isConfirmed } = useConfirm()

  const handleDelete = async (record) => {
    const confirm = await isConfirmed('Are you sure you want to delete?')
    if (!confirm) return

    try {
      await services.clusters.deleteCluster(record?.id)
      toast.success('Deleted Successfully')
      queryClient.invalidateQueries('clusters')
    } catch (error) {
      axiosErrorToast(error)
    }
  }

  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Cluster Head',
      key: 'head',
      dataIndex: 'head.fullName',
    },
    {
      title: 'Co-ordinator',
      key: 'coordinator',
      dataIndex: 'coordinator.fullName',
      render: (text, row) => (
        <Box>
          {row.coordinators?.map((coordinator, index) => {
            return (
              <>
                <span>{coordinator.fullName}</span>
                {index !== row.coordinators.length - 1 && <span>,</span>}
              </>
            )
          })}
        </Box>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      render: (text, row) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <DropDownButton
            icon={{
              icon: <MoreVert />,
              outlined: false,
            }}
            menu={[
              {
                label: 'Assign Counsellors',
                contentTitle: 'Assign Counsellors',
                actionType: 'dialog',
                content: ({ close }) => (
                  <AssignCounsellers close={close} row={row} />
                ),
              },
            ]}
          />

          <ValidateAccess accessKey={EnrollPermissions.CAN_CLUSTERS_EDIT}>
            <DrawerButton
              key={0}
              title="Edit Cluster"
              anchor={({ open }) => <EditButton onClick={open} />}
              content={({ close }) => <ClusterForm close={close} data={row} />}
            />
          </ValidateAccess>
          {/* <ValidateAccess accessKey={EnrollPermission.CAN_CLUSTERS_DELETE}>
            <DeleteButton onClick={() => handleDelete(row)} />
          </ValidateAccess> */}
        </Box>
      ),
    },
  ]

  return (
    <div>
      <PageHeader
        title="Clusters"
        actions={[
          <ValidateAccess
            key={0}
            accessKey={EnrollPermissions.CAN_CLUSTERS_EDIT}
          >
            <DrawerButton
              key={0}
              title="Add New Cluster"
              anchor={({ open }) => <Button onClick={open}>Add New</Button>}
              content={({ close }) => <ClusterForm close={close} data={null} />}
            />
          </ValidateAccess>,
        ]}
      />
      <PageContent>
        <Table columns={columns} dataSource={data} loading={isLoading} />
      </PageContent>
    </div>
  )
}
