import { Add, Close } from '@mui/icons-material'
import {
  Box,
  Button,
  ButtonProps,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogProps,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  alpha,
  styled,
} from '@mui/material'

import {
  DeleteButton,
  EditButton,
  SingleSelect,
  UserStore,
  axios,
} from '@campxdev/shared'
import { batchOptions } from 'constants/UIConstants'
import { Store } from 'pullstate'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { services } from 'services'

const courseStore = new Store({
  courseId: null,
  quotaId: '',
  batch: '',
  lateralEntry: false,
  confirmAdmissions: false,
  hardUpdate: false,
  phase: '',
})

const StyledDialogHeader = styled(Box)(({ theme }) => ({
  height: '64px',
  backgroundColor: alpha(theme.palette.text.secondary, 0.1),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0.6rem 1rem',
}))
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  alignItems: 'center',
  padding: '40px',
}))

const StyledImage = styled((props: any) => (
  <Box>
    <img {...props} />
  </Box>
))(({ theme }) => ({
  height: '90px',
  width: '90px',
  '> img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
}))

const StyledChooseFileButton = styled(Button)(({ theme }) => ({
  padding: '15px 20px',
  height: '50px',
  '> .MuiBox-root': {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

const StyledFileLabel = styled(Box)(({ theme }) => ({
  background: theme.palette.secondary.light,
  borderRadius: '5px',
  padding: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

interface UploadFileDialogProps {
  buttonProps?: ButtonProps
  dialogProps?: DialogProps
  buttonText: string | ReactNode
  children?: ReactNode
  dialogTitle: ReactNode | string
  sampleFileUrl?: string
  uploadUrl: string
  successMessage?: string
  admissionNotificationId?: string
  refetchFn: () => void
}

async function fetchCourseData() {
  const courses = await services.courses.fetchDomainCourses()
  const quotas = await services.quotas.fetchDomainQuotas()
  return {
    courses: courses,
    quotas: quotas,
  }
}
function ImportDialog({
  buttonProps,
  dialogProps,
  buttonText,
  dialogTitle,
  uploadUrl,
  sampleFileUrl,
  successMessage,
  admissionNotificationId,
  refetchFn,
}: UploadFileDialogProps) {
  const inputRef: any = useRef(null)
  const [loading, setLoading] = useState(false)
  const filters = courseStore.useState((s) => s)
  const [file, setFile] = useState(null)
  const [open, setOpen] = useState(false)
  const [isValid, setisValid] = useState(true)
  const [dropDown, setDropDown] = useState(true)
  const onClose = () => {
    setOpen(false)
  }

  const {
    data: courseData,
    isLoading,
    error,
  } = useQuery(['courseData'], () => fetchCourseData())

  function handleNext() {
    if (filters.batch && filters.courseId && filters.quotaId && filters.phase) {
      setDropDown(false)
    } else {
      toast.error('Please select the required fields before import')
    }
  }

  const triggerUpload = () => {
    if (!inputRef.current) return
    inputRef?.current?.click()
  }

  const handleImageChange = (e) => {
    setFile(e.target.files[0])
  }

  const setFileInput = (value) => {
    setFile(value)
  }

  const handleOnUpload = () => {
    if (!file) {
      setisValid(false)
      return
    }
    const formData = new FormData()
    formData.append('file', file)
    formData.append('courseId', filters?.courseId)
    formData.append('batch', filters?.batch)
    formData.append('quotaId', filters?.quotaId)
    formData.append('phase', filters?.phase)
    filters?.lateralEntry == true &&
      formData.append('lateralEntry', `${filters?.lateralEntry}`)
    filters?.confirmAdmissions == true &&
      formData.append('confirmAdmissions', `${filters?.confirmAdmissions}`)

    formData.append('hardUpdate', `${filters?.hardUpdate}`)
    if (admissionNotificationId != 'all-admissions') {
      formData.append('admissionNotificationId', `${admissionNotificationId}`)
    }

    setLoading(true)

    axios
      .post(uploadUrl, formData)
      .then((res) => {
        setLoading(false)
        refetchFn()
        setOpen(false)
        toast.success(successMessage ?? 'Successful')
      })
      .catch((err) => {
        setLoading(false)
        toast.error(
          err?.response?.data?.message ?? 'Server Error While Uploading File',
        )
      })
  }

  useEffect(() => {
    return () => {
      setFile(null)
      inputRef.current = null
    }
  }, [])

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true)
          setDropDown(true)
        }}
        variant="outlined"
        {...buttonProps}
      >
        {buttonText}
      </Button>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        {...dialogProps}
      >
        <StyledDialogHeader>
          <Typography fontWeight={600}>{dialogTitle}</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </StyledDialogHeader>
        <StyledDialogContent>
          {dropDown ? (
            <DropDown
              filters={filters}
              quotas={courseData?.quotas}
              courses={courseData?.courses}
              handleNext={handleNext}
            />
          ) : (
            <UploadFile
              dialogTitle={dialogTitle}
              file={file}
              loading={loading}
              inputRef={inputRef}
              handleImageChange={handleImageChange}
              triggerUpload={triggerUpload}
              handleOnUpload={handleOnUpload}
              sampleFileUrl={sampleFileUrl}
              setFileInput={setFileInput}
              isValid={isValid}
            />
          )}
        </StyledDialogContent>
      </Dialog>
    </>
  )
}

export default ImportDialog

function UploadFile({
  dialogTitle,
  file,
  loading,
  inputRef,
  handleImageChange,
  triggerUpload,
  handleOnUpload,
  sampleFileUrl,
  setFileInput,
  isValid,
}) {
  return (
    <>
      <StyledImage src="/images/AnimatedUploadFile.gif" alt="Upload Image" />
      <Typography variant="h6">{dialogTitle}</Typography>
      <Typography textAlign={'center'}>
        Before importing, download Sample CSV File or Sample XLSX file and
        compare it with your sheet or edit the sample sheet
      </Typography>
      <Box sx={{ minWidth: '400px', marginBottom: '20px' }}>
        {!file ? (
          <>
            <input
              onChange={handleImageChange}
              hidden
              type="file"
              ref={inputRef}
            />
            <StyledChooseFileButton
              variant="outlined"
              fullWidth
              onClick={triggerUpload}
            >
              <Box>
                <Typography variant="h6" color="primary">
                  Choose File
                </Typography>
                <Add />
              </Box>
            </StyledChooseFileButton>
            {!isValid && (
              <Typography sx={{ color: 'red', margin: '10px 0px' }}>
                Please upload a file
              </Typography>
            )}
          </>
        ) : (
          <StyledFileLabel>
            <Typography variant="body2">{file?.name}</Typography>
            <Box display={'flex'} gap="16px">
              <EditButton
                onClick={() => {
                  triggerUpload()
                }}
              />
              <DeleteButton onClick={() => setFileInput(null)} />
            </Box>
          </StyledFileLabel>
        )}
      </Box>

      <Button
        fullWidth
        sx={{ maxWidth: '255px' }}
        onClick={handleOnUpload}
        endIcon={
          loading ? (
            <CircularProgress size={20} sx={{ color: 'white' }} />
          ) : null
        }
      >
        Upload File
      </Button>
      {sampleFileUrl && (
        <a href={sampleFileUrl} target="_blank" rel="noreferrer">
          <Button fullWidth sx={{ maxWidth: '255px' }} variant="outlined">
            Download Sample File
          </Button>
        </a>
      )}
    </>
  )
}

function DropDown({ quotas, filters, courses, handleNext }) {
  const quotaData = filters.courseId
    ? quotas?.filter((item) => {
        return item?.courseId === filters.courseId
      })
    : []

  const state = UserStore.useState()
  return (
    <Box sx={{ width: '100%' }}>
      <Stack gap={2.5}>
        <SingleSelect
          label={'Degree'}
          name={'courseId'}
          options={
            courses
              ? courses?.map((item) => {
                  return { label: item.courseName, value: item.id }
                })
              : []
          }
          onChange={(e) => {
            courseStore.update((s) => {
              s.courseId = e.target.value
            })
          }}
          required
          value={filters.courseId}
          fullWidth
        />
        <SingleSelect
          label={'Quota'}
          name={'quotaId'}
          options={quotaData?.map((item) => {
            return { label: item?.name, value: item?.id }
          })}
          onChange={(e) => {
            courseStore.update((s) => {
              s.quotaId = e.target.value
            })
          }}
          required
          value={filters.quotaId}
          fullWidth
        />
        <SingleSelect
          label={'Batch'}
          name={'batch'}
          options={batchOptions.map((item, index) => ({
            label: item,
            value: item,
          }))}
          onChange={(e) => {
            courseStore.update((s) => {
              s.batch = e.target.value
            })
          }}
          required
          value={filters.batch}
          fullWidth
        />

        <FormControlLabel
          value={filters?.lateralEntry}
          control={
            <Checkbox
              onChange={(e) => {
                courseStore.update((s) => {
                  s.lateralEntry = e.target.checked
                })
              }}
            />
          }
          label="Lateral Entry"
        />
        {state.user.isSuperUser && (
          <>
            <FormControlLabel
              value={filters?.confirmAdmissions}
              control={
                <Checkbox
                  onChange={(e) => {
                    courseStore.update((s) => {
                      s.confirmAdmissions = e.target.checked
                    })
                  }}
                />
              }
              label="Confirm Admissions"
            />

            <FormControlLabel
              value={filters?.hardUpdate}
              control={
                <Checkbox
                  onChange={(e) => {
                    courseStore.update((s) => {
                      s.hardUpdate = e.target.checked
                    })
                  }}
                />
              }
              label="Hard Update"
            />
          </>
        )}

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>Phase :</Typography>
          <Typography fontSize={14} component={'span'} color="error">
            {' *'}
          </Typography>
          <RadioGroup
            value={filters.phase}
            onChange={(e) => {
              courseStore.update((s) => {
                s.phase = e.target.value
              })
            }}
            row
            sx={{ margin: '0px 20px' }}
          >
            <FormControlLabel control={<Radio />} label="Phase-1" value="1" />
            <FormControlLabel control={<Radio />} label="Phase-2" value="2" />
            <FormControlLabel control={<Radio />} label="Phase-3" value="3" />
          </RadioGroup>
        </Box>

        <Button onClick={handleNext}>Next</Button>
      </Stack>
    </Box>
  )
}
