import { PageContent, axios } from '@campxdev/shared'
import { urlTenantKey } from '@campxdev/shared/src/contexts/Providers'
import { Box, Divider, Typography, styled } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { AdmissionStatusTable } from './AdmissionStatusTable'
import { AdmissionStatusFilters } from './AttendanceStatusFilter'

function AdmissionStatusReport() {
  const [intakeData, setIntakeData] = useState(null)

  const { data: course, isLoading: courseLoading } = useQuery('courses', () =>
    axios.get('/paymentx/domain/courses').then((res) => res.data?.courses),
  )

  return (
    <>
      <PageContent>
        <StyledHeaderBox>
          <Typography variant="h6">{_.upperCase(urlTenantKey)}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Divider orientation="horizontal" sx={{ width: '100px' }} />
            <Typography variant="h6" sx={{ fontSize: '24px' }}>
              Admission Status Report
            </Typography>
            <Divider orientation="horizontal" sx={{ width: '100px' }} />
          </Box>
          <AdmissionStatusFilters
            setIntakeData={setIntakeData}
            course={course}
          />
        </StyledHeaderBox>
        {intakeData && (
          <Box sx={{ marginTop: '20px' }}>
            <AdmissionStatusTable data={intakeData} course={course} />
          </Box>
        )}
      </PageContent>
    </>
  )
}
export default AdmissionStatusReport

export const StyledHeaderBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}))

export function dataColumn(data) {
  return data
}
