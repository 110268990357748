export const DashboardIcon = () => {
  return (
    <svg
      id="apps_2_"
      data-name="apps (2)"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Path_1377"
        data-name="Path 1377"
        d="M4.667,0h-2A2.667,2.667,0,0,0,0,2.667v2A2.667,2.667,0,0,0,2.667,7.333h2A2.667,2.667,0,0,0,7.333,4.667v-2A2.667,2.667,0,0,0,4.667,0ZM6,4.667A1.333,1.333,0,0,1,4.667,6h-2A1.333,1.333,0,0,1,1.333,4.667v-2A1.333,1.333,0,0,1,2.667,1.333h2A1.333,1.333,0,0,1,6,2.667Z"
        fill="#fff"
      />
      <path
        id="Path_1378"
        data-name="Path 1378"
        d="M17.667,0h-2A2.667,2.667,0,0,0,13,2.667v2a2.667,2.667,0,0,0,2.667,2.667h2a2.667,2.667,0,0,0,2.667-2.667v-2A2.667,2.667,0,0,0,17.667,0ZM19,4.667A1.333,1.333,0,0,1,17.667,6h-2a1.333,1.333,0,0,1-1.333-1.333v-2a1.333,1.333,0,0,1,1.333-1.333h2A1.333,1.333,0,0,1,19,2.667Z"
        transform="translate(-4.333)"
        fill="#fff"
      />
      <path
        id="Path_1379"
        data-name="Path 1379"
        d="M4.667,13h-2A2.667,2.667,0,0,0,0,15.667v2a2.667,2.667,0,0,0,2.667,2.667h2a2.667,2.667,0,0,0,2.667-2.667v-2A2.667,2.667,0,0,0,4.667,13ZM6,17.667A1.333,1.333,0,0,1,4.667,19h-2a1.333,1.333,0,0,1-1.333-1.333v-2a1.333,1.333,0,0,1,1.333-1.333h2A1.333,1.333,0,0,1,6,15.667Z"
        transform="translate(0 -4.333)"
        fill="#fff"
      />
      <path
        id="Path_1380"
        data-name="Path 1380"
        d="M17.667,13h-2A2.667,2.667,0,0,0,13,15.667v2a2.667,2.667,0,0,0,2.667,2.667h2a2.667,2.667,0,0,0,2.667-2.667v-2A2.667,2.667,0,0,0,17.667,13ZM19,17.667A1.333,1.333,0,0,1,17.667,19h-2a1.333,1.333,0,0,1-1.333-1.333v-2a1.333,1.333,0,0,1,1.333-1.333h2A1.333,1.333,0,0,1,19,15.667Z"
        transform="translate(-4.333 -4.333)"
        fill="#fff"
      />
    </svg>
  )
}
export const ConcessionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.333"
      height="16"
      viewBox="0 0 24 24"
    >
      <g id="earnings" transform="translate(24) rotate(90)">
        <g
          id="_01_align_center"
          data-name="01 align center"
          transform="translate(0 0)"
        >
          <path
            id="Path_16118"
            data-name="Path 16118"
            d="M22,24H13V22h7.586L0,1.414,1.414,0,22,20.586V13h2v9A2,2,0,0,1,22,24Z"
            fill="#fff"
          />
          <path
            id="Path_16119"
            data-name="Path 16119"
            d="M4,12a4,4,0,1,1-4,4A4,4,0,0,1,4,12Zm0,6a2,2,0,1,0-2-2,2,2,0,0,0,2,2ZM16,8a4,4,0,1,1,4-4A4,4,0,0,1,16,8Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,16,2Z"
            transform="translate(2 2)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}

export const UploadIcon = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g id="download" opacity="0.5">
        <path
          id="Path_9411"
          data-name="Path 9411"
          d="M9.294.733a2.5,2.5,0,0,1,3.537,0l2.676,2.676a.833.833,0,0,1-1.178,1.176L11.89,2.145,11.9,15a.833.833,0,0,1-.833.833h0A.833.833,0,0,1,10.229,15l-.008-12.84L7.8,4.584A.833.833,0,0,1,6.618,3.405Z"
          transform="translate(-1.062 0)"
          fill="#121212"
        />
        <path
          id="Path_9412"
          data-name="Path 9412"
          d="M19.167,16h0a.833.833,0,0,0-.833.833v3.333A.833.833,0,0,1,17.5,21H2.5a.833.833,0,0,1-.833-.833V16.833A.833.833,0,0,0,.833,16h0A.833.833,0,0,0,0,16.833v3.333a2.5,2.5,0,0,0,2.5,2.5h15a2.5,2.5,0,0,0,2.5-2.5V16.833A.833.833,0,0,0,19.167,16Z"
          transform="translate(0 -2.667)"
          fill="#121212"
        />
      </g>
    </svg>
  </>
)

export const UsersIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="users_3_"
        data-name="users (3)"
        d="M5,8.667a3,3,0,1,1,3-3,3,3,0,0,1-3,3ZM5,4A1.667,1.667,0,1,0,6.667,5.667,1.667,1.667,0,0,0,5,4Zm5,11.333V15A5,5,0,0,0,0,15v.333a.667.667,0,0,0,1.333,0V15a3.667,3.667,0,0,1,7.333,0v.333a.667.667,0,0,0,1.333,0ZM16,12A4.667,4.667,0,0,0,8.222,8.522a.667.667,0,1,0,.889.993A3.333,3.333,0,0,1,14.667,12,.667.667,0,0,0,16,12ZM11.667,6a3,3,0,1,1,3-3,3,3,0,0,1-3,3Zm0-4.667A1.667,1.667,0,1,0,13.333,3,1.667,1.667,0,0,0,11.667,1.333Z"
        fill="#fff"
      />
    </svg>
  )
}

export const CollegeFeedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="13.629"
      viewBox="0 0 16 13.629"
    >
      <path
        id="rectangle-horizontal"
        d="M12.667,3H3.333A3.333,3.333,0,0,0,0,6.333V13.3a3.333,3.333,0,0,0,3.333,3.333h9.333A3.333,3.333,0,0,0,16,13.3V6.333A3.333,3.333,0,0,0,12.667,3Zm2,10.3a2,2,0,0,1-2,2H3.333a2,2,0,0,1-2-2V6.333a2,2,0,0,1,2-2h9.333a2,2,0,0,1,2,2Z"
        transform="translate(0 -3)"
        fill="#fff"
      />
    </svg>
  )
}

export const ApplicationIcon = () => {
  return (
    <svg
      id="Layer_1"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
    >
      <path
        fill="#fff"
        d="m16 17a1 1 0 0 1 0 2h-1a1 1 0 0 1 0-2zm-2-2a1 1 0 0 1 -1-1v-1h-2v1a1 1 0 0 1 -2 0v-4a3 3 0 0 1 6 0v4a1 1 0 0 1 -1 1zm-1-4v-1a1 1 0 0 0 -2 0v1zm-1.711 5.3-1.612 1.63a.25.25 0 0 1 -.344.01l-.616-.64a1 1 0 0 0 -1.434 1.4l.626.644a2.255 2.255 0 0 0 3.186 0l1.616-1.644a1 1 0 0 0 -1.422-1.4zm9.711-9.643v12.343a5.006 5.006 0 0 1 -5 5h-8a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h6.343a4.969 4.969 0 0 1 3.536 1.465l1.656 1.656a4.969 4.969 0 0 1 1.465 3.536zm-4.535-3.778a3.042 3.042 0 0 0 -.465-.379v2.5h2.5a3.042 3.042 0 0 0 -.38-.465zm2.535 4.121h-3a2 2 0 0 1 -2-2v-3h-6a3 3 0 0 0 -3 3v14a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3z"
      />
    </svg>
  )
}

export const CetIcon = () => {
  return (
    <svg
      id="Layer_1"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
    >
      <path
        fill="#fff"
        d="m19.535 3.122-1.656-1.658a4.968 4.968 0 0 0 -3.536-1.464h-6.343a5.006 5.006 0 0 0 -5 5v14a5.006 5.006 0 0 0 5 5h8a5.006 5.006 0 0 0 5-5v-12.343a4.968 4.968 0 0 0 -1.465-3.535zm-1.414 1.414a2.932 2.932 0 0 1 .379.464h-2.5v-2.5a3.1 3.1 0 0 1 .465.38zm.879 14.464a3 3 0 0 1 -3 3h-8a3 3 0 0 1 -3-3v-14a3 3 0 0 1 3-3h6v3a2 2 0 0 0 2 2h3zm-3-10a1 1 0 0 1 0 2h-8a1 1 0 0 1 0-2zm1 5a1 1 0 0 1 -1 1h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1zm-.192 3.413a1 1 0 0 1 -.217 1.394 6.464 6.464 0 0 1 -3.456 1.193 3.252 3.252 0 0 1 -2-.7c-.328-.225-.453-.3-.7-.3a3.951 3.951 0 0 0 -1.832.794 1 1 0 0 1 -1.214-1.588 5.861 5.861 0 0 1 3.05-1.206 3.025 3.025 0 0 1 1.832.655 1.347 1.347 0 0 0 .864.345 4.586 4.586 0 0 0 2.277-.809 1 1 0 0 1 1.396.222z"
      />
    </svg>
  )
}

export const LogsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g id="clock_5_" data-name="clock (5)" transform="translate(0 0)">
        <path
          id="Path_1791"
          data-name="Path 1791"
          d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM8,14.667A6.667,6.667,0,1,1,14.667,8,6.667,6.667,0,0,1,8,14.667Z"
          fill="#fff"
        />
        <path
          id="Path_1792"
          data-name="Path 1792"
          d="M10.386,6a.667.667,0,0,0-.667.667V9.55L7.472,10.958a.668.668,0,0,0,.708,1.133l2.56-1.6a.667.667,0,0,0,.313-.572V6.667A.667.667,0,0,0,10.386,6Z"
          transform="translate(-2.386 -2)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export const ClassroomsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="presentation_1_"
        data-name="presentation (1)"
        d="M15.333,9.333h-.667v-6A3.337,3.337,0,0,0,11.333,0H4.667A3.337,3.337,0,0,0,1.333,3.333v6H.667a.667.667,0,1,0,0,1.333H7.333v2.667H6a2,2,0,0,0-2,2,.667.667,0,0,0,1.333,0A.667.667,0,0,1,6,14.667h4a.667.667,0,0,1,.667.667.667.667,0,0,0,1.333,0,2,2,0,0,0-2-2H8.667V10.667h6.667a.667.667,0,0,0,0-1.333Zm-12.667-6a2,2,0,0,1,2-2h6.667a2,2,0,0,1,2,2v6H2.667Z"
        fill="#fff"
      />
    </svg>
  )
}

export const ReceiptIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      viewBox="0 0 12 16"
    >
      <g id="receipt_2_" data-name="receipt (2)" transform="translate(-3)">
        <path
          id="Path_1412"
          data-name="Path 1412"
          d="M11.667,0H6.333A3.337,3.337,0,0,0,3,3.333v12a.667.667,0,0,0,1.043.55l1.4-.959,1.4.959a.667.667,0,0,0,.753,0L9,14.924l1.4.959a.667.667,0,0,0,.754,0l1.4-.959,1.4.958A.667.667,0,0,0,15,15.333v-12A3.337,3.337,0,0,0,11.667,0Zm2,14.067-.733-.5a.667.667,0,0,0-.755,0l-1.4.959-1.4-.959a.667.667,0,0,0-.754,0l-1.4.959-1.4-.959a.667.667,0,0,0-.753,0l-.739.5V3.333a2,2,0,0,1,2-2h5.333a2,2,0,0,1,2,2Z"
          fill="#fff"
        />
        <rect
          id="Rectangle_1393"
          data-name="Rectangle 1393"
          width="6.667"
          height="1.333"
          rx="0.667"
          transform="translate(5.667 5.333)"
          fill="#fff"
        />
        <rect
          id="Rectangle_1394"
          data-name="Rectangle 1394"
          width="5.333"
          height="1.333"
          rx="0.667"
          transform="translate(5.667 8)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export const SettingsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g id="settings-sliders" transform="translate(0 0)">
        <path
          id="Path_17828"
          data-name="Path 17828"
          d="M.667,3.167H2.491a2.485,2.485,0,0,0,4.8,0h8.046a.667.667,0,1,0,0-1.333H7.287a2.485,2.485,0,0,0-4.8,0H.667a.667.667,0,1,0,0,1.333ZM4.889,1.333A1.167,1.167,0,1,1,3.722,2.5,1.167,1.167,0,0,1,4.889,1.333Z"
          fill="#fff"
        />
        <path
          id="Path_17829"
          data-name="Path 17829"
          d="M15.333,10.083H13.509a2.485,2.485,0,0,0-4.8,0H.667a.667.667,0,1,0,0,1.333H8.713a2.485,2.485,0,0,0,4.8,0h1.824a.667.667,0,1,0,0-1.333Zm-4.222,1.833a1.167,1.167,0,1,1,1.167-1.167,1.167,1.167,0,0,1-1.167,1.167Z"
          transform="translate(0 -2.75)"
          fill="#fff"
        />
        <path
          id="Path_17830"
          data-name="Path 17830"
          d="M15.333,18.333H7.287a2.485,2.485,0,0,0-4.8,0H.667a.667.667,0,0,0,0,1.333H2.491a2.485,2.485,0,0,0,4.8,0h8.046a.667.667,0,0,0,0-1.333ZM4.889,20.167A1.167,1.167,0,1,1,6.055,19a1.167,1.167,0,0,1-1.167,1.167Z"
          transform="translate(0 -5.5)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export const ReportsIcon = () => {
  return (
    <svg
      id="stats_1_"
      data-name="stats (1)"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Path_1407"
        data-name="Path 1407"
        d="M15.333,14.667H2A.667.667,0,0,1,1.333,14V.667A.667.667,0,1,0,0,.667V14a2,2,0,0,0,2,2H15.333a.667.667,0,0,0,0-1.333Z"
        fill="#fff"
      />
      <path
        id="Path_1408"
        data-name="Path 1408"
        d="M14.667,17a.667.667,0,0,0,.667-.667V11.667a.667.667,0,1,0-1.333,0v4.667A.667.667,0,0,0,14.667,17Z"
        transform="translate(-4.667 -3.667)"
        fill="#fff"
      />
      <path
        id="Path_1409"
        data-name="Path 1409"
        d="M6.667,17a.667.667,0,0,0,.667-.667V11.667a.667.667,0,1,0-1.333,0v4.667A.667.667,0,0,0,6.667,17Z"
        transform="translate(-2 -3.667)"
        fill="#fff"
      />
      <path
        id="Path_1410"
        data-name="Path 1410"
        d="M18.667,15.333a.667.667,0,0,0,.667-.667v-8a.667.667,0,1,0-1.333,0v8A.667.667,0,0,0,18.667,15.333Z"
        transform="translate(-6 -2)"
        fill="#fff"
      />
      <path
        id="Path_1411"
        data-name="Path 1411"
        d="M10.667,15.333a.667.667,0,0,0,.667-.667v-8a.667.667,0,1,0-1.333,0v8A.667.667,0,0,0,10.667,15.333Z"
        transform="translate(-3.333 -2)"
        fill="#fff"
      />
    </svg>
  )
}

export const ExamsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      viewBox="0 0 12 16"
    >
      <path
        id="test_5_"
        data-name="test (5)"
        d="M11.667,11.333a.667.667,0,0,1,0,1.333H11a.667.667,0,0,1,0-1.333ZM10.333,10a.667.667,0,0,1-.667-.667V8.667H8.333v.667A.667.667,0,0,1,7,9.333V6.667a2,2,0,1,1,4,0V9.333A.667.667,0,0,1,10.333,10ZM9.667,7.333V6.667a.667.667,0,1,0-1.333,0v.667ZM8.526,10.867,7.451,11.953a.167.167,0,0,1-.229.007l-.411-.427a.668.668,0,0,0-.956.933l.417.429a1.5,1.5,0,0,0,2.124,0l1.077-1.1a.667.667,0,0,0-.948-.933ZM15,4.438v8.229A3.337,3.337,0,0,1,11.667,16H6.333A3.337,3.337,0,0,1,3,12.667V3.333A3.337,3.337,0,0,1,6.333,0h4.229a3.313,3.313,0,0,1,2.357.977l1.1,1.1A3.313,3.313,0,0,1,15,4.438ZM11.977,1.919a2.028,2.028,0,0,0-.31-.253V3.333h1.667a2.028,2.028,0,0,0-.253-.31Zm1.69,2.747h-2a1.333,1.333,0,0,1-1.333-1.333v-2h-4a2,2,0,0,0-2,2v9.333a2,2,0,0,0,2,2h5.333a2,2,0,0,0,2-2Z"
        transform="translate(-3 0)"
        fill="#fff"
      />
    </svg>
  )
}

export const ActivitiesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="trophy_1_"
        data-name="trophy (1)"
        d="M10.061,10.667C14.441,10.643,16,8.323,16,6.333a2.333,2.333,0,0,0-1.843-2.279c.091-.258.169-.495.222-.674a2.591,2.591,0,0,0-.417-2.305A2.653,2.653,0,0,0,11.819,0H4.181A2.653,2.653,0,0,0,2.038,1.075,2.591,2.591,0,0,0,1.621,3.38c.053.179.133.416.222.674A2.333,2.333,0,0,0,0,6.333c0,1.989,1.559,4.309,5.939,4.333A3.373,3.373,0,0,1,6,11.281v2.053a1.255,1.255,0,0,1-1.333,1.333H4A.667.667,0,0,0,4,16h8a.667.667,0,0,0,0-1.333h-.661A1.257,1.257,0,0,1,10,13.333V11.28a3.372,3.372,0,0,1,.061-.613Zm3.606-5.333a1,1,0,0,1,1,1c0,1.356-1.073,2.8-4.024,2.98a3.231,3.231,0,0,1,.508-.547,10.088,10.088,0,0,0,2.485-3.439C13.646,5.327,13.655,5.333,13.667,5.333Zm-12.333,1a1,1,0,0,1,1-1c.011,0,.021-.006.031-.007A10.088,10.088,0,0,0,4.849,8.766a3.231,3.231,0,0,1,.508.547C2.406,9.133,1.333,7.689,1.333,6.333Zm5.675,8.333a2.72,2.72,0,0,0,.325-1.333V11.281a4.62,4.62,0,0,0-1.621-3.53A10.225,10.225,0,0,1,2.9,3a1.267,1.267,0,0,1,.207-1.129,1.329,1.329,0,0,1,1.075-.537h7.639a1.329,1.329,0,0,1,1.075.54A1.267,1.267,0,0,1,13.1,3a10.225,10.225,0,0,1-2.813,4.748A4.619,4.619,0,0,0,8.667,11.28v2.053a2.72,2.72,0,0,0,.325,1.333Z"
        transform="translate(0 0)"
        fill="#fff"
      />
    </svg>
  )
}

export const AdmissionsIcon = () => {
  return (
    <svg
      id="user-add"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Path_1381"
        data-name="Path 1381"
        d="M20.667,10H19.333V8.667a.667.667,0,1,0-1.333,0V10H16.667a.667.667,0,0,0,0,1.333H18v1.333a.667.667,0,1,0,1.333,0V11.333h1.333a.667.667,0,0,0,0-1.333Z"
        transform="translate(-5.333 -2.667)"
        fill="#fff"
      />
      <path
        id="Path_1382"
        data-name="Path 1382"
        d="M7,8A4,4,0,1,0,3,4,4,4,0,0,0,7,8ZM7,1.333A2.667,2.667,0,1,1,4.333,4,2.667,2.667,0,0,1,7,1.333Z"
        transform="translate(-1 0)"
        fill="#fff"
      />
      <path
        id="Path_1383"
        data-name="Path 1383"
        d="M6,14a6.007,6.007,0,0,0-6,6,.667.667,0,1,0,1.333,0,4.667,4.667,0,1,1,9.333,0A.667.667,0,0,0,12,20,6.007,6.007,0,0,0,6,14Z"
        transform="translate(0 -4.667)"
        fill="#fff"
      />
    </svg>
  )
}

export const NotificationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.974"
      height="16"
      viewBox="0 0 15.974 16"
    >
      <path
        id="bell-ring"
        d="M13.907,10.22l-2.515,4.1a3.49,3.49,0,0,1-2.58,1.651A3.543,3.543,0,0,1,8.394,16a3.475,3.475,0,0,1-2-.637,3.161,3.161,0,0,1-4.459-4.377l-.877-.875a3.509,3.509,0,0,1,.637-5.467L5.539,2.266a5.906,5.906,0,0,1,6.885.367L13.53,1.528a.667.667,0,1,1,.943.943l-1.1,1.105a5.967,5.967,0,0,1,.539,6.645ZM5.378,14.431,2.9,11.951a1.814,1.814,0,0,0,.309,2.176A1.885,1.885,0,0,0,5.378,14.431Zm6.614-10.4A4.591,4.591,0,0,0,6.247,3.4L2.4,5.777A2.177,2.177,0,0,0,2,9.168l4.86,4.861a2.177,2.177,0,0,0,3.4-.4l2.5-4.083a4.625,4.625,0,0,0-.768-5.517ZM12.845,16a.667.667,0,0,1-.394-1.205,5.755,5.755,0,0,0,2.21-3.6.667.667,0,1,1,1.3.287,7.133,7.133,0,0,1-2.725,4.4.667.667,0,0,1-.393.128ZM.668,3.826A.667.667,0,0,1,.135,2.763,7.128,7.128,0,0,1,4.61.013.667.667,0,0,1,4.877,1.32,5.757,5.757,0,0,0,1.2,3.556a.667.667,0,0,1-.533.27Z"
        transform="translate(-0.004 0)"
        fill="#fff"
      />
    </svg>
  )
}

export const LibraryIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.207"
      height="16.294"
      viewBox="0 0 16.207 16.294"
    >
      <path
        id="books"
        d="M15.851,12.979,11.137,1.213A2,2,0,0,0,8.531.117L7.915.369a2.015,2.015,0,0,0-.453.268A1.986,1.986,0,0,0,6-.011H2a2,2,0,0,0-2,2V13.984a2,2,0,0,0,2,2H6a2,2,0,0,0,2-2V5.917l3.534,8.824a2.005,2.005,0,0,0,2.6,1.094l.617-.252a2.008,2.008,0,0,0,1.1-2.6ZM9.045,4.952,10.9,4.2l2.779,6.938-1.851.754ZM8.418,1.6l.616-.252a.667.667,0,0,1,.868.362l.5,1.247-1.851.754-.5-1.243A.667.667,0,0,1,8.419,1.6ZM1.333,4.654h2v6.664h-2Zm3.332,0h2v6.664h-2Zm2-2.666V3.322h-2v-2H6A.667.667,0,0,1,6.664,1.989ZM2,1.323H3.332v2h-2V1.989A.667.667,0,0,1,2,1.323ZM1.333,13.984V12.651h2v2H2A.667.667,0,0,1,1.333,13.984ZM6,14.65H4.665v-2h2v1.333A.667.667,0,0,1,6,14.65Zm8.616-.66a.663.663,0,0,1-.363.359h0l-.617.252a.667.667,0,0,1-.866-.36l-.446-1.114,1.851-.754.443,1.107a.658.658,0,0,1,0,.51Z"
        transform="translate(0.1 0.204)"
        fill="#fff"
        stroke="#121212"
        strokeWidth="0.2"
      />
    </svg>
  )
}

export const PlacementIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="briefcase"
        d="M12.667,2.667h-.733A3.339,3.339,0,0,0,8.667,0H7.333A3.339,3.339,0,0,0,4.067,2.667H3.333A3.337,3.337,0,0,0,0,6v6.667A3.337,3.337,0,0,0,3.333,16h9.333A3.337,3.337,0,0,0,16,12.667V6a3.337,3.337,0,0,0-3.333-3.333ZM7.333,1.333H8.667a2,2,0,0,1,1.877,1.333H5.456A2,2,0,0,1,7.333,1.333ZM3.333,4h9.333a2,2,0,0,1,2,2V8H1.333V6a2,2,0,0,1,2-2Zm9.333,10.667H3.333a2,2,0,0,1-2-2V9.333h6V10a.667.667,0,0,0,1.333,0V9.333h6v3.333A2,2,0,0,1,12.667,14.667Z"
        fill="#fff"
      />
    </svg>
  )
}

export const StudentsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="users_3_"
        data-name="users (3)"
        d="M5,8.667a3,3,0,1,1,3-3,3,3,0,0,1-3,3ZM5,4A1.667,1.667,0,1,0,6.667,5.667,1.667,1.667,0,0,0,5,4Zm5,11.333V15A5,5,0,0,0,0,15v.333a.667.667,0,0,0,1.333,0V15a3.667,3.667,0,0,1,7.333,0v.333a.667.667,0,0,0,1.333,0ZM16,12A4.667,4.667,0,0,0,8.222,8.522a.667.667,0,1,0,.889.993A3.333,3.333,0,0,1,14.667,12,.667.667,0,0,0,16,12ZM11.667,6a3,3,0,1,1,3-3,3,3,0,0,1-3,3Zm0-4.667A1.667,1.667,0,1,0,13.333,3,1.667,1.667,0,0,0,11.667,1.333Z"
        fill="#fff"
      />
    </svg>
  )
}

export const HostelsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="building"
        d="M4.667,9.333A.667.667,0,0,1,4,10H3.333a.667.667,0,1,1,0-1.333H4A.667.667,0,0,1,4.667,9.333Zm2.667-.667H6.667a.667.667,0,1,0,0,1.333h.667a.667.667,0,1,0,0-1.333ZM4,11.333H3.333a.667.667,0,1,0,0,1.333H4a.667.667,0,1,0,0-1.333Zm3.333,0H6.667a.667.667,0,1,0,0,1.333h.667a.667.667,0,1,0,0-1.333ZM4,3.333H3.333a.667.667,0,1,0,0,1.333H4A.667.667,0,1,0,4,3.333Zm3.333,0H6.667a.667.667,0,1,0,0,1.333h.667a.667.667,0,0,0,0-1.333ZM4,6H3.333a.667.667,0,1,0,0,1.333H4A.667.667,0,1,0,4,6ZM7.333,6H6.667a.667.667,0,1,0,0,1.333h.667A.667.667,0,0,0,7.333,6ZM16,6.667v6A3.337,3.337,0,0,1,12.667,16H3.333A3.337,3.337,0,0,1,0,12.667V3.333A3.337,3.337,0,0,1,3.333,0h4a3.337,3.337,0,0,1,3.333,3.333h2A3.337,3.337,0,0,1,16,6.667Zm-12.667,8h6V3.333a2,2,0,0,0-2-2h-4a2,2,0,0,0-2,2v9.333a2,2,0,0,0,2,2Zm11.333-8a2,2,0,0,0-2-2h-2v10h2a2,2,0,0,0,2-2Zm-2,2a.667.667,0,1,0,.667.667A.667.667,0,0,0,12.667,8.667Zm0,2.667a.667.667,0,1,0,.667.667A.667.667,0,0,0,12.667,11.333Zm0-5.333a.667.667,0,1,0,.667.667A.667.667,0,0,0,12.667,6Z"
        fill="#fff"
      />
    </svg>
  )
}

export const TaskListIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.452"
      height="15.229"
      viewBox="0 0 16.452 15.229"
    >
      <path
        id="list-check"
        d="M2.666,4.007a1.988,1.988,0,0,1-1.415-.586L.222,2.506a.667.667,0,0,1,.888-1l1.057.943a.667.667,0,0,0,.971.027L5.54.192a.667.667,0,0,1,.919.965L4.066,3.433A1.976,1.976,0,0,1,2.666,4.007ZM16,2.674a.667.667,0,0,0-.667-.667H8.666a.667.667,0,0,0,0,1.333h6.666A.667.667,0,0,0,16,2.674ZM4.066,8.766,6.459,6.49a.667.667,0,1,0-.919-.965L3.14,7.812a.682.682,0,0,1-.943,0L1.138,6.755A.667.667,0,1,0,.195,7.7L1.252,8.755a2,2,0,0,0,2.817.011ZM16,8.007a.667.667,0,0,0-.667-.667H8.666a.667.667,0,0,0,0,1.333h6.666A.667.667,0,0,0,16,8.007ZM4.066,14.1l2.39-2.276a.667.667,0,1,0-.919-.965l-2.4,2.287a.667.667,0,0,1-.971-.027L1.11,12.176a.667.667,0,1,0-.888,1l1.029.916a2,2,0,0,0,2.817.011ZM16,13.341a.667.667,0,0,0-.667-.667H8.666a.667.667,0,1,0,0,1.333h6.666A.667.667,0,0,0,16,13.341Z"
        transform="translate(0.253 0.225)"
        fill="#fff"
        stroke="#fff"
        stroke-width="0.4"
      />
    </svg>
  )
}
