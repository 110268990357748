import {
  Chips,
  DateRangePicker,
  MultiSelect,
  RadioGroup,
  SingleCheckbox,
  SingleSelect,
  Spinner,
  axios,
} from '@campxdev/shared'
import { Box, Button, Stack, Typography } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { services } from 'services'
import { useImmer } from 'use-immer'
import { leadStore } from './Leads'
import { fetchClusterHeadData } from './services'
const defaultState = {
  status: [],
  courseId: '',
  programId: '',
  type: '',
  endDate: null,
  startDate: null,
  documents: null,
  counsellorId: '',
  noCounsellor: false,
  source: [],
  isAts: false,
  excludeExamRegisteredLeads: null,
  clusterId: null,
}

const LeadType = ['Hot', 'Warm', 'Cold']

// const SourcesData = ['Facebook', 'Instagram', 'Organic', 'Website']

export async function fetchLeadData() {
  let courseData = await services.courses.fetchDomainCourses()
  let branchData = await services.programs.fetchPrograms()
  let counsellerData = await services.users.fetchCounsellors()
  let leadStatus = await axios.get('/paymentx/lead-status')
  let leadSources = await axios.get('/paymentx/lead-sources')
  return {
    courses: courseData,
    branches: branchData,
    counsellers: counsellerData?.counsellors,
    leadStatusData: leadStatus.data,
    leadSourcesData: leadSources.data,
  }
}
export default function LeadFilters({
  hideDialog,
  refetch,
  setFiltersApplied,
}) {
  const [filters, setFilters] = useImmer(defaultState)
  const _filters = leadStore.useState((s) => s)

  const [dateFilterType, setDateFilterType] = useState('')

  const { isLoading, data, error } = useQuery(['courses'], () =>
    fetchLeadData(),
  )

  const { data: clusterHeadData } = useQuery(
    'clusterHeads',
    fetchClusterHeadData,
  )

  const selectedCourseId = filters.courseId
  const selectedCluster = filters.clusterId
  const formattedData = selectedCluster
    ? data?.counsellers
        .filter((item) => item.clusterId == selectedCluster)
        ?.map((item) => ({
          label: item?.fullName,
          value: item?._id,
        }))
    : data?.counsellers?.map((item) => ({
        label: item?.fullName,
        value: item?._id,
      }))

  if (!isLoading) {
    var courseIdMap = _.chain(data.courses).keyBy('_id').mapValues('id').value()
  }

  const programData = selectedCourseId
    ? data?.branches?.filter((item) => {
        return item?.courseId === filters.courseId
      })
    : []

  const filterLeads = () => {
    leadStore.update((s) => {
      s.status = filters.status
      s.courseId = filters.courseId
      s.programId = filters.programId
      s.endDate = filters.endDate
      s.startDate = filters.startDate
      s.type = filters.type
      s.documents = filters.documents
      s.counsellorId =
        filters.counsellorId == 'all'
          ? ''
          : filters.counsellorId == 'unassigned'
          ? null
          : filters.counsellorId
      s.noCounsellor = filters.noCounsellor
      s.source = filters.source
      s.limit = 10
      s.skip = 0
      s.isATS = Boolean(filters.isAts)
      s.excludeExamRegisteredLeads = filters.excludeExamRegisteredLeads
      s.clusterId = filters.clusterId
    })

    setFiltersApplied(false)
    Object.keys(filters).some((key) => {
      if (
        !!filters[key] &&
        key != 'limit' &&
        key != 'skip' &&
        filters[key] != '' &&
        key != 'sortOrder'
      ) {
        setFiltersApplied(true)
      }
    })

    hideDialog()
  }
  const clearFilters = () => {
    leadStore.update((s) => {
      s.status = []
      s.courseId = ''
      s.programId = ''
      s.endDate = ''
      s.startDate = ''
      s.counsellorId = ''
      s.source = []
      s.documents = null
      s.noCounsellor = false
      s.type = ''
      s.limit = 10
      s.skip = 0
      s.isATS = false
      s.excludeExamRegisteredLeads = null
      s.clusterId = null
    })
    setFiltersApplied(false)
    hideDialog()
  }

  useEffect(() => {
    const defaultFilters = { ..._filters, isAts: Boolean(_filters.isATS) }
    setFilters(defaultFilters)
  }, [])

  const handleSelectSource = (v) => {
    if (
      v.length !== data.leadSourcesData?.length + 1 &&
      !v.find((item) => item?.value == 'all')
    ) {
      setFilters((s) => {
        s.source = v
      })
    } else if (v.find((item) => item?.value == 'all')) {
      setFilters((s) => {
        s.source = [
          {
            label: 'All',
            value: 'all',
          },
          ...data.leadSourcesData?.map((item) => ({
            label: item?.name,
            value: item?.name,
          })),
        ]
      })
    }
  }

  if (isLoading) return <Spinner />
  return (
    <>
      <Stack gap={3}>
        <Box>
          <Typography variant="subtitle2" my={1}>
            Filter by Lead Type
          </Typography>
          <Chips
            selected={filters.type}
            onSelected={(newState) => {
              setFilters((s) => {
                s.type = newState
              })
            }}
            list={LeadType?.map((item) => ({
              label: item,
              value: item,
            }))}
          />
        </Box>
        <Box>
          <SingleSelect
            value={filters?.courseId}
            label="Select Degree(s)"
            onChange={(e) => {
              setFilters((s) => {
                s.courseId = e.target.value
              })
            }}
            options={data?.courses?.map((item) => ({
              label: item?.courseName,
              value: item?.id,
            }))}
          />
        </Box>

        <Box>
          <RadioGroup
            label={'Exclude ExamRegistered Leads'}
            name={'excludeExamRegisteredLeads'}
            row={true}
            value={filters.excludeExamRegisteredLeads}
            onChange={(e) => {
              setFilters((s) => {
                s.excludeExamRegisteredLeads = e.target.value
              })
            }}
            options={[
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ]}
          />
        </Box>

        {selectedCourseId ? (
          <>
            <Box>
              <SingleSelect
                value={filters?.programId}
                label="Select Program(s)"
                onChange={(e) => {
                  setFilters((s) => {
                    s.programId = e.target.value
                  })
                }}
                options={
                  selectedCourseId
                    ? programData?.map((item) => ({
                        label: item?.branchName,
                        value: item?.id,
                      }))
                    : []
                }
              />
            </Box>
          </>
        ) : (
          ''
        )}

        <Box>
          <MultiSelect
            multiple={true}
            label={'Filter By Source'}
            name={'source'}
            limitTags={4}
            onChange={(v) => {
              handleSelectSource(v)
            }}
            options={data?.leadSourcesData?.map((item) => ({
              label: item.name,
              value: item.name,
            }))}
            value={filters.source}
          />
        </Box>
        <Box>
          <SingleCheckbox
            checked={filters.isAts}
            label="ATS Leads"
            onChange={(e) => {
              setFilters((s) => {
                s.isAts = !filters.isAts
              })
            }}
          />
        </Box>
        <Box>
          <Typography variant="subtitle2" my={1}>
            Filter by Status
          </Typography>
          <Chips
            singleSelection={false}
            selected={filters?.status}
            onSelected={(newState) => {
              setFilters((s) => {
                s.status = newState
              })
            }}
            list={data?.leadStatusData?.map((item) => ({
              label: item.name,
              value: item.name,
            }))}
          />
        </Box>
        <Box>
          <SingleSelect
            value={filters?.clusterId}
            label="Filter by Cluster"
            onChange={(e) => {
              setFilters((s) => {
                s.clusterId = e.target.value
              })
            }}
            options={
              clusterHeadData?.map((item) => ({
                label: item.name,
                value: item.id,
              })) || []
            }
          />
        </Box>
        <Box>
          <SingleSelect
            value={
              filters?.counsellorId == ''
                ? 'all'
                : filters?.counsellorId == null
                ? 'unassigned'
                : filters?.counsellorId
            }
            label="Filter by Counsellor"
            onChange={(e) => {
              setFilters((s) => {
                if (e.target.value === 'unassigned') {
                  s.noCounsellor = true
                  s.counsellorId = e.target.value
                } else {
                  s.noCounsellor = null
                  s.counsellorId = e.target.value
                }
              })
            }}
            options={[
              { label: 'All', value: 'all' },
              { label: 'Unassigned', value: 'unassigned' },

              ...formattedData,
            ]}
          />
        </Box>

        <Box>
          <SingleSelect
            value={filters?.documents}
            label="Filter by Document"
            onChange={(e) => {
              setFilters((s) => {
                s.documents = e.target.value
              })
            }}
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
          />
        </Box>

        <Box>
          <DateRangePicker
            label="Filter by Date"
            size="medium"
            value={{ start: filters?.startDate, end: filters?.endDate }}
            onChange={(newValue) => {
              setFilters((s) => {
                s.endDate = newValue.end
                s.startDate = newValue.start
              })
            }}
          />
        </Box>

        <Stack direction="row" gap={2} mt={0.5}>
          <Button fullWidth onClick={() => filterLeads()}>
            Apply Filters
          </Button>
          <Button variant="outlined" fullWidth onClick={() => clearFilters()}>
            Clear Filters
          </Button>
        </Stack>
      </Stack>
    </>
  )
}
