/* eslint-disable no-console */
import { Table } from '@campxdev/shared'
import PhaseTimeline from './PhaseTimeline'
import { PhaseLogsStore } from './store'

const columns = [
  {
    title: 'ID',
    dataIndex: 'uniqueId',
    key: 'id',
  },

  {
    title: 'Name',
    dataIndex: 'fullName',
    key: 'fullName',
  },

  {
    title: 'Batch',
    dataIndex: 'batch',
    key: 'batch',
  },

  {
    title: 'Phase',
    key: 'phase',
    dataIndex: '',
    render: (r, data) => <PhaseTimeline data={data} />,
  },
  {
    title: 'Phase Status',
    key: 'phaseStatus',
    dataIndex: 'phaseStatus',
    render: (r, data) => {
      if (r == 'CHANGED_BRANCH') {
        return `CHANGED_BRANCH`
      } else {
        return r
      }
    },
  },
]

const AdmissionLogsTable = ({ data, isLoading }) => {
  const logFilters = PhaseLogsStore.useState((s) => s)

  const handlePagination = (skip: number) => {
    PhaseLogsStore.update((s) => {
      s.skip = skip * s.limit - s.limit
    })
  }

  const handlePageLimit = (value: number) => {
    PhaseLogsStore.update((s) => {
      s.limit = value
      s.skip = 0
    })
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={data?.admissions ?? []}
        pagination={{
          page: logFilters.skip / logFilters.limit,
          limit: logFilters.limit,
          onChange: handlePagination,
          totalCount: data?.count ?? 0,
          onChangeLimit: handlePageLimit,
        }}
        loading={isLoading}
      />
    </>
  )
}
export default AdmissionLogsTable
