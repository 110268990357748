import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useRoutes } from 'react-router-dom'
import { mainRoutes } from 'routes/main'
import './styles/App.less'
import 'swiper/css'
import { Provider } from 'react-redux'
import store from 'redux/store'
import MenuPopoverProvider from 'context/MenuPopover'

function App() {
  const app = useRoutes(mainRoutes)
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MenuPopoverProvider>
          <Provider store={store}> {app}</Provider>
        </MenuPopoverProvider>
      </LocalizationProvider>
    </>
  )
}

export default App
