import { axios } from '@campxdev/shared'

const USERS = '/hrms/employees/search'
const paymentxCounsellors = '/paymentx/counsellors'

export const users = {
  async fetchCounsellors(params?: any) {
    const res = await axios.get(paymentxCounsellors, {
      params,
    })
    return res.data
  },

  async fetchUsers(params: {
    limit?: number
    offset?: number
    search?: string
    type: 'staff_user' | 'student'
  }) {
    const res = await axios.get(`${USERS}`, { params })
    return res.data
  },

  async addCounsellor(body: { globalUserId: string }) {
    return axios.post(paymentxCounsellors, body)
  },

  async deleteCounsellor(body: { globalUserId: string }) {
    return axios.delete(`${paymentxCounsellors}/${body.globalUserId}`)
  },
}
