import { axios, axiosErrorToast } from '@campxdev/shared'
import { toast } from 'react-toastify'
import { paymentMethods } from './paymentMethods'
import { paytmConfig, razorPayConfig } from 'utils/paymentConfig'
import { formStore } from './AdmissionForm'

interface CreateOrderDto {
  amount: number
  paymentMethod: string
  notes: any
  razorpayNotes: any
}

export async function fetchCourseData() {
  let courseData = await axios.get('/square/courses')
  let branchData = await axios.get('/square/branches')
  let quotaData = await axios.get('/square/quotas')
  return {
    course: courseData.data,
    branch: branchData.data,
    quota: quotaData.data,
  }
}

export async function updateLeadDetails({ body }) {
  return axios.put(`/square/leads/update-details`, body)
}

export type RazorPayOptions = {
  amount: number
  currency: string
  orderId: string
  description: string
  email: string
  mobile: string
  fullName: string
  method: string
  cardType: string
  clientLogo: string | null
  key: string
}

export async function acknowledgePayment({
  razorPayResponse,
  type,
  paymentMethod,
  leadDetails,
  id,
}: {
  razorPayResponse: any
  type: any
  paymentMethod: any
  leadDetails: any
  id?: any
}) {
  try {
    const paytmParams = {
      type: type,
      orderId: razorPayResponse?.ORDERID,
    }

    const razorPayParams = {
      type: type,
      orderId: razorPayResponse?.razorpay_order_id,
      paymentId: razorPayResponse?.razorpay_payment_id,
      signature: razorPayResponse?.razorpay_signature,
    }

    const { data } = await axios.post(
      '/square/open-admissions/submit-application-form',
      leadDetails,
      {
        params: type == 'PAYTM' ? paytmParams : razorPayParams,
      },
    )
    window.location.href = 'public-forms/thank-you'
    toast.success('Application form submitted successfully')
    return data
  } catch (error) {
    axiosErrorToast(error ?? 'Error while submitting Application Form')
  }
}

export const initiateOrder = async (payload: any) => {
  try {
    const res = await axios.post(
      `/payments/payment-clients/initiate-payment`,
      payload,
    )
    return res.data
  } catch (error) {
    axiosErrorToast(error)
  }
}

export const initiateRazorPayOrder = (
  payload: any,
  onSuccess: (response) => void,
) => {
  razorPayConfig(payload, onSuccess)
}

export async function intiatePaymentOrder(
  payload: any,
  onSuccess: (response) => void,
) {
  paytmConfig(payload, onSuccess)
}

export async function createRazorPayOrder({ createOrderBody, leadDetails }) {
  const assets = await axios.get('/square/assets')
  const razorpayRes = await axios.post(
    `/payments/razorpay/create-order`,
    createOrderBody,
  )

  const order = razorpayRes.data?.details?.order?.order
  let razorpay
  try {
    const razorPayOptions = {
      key: razorpayRes.data?.razorpayKey,
      amount: order?.amount,
      currency: order?.currency,
      order_id: order?.id,
      description: 'Admission Form Fee',
      image: assets.data?.logo,
      email: leadDetails?.email || '',
      contact: leadDetails?.mobile || '',
      type:
        paymentMethods?.find(
          (item) => item.value == createOrderBody.paymentMethod,
        ).cardType ?? undefined,
      prefill: {
        name: leadDetails?.fullName || '',
        email: leadDetails?.email || '',
        contact: leadDetails?.mobile || '',
      },
      method: {
        netbanking: createOrderBody.paymentMethod === 'netbanking',
        card:
          createOrderBody.paymentMethod === 'debit_card' ||
          createOrderBody.paymentMethod === 'credit_card',
        wallet: createOrderBody.paymentMethod === 'wallet',
        upi: createOrderBody.paymentMethod === 'upi',
      },
      handler: async function (response) {
        if (response)
          acknowledgePayment({
            razorPayResponse: response,
            type: 'RAZORPAY',
            paymentMethod: createOrderBody.paymentMethod,
            leadDetails: leadDetails,
          })
      },
    }
    razorpay = new (window as any).Razorpay(razorPayOptions)
    razorpay.open()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
  razorpay?.on('payment.failed', function (response) {
    toast.error('Failed to initiate Payment')
    // Modal.error({
    //   content: response?.error?.description,
    // })
  })
}
