import { axios } from '@campxdev/shared'

const CONCESSIONS = '/payments/fee-concessions'
export const concessions = {
  async getRequests(params: {
    admissionId?: number
    year?: number
    status?: string
    feeType?: string
  }) {
    const res = await axios.get(CONCESSIONS, {
      params,
    })
    return res.data
  },
  async postConcession(body: {
    admissionId: number
    year: number
    feeType: string
    concessionType: string
    amount: number
    remarks: string
  }) {
    const res = await axios.post(CONCESSIONS, body)
    return res.data
  },
  async updateConcessionStaus(body: {
    feeConcessionId: number
    status: 'APPROVED' | 'REJECTED'
    remarks: string
  }) {
    const res = await axios.post(CONCESSIONS + '/update-status', body)
    return res.data
  },
}
