import {
  ActionButton,
  FormSingleSelect,
  Spinner,
  axios,
  axiosErrorToast,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { services } from 'services'
import * as yup from 'yup'

const schema = yup.object().shape({
  institutionId: yup.string().required('Institution is required'),
  courseId: yup.string().required('Degree is required'),
  programId: yup.string().required('Program is required'),
})

const urls = {
  admission: '/paymentx/admissions/move-to-institution',
  leads: '/paymentx/leads/bulk-update-lead-institution',
}

function MovetoInstitution({ clear, ids, close, type }) {
  const queryClient = useQueryClient()

  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(schema),
  })

  const { data, isLoading } = useQuery('institutions', () =>
    axios.get('/square/institutions').then((res) => res.data.institutions),
  )

  const {
    isLoading: domainLoading,
    data: domainData,
    error,
  } = useQuery(
    ['courses', watch().institutionId],
    () =>
      services.admissions.fetchDomainData({
        id: watch().institutionId,
      }),
    { enabled: !!watch().institutionId },
  )

  const programData =
    watch().courseId &&
    domainData?.programs?.filter((item) => item.courseId == watch().courseId)

  const quotaData =
    watch().courseId &&
    domainData?.quotas?.filter((item) => item.courseId == watch().courseId)

  const onSubmit = async (formData) => {
    try {
      type == 'leads'
        ? await axios.post(urls[type], {
            ids: ids?.map((i) => i),
            ...formData,
          })
        : await axios.put(urls[type], {
            ids: ids?.map((i) => i),
            ...formData,
          })
      queryClient.invalidateQueries(
        type == 'admission' ? 'notification-admissions' : 'leads',
      )
      toast.success('Updated Successfully')
      close()
      clear()
    } catch (err) {
      axiosErrorToast(err)
    }
  }
  if (isLoading) return <Spinner />

  return (
    <>
      <Box sx={{ height: 'auto', overflowY: 'auto', padding: '16px 10px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={2.5}>
            <FormSingleSelect
              required
              name="institutionId"
              label={'Select Institution'}
              options={data?.map((item) => ({
                label: item.name,
                value: item._id,
              }))}
              control={control}
            />

            {domainLoading ? (
              <Spinner />
            ) : (
              <>
                <FormSingleSelect
                  label={'Degree'}
                  name={'courseId'}
                  control={control}
                  options={domainData?.courses?.map((item) => {
                    return { label: item.courseName, value: item.id }
                  })}
                  required
                />
                <FormSingleSelect
                  label={'Program'}
                  name={'programId'}
                  control={control}
                  required
                  options={programData?.map((item) => {
                    return { label: item.branchName, value: item.id }
                  })}
                />
                <FormSingleSelect
                  label={'Quotas'}
                  name={'quotaId'}
                  control={control}
                  options={quotaData?.map((item) => {
                    return { label: item.name, value: item.id }
                  })}
                />
                <FormSingleSelect
                  label={'Notification'}
                  name={'admissionNotificationId'}
                  control={control}
                  options={
                    watch().institutionId
                      ? domainData?.notifications?.map((item) => {
                          return {
                            label: item?.name,
                            value: item?.id,
                          }
                        })
                      : []
                  }
                />
              </>
            )}

            <Stack direction="row" gap={2} mt={2}>
              <ActionButton
                variant="outlined"
                fullWidth
                onClick={() => close()}
              >
                Cancel
              </ActionButton>
              <ActionButton type="submit" fullWidth>
                Submit
              </ActionButton>
            </Stack>
          </Stack>
        </form>
      </Box>
    </>
  )
}

export default MovetoInstitution
