import { PageNotFound } from '@campxdev/shared'
import AdmissionForm from 'pages/PublicForms/AdmissionForm'
import OpenAdmissionForm from 'pages/PublicForms/OpenAdmissionForm'
import Thankyou from 'pages/PublicForms/Thankyou'
import PhdHallTickets from 'pages/PublicForms/PhdHallTickets'
const tenantKey = window.location.pathname.split('/')[1]

const pageMap = {
  setwin: <AdmissionForm />,
  campx_dev: <AdmissionForm />,
}

const page = tenantKey ? (
  pageMap[tenantKey] ?? <PageNotFound />
) : (
  <PageNotFound />
)

export const publicFormsRoutes = [
  {
    element: page,
    path: 'admission-form/:collegeName/:id',
  },

  {
    element: <Thankyou />,
    path: 'admission-form/:admissionId/thank-you',
  },

  {
    element: <OpenAdmissionForm />,
    path: 'open-admission-form',
  },
  {
    element: <PhdHallTickets />,
    path: 'phd-hallticket',
  },
]
