import {
  PageContent,
  PageHeader,
  SearchBar,
  SingleSelect,
  Table,
} from '@campxdev/shared'
import { Box, Typography } from '@mui/material'
import { StyledStatus } from 'pages/LeadAdmissions/ViewAdmissions'
import { statusData } from 'pages/LeadAdmissions/services'
import { useQuery } from 'react-query'
import { useImmer } from 'use-immer'
import { batchOptions, fetchData } from './services'
const defaultObject = {
  filters: {
    limit: 10,
    skip: 0,
    search: null,
    batch: null,
  },
  data: [],
}
const Columns = [
  {
    title: 'Admission Id',
    dataIndex: 'uniqueId',
    key: 'id',
  },
  {
    title: 'Full Name',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Batch',
    dataIndex: 'batch',
    key: 'batch',
  },
  {
    title: 'Mobile',
    dataIndex: 'mobile',
    key: 'mobile',
  },
  {
    title: 'Degree',
    dataIndex: 'course.courseName',
    key: 'course',
  },
  {
    title: 'Program',
    dataIndex: 'program.branchCode',
    key: 'program',
  },
  {
    title: 'Quota',
    dataIndex: 'quota.name',
    key: 'quota',
    render: (_, row) => {
      return <Typography>{row?.quota?.name || '-'}</Typography>
    },
  },

  {
    title: 'Parent Name',
    dataIndex: 'guardianDetails.fatherName',
    key: 'parentName',
  },
  {
    title: 'Parent Mobile',
    dataIndex: 'guardianDetails.fatherMobile',
    key: 'parentMobile',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (cellData) => (
      <StyledStatus statusType={cellData}>{statusData[cellData]}</StyledStatus>
    ),
  },
]

function DeletedAdmissionReport() {
  const [state, setState] = useImmer(defaultObject)
  const { data, isLoading } = useQuery(
    [
      'deleted-admissions',
      ...Object.keys(state.filters)?.map((key) => state.filters[key]),
    ],
    () => fetchData(state.filters),
    {
      onSuccess: (res) => {
        setState((s) => {
          s.data = res.admissions
        })
      },
    },
  )

  const handlePagination = (value: number) => {
    setState((s) => {
      s.filters.skip = value * s.filters.limit - s.filters.limit
    })
  }
  const handlePageLimit = (value: number) => {
    setState((s) => {
      s.filters.limit = value
      s.filters.skip = 0
    })
  }

  return (
    <>
      <PageHeader title="Deleted Admissions Report" />
      <PageContent>
        <Box sx={{ display: 'flex', alignItems: 'end', marginY: '20px' }}>
          <Box sx={{ width: '150px' }}>
            <SingleSelect
              name="batch"
              label={'Select Batch'}
              value={state.filters.batch}
              options={batchOptions?.map((item) => ({
                label: item,
                value: item,
              }))}
              onChange={(e) => {
                setState((s) => {
                  s.filters.batch = e.target.value
                })
              }}
            />
          </Box>
          <Box mx={2}>
            <SearchBar
              onSearch={(e) => {
                setState((s) => {
                  s.filters.search = e
                })
              }}
              label="Search with Name"
            />
          </Box>
        </Box>

        <Table
          columns={Columns}
          dataSource={state.data ?? []}
          loading={isLoading}
          pagination={{
            page: state.filters.skip / state.filters.limit,
            limit: state.filters.limit,
            onChange: handlePagination,
            totalCount: data?.count ?? 0,
            onChangeLimit: handlePageLimit,
          }}
        />
      </PageContent>
    </>
  )
}

export default DeletedAdmissionReport
