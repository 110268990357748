import { ActionButton, FormSingleSelect } from '@campxdev/shared'
import { Stack } from '@mui/material'
import { fetchLeadData } from 'pages/Leads/LeadFilters'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { batchOptions } from '../DeletedAdmissionReport/services'
import { dynamicStatusValues } from './service'

function LeadAdmissionFilter({ setFilters }) {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      course: '',
      batch: batchOptions[0],
    },
  })
  const { isLoading, data, error } = useQuery(['courses'], () =>
    fetchLeadData(),
  )
  const programData = watch('course')
    ? data?.branches?.filter((item) => {
        return item?.courseId === watch('course')
      })
    : []

  const onSubmit = (formdata) => {
    setFilters((s) => {
      s.status = formdata?.status
      s.courseId = formdata?.course != 'all' ? formdata?.course : undefined
      s.programId = formdata?.program != 'all' ? formdata?.program : undefined
      s.batch = formdata?.batch
    })
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          gap={2.5}
          direction={'row'}
          sx={{ alignItems: 'flex-end', maxWidth: '900px', my: '20px' }}
        >
          <FormSingleSelect
            name="batch"
            label={'Select Batch'}
            control={control}
            options={batchOptions?.map((item) => ({
              label: item,
              value: item,
            }))}
          />

          <FormSingleSelect
            name="course"
            label={'Select Degree'}
            control={control}
            options={
              data?.courses?.length && [
                { label: 'All', value: 'all' },
                ...data?.courses?.map((item) => ({
                  label: item?.courseName,
                  value: item?.id,
                })),
              ]
            }
          />

          <FormSingleSelect
            name={'program'}
            label={'Select Program'}
            control={control}
            options={
              watch('course') && programData?.length
                ? [
                    { label: 'All', value: 'all' },
                    ...programData?.map((item) => ({
                      label: item?.branchName,
                      value: item?.id,
                    })),
                  ]
                : []
            }
          />
          <FormSingleSelect
            name="status"
            label={'Select Status'}
            control={control}
            options={dynamicStatusValues?.map((item) => ({
              label: item?.title,
              value: item?.value,
            }))}
          />

          <ActionButton type="submit">Submit</ActionButton>
        </Stack>
      </form>
    </div>
  )
}

export default LeadAdmissionFilter
