import {
  ActionButton,
  DialogButton,
  FormSingleSelect,
  PageContent,
  PageHeader,
  Spinner,
  Table,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Stack, Typography, styled } from '@mui/material'
import { batchOptions } from 'constants/UIConstants'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useImmer } from 'use-immer'
import * as yup from 'yup'
import StudentReportDialog from './StudentReportDialog'
import {
  fetchData,
  getCounsellorReport,
  getLeadsByStatus,
  handleExport,
} from './service'
const schema = yup.object().shape({
  batch: yup.string().required('Batch is required'),
})

export const StyledFiltersContainer = styled(Box)(({ theme }) => ({
  border: theme?.borders?.grayLight,
  borderRadius: '10px',
  maxWidth: '350px',
  flexShrink: 0,
}))

export const StyledFiltersHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme?.palette?.secondary?.light,
  padding: '20px',
  borderRadius: '10px 10px 0 0',
}))

const defaultState = {
  limit: 10,
  offset: 0,
  count: 0,
  batch: null,
  course: null,
  data: [],
  status: null,
  counsellor: null,
  enabled: false,
}

export default function FacultyReport() {
  const [filters, setFilters] = useImmer(defaultState)

  const { data: reportData, isLoading: reportLoading } = useQuery(
    'fetch-details',
    fetchData,
  )

  const [counsellorsData, setCounsellorsData] = useState(null)

  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      courseId: '',
      batch: '',
      quotaId: null,
    },
  })

  const courseId = watch('courseId')

  const leadParams = {
    courseId: filters?.course ?? courseId,
    batch: filters?.batch,
    counsellorId: filters?.counsellor,
    sortOrder: 'DESC',
    offset: filters?.offset,
    limit: filters?.limit,
    status: 'Moved to admissions',
  }
  const admissionParams = {
    counselledById: filters?.counsellor,
    status: filters.status,
    courseId: filters?.course ?? courseId,
    batch: filters?.batch,
    offset: filters?.offset,
    limit: filters?.limit,
  }

  const { data: leadsData, isLoading: leadsLoading } = useQuery(
    ['individual-lead-report', filters.count, filters.offset, filters.limit],
    () =>
      getLeadsByStatus({
        params:
          filters.status != 'Moved to admissions'
            ? admissionParams
            : leadParams,
        filters: filters,
      }).then((res) => {
        setFilters((s) => {
          s.data =
            filters.status == 'Moved to admissions'
              ? res.data.result
              : res.data.admissions
          s.count = res.data.count
          s.enabled = false
        })
      }),
    {
      enabled: filters.enabled,
    },
  )

  function handleGetLeadReport({ status, row, open }) {
    setFilters((s) => {
      s.status = status
      s.limit = 10
      s.offset = 0
      s.data = []
      s.counsellor = row?.counsellorId
      s.count = 0
      s.enabled = true
    })
    open()
  }

  const columns = [
    {
      title: 'Counsellor',
      dataIndex: 'counsellorName',
      key: 'counsellorName',
      render: (cellData) => {
        return cellData?.toUpperCase()
      },
    },
    {
      title: 'ASSIGNED',
      dataIndex: 'ASSIGNED',
      key: 'ASSIGNED',
      render: (_, row) => {
        return (
          <DialogButton
            dialogProps={{
              maxWidth: 'md',
            }}
            anchor={({ open }) => (
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  handleGetLeadReport({
                    status: 'Moved to admissions',
                    row: row,
                    open: open,
                  })
                }}
              >
                {row?.ASSIGNED ?? 0}
              </Box>
            )}
            title={'Assigned'}
            content={({ close }) => (
              <StudentReportDialog
                isLoading={leadsLoading}
                close={close}
                status={'Moved to admissions'}
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
        )
      },
    },
    {
      title: 'IN PROGRESS',
      dataIndex: 'IN_PROGRESS',
      key: 'IN_PROGRESS',
      render: (_, row) => {
        return (
          <DialogButton
            dialogProps={{
              maxWidth: 'md',
            }}
            anchor={({ open }) => (
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  handleGetLeadReport({
                    status: 'IN_PROGRESS',
                    row: row,
                    open: open,
                  })
                }}
              >
                {row?.IN_PROGRESS ?? 0}
              </Box>
            )}
            title={'In Progress'}
            content={({ close }) => (
              <StudentReportDialog
                isLoading={leadsLoading}
                close={close}
                status={'IN_PROGRESS'}
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
        )
      },
    },
    {
      title: 'CONFIRMED',
      dataIndex: 'CONFIRMED',
      key: 'CONFIRMED',
      render: (_, row) => {
        return (
          <DialogButton
            dialogProps={{
              maxWidth: 'md',
            }}
            anchor={({ open }) => (
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  handleGetLeadReport({
                    status: 'CONFIRMED',
                    row: row,
                    open: open,
                  })
                }}
              >
                {row?.CONFIRMED ?? 0}
              </Box>
            )}
            title={'Confirmed'}
            content={({ close }) => (
              <StudentReportDialog
                isLoading={leadsLoading}
                close={close}
                status={'CONFIRMED'}
                filters={filters}
                setFilters={setFilters}
              />
            )}
          />
        )
      },
    },
    {
      title: 'TOTAL',
      dataIndex: 'TOTAL',
      key: 'TOTAL',
      render: (_, row) => {
        return (
          <>
            {parseInt(row['ASSIGNED'] ?? 0) +
              parseInt(row['IN_PROGRESS'] ?? 0) +
              parseInt(row['CONFIRMED'] ?? 0)}
          </>
        )
      },
    },
  ]

  const onSubmit = async (formData) => {
    setFilters((s) => {
      s.batch = formData.batch
      s.course = formData.course
    })

    getCounsellorReport(formData).then((res) => {
      setCounsellorsData(res)
    })
  }

  if (reportLoading) return <Spinner />
  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box sx={{ width: '350px' }}>
        <PageHeader title="Report" />
        <PageContent>
          <StyledFiltersContainer>
            <StyledFiltersHeader>
              <Typography variant="h6">Apply Filters</Typography>
            </StyledFiltersHeader>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap={2.5} sx={{ padding: '20px' }}>
                <FormSingleSelect
                  name="batch"
                  label={'Batches'}
                  control={control}
                  required
                  options={batchOptions?.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />

                <FormSingleSelect
                  name={'courseId'}
                  label={'Degree'}
                  control={control}
                  options={reportData?.courses?.map((item) => ({
                    label: item?.courseName,
                    value: item?.id,
                  }))}
                />
                <FormSingleSelect
                  name={'quotaId'}
                  label={'Quota'}
                  control={control}
                  firstItemEmpty
                  options={reportData?.quotas
                    ?.filter((item) => item?.courseId == courseId)
                    ?.map((item) => ({
                      label: item?.name,
                      value: item?.id,
                    }))}
                />

                <ActionButton type="submit" fullWidth>
                  Submit
                </ActionButton>
                {counsellorsData && (
                  <ActionButton
                    variant="outlined"
                    fullWidth
                    onClick={() => handleExport(counsellorsData)}
                  >
                    Export
                  </ActionButton>
                )}
              </Stack>
            </form>
          </StyledFiltersContainer>
        </PageContent>
      </Box>

      <Box sx={{ marginTop: '75px', marginRight: '20px' }}>
        <Table
          key={'admission'}
          dataSource={counsellorsData ? counsellorsData : []}
          rowKey={'id'}
          columns={columns}
        />
      </Box>
    </Box>
  )
}
