import { axios } from '@campxdev/shared'

export const studentServiceRequests = {
  async getStudentServiceRequests(params: any) {
    let res = await axios.get('/paymentx/student-service-requests', { params })
    return res.data
  },

  async sendProvisionalAdmissionLetterEmail(id: string) {
    let res = await axios.get(
      `/paymentx/student-service-requests/${id}/provisional-admission-letter-email`,
    )
    return res.data
  },

  async updateStudentServiceRequestStatus(body: {
    id: any
    status: any
    remark: any
    custodianCertificates?: string[]
  }) {
    let res = await axios.post(
      `/paymentx/student-service-requests/${body.id}/status`,
      {
        status: body.status,
        remark: body.remark,
        custodianCertificates: body.custodianCertificates,
      },
    )
    return res.data
  },
}
