import {
  ActionButton,
  FormRadioGroup,
  FormSemseterSelector,
  FormSingleSelect,
  FormTextField,
  Spinner,
  axios,
  axiosErrorToast,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Typography } from '@mui/material'
import { AxiosRequestConfig } from 'axios'
import { batchOptions } from 'constants/UIConstants'
import { useForm } from 'react-hook-form'
import { useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { services } from 'services'
import * as yup from 'yup'

async function fetchCourseData() {
  let courseData = await services.courses.fetchDomainCourses()
  let branchData = await services.programs.fetchPrograms()
  let quotaData = await services.quotas.fetchQuotas()
  return {
    course: courseData,
    branch: branchData,
    quota: quotaData,
  }
}
const mobileRegx: any = new RegExp(/^[6-9]\d{9}$/)

const aadharRegex = new RegExp(
  '^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}s[0-9]{4}s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)',
)

const schema = yup.object().shape(
  {
    firstName: yup.string().required('Name is required'),
    mobile: yup
      .string()
      .matches(mobileRegx, 'Enter a valid 10 digit mobile number')
      .nullable()
      .required('Mobile number is required'),
    email: yup
      .string()
      .email('Enter a valid email')
      .required('E-Mail ID is required'),
    gender: yup.string().required('Gender is required'),
    courseId: yup.string().required('Degree is required'),
    programId: yup.string().required('Program is required'),
    semNo: yup.string().required('Semester No is  required'),
    // quotaId: yup.string().required('Quota is required'),
    batch: yup.string().required('Batch is required'),
    fatherName: yup.string().required("Father's name is required"),
    fatherMobile: yup
      .string()
      .matches(mobileRegx, 'Enter a valid 10 digit mobile number')
      .nullable()
      .required("Father's mobile number is required"),

    aadhaarNo: yup
      .string()
      .when('nicKey', function (v) {
        if (v == null || v == undefined)
          return this.required(' Aadhaar Number  is required')
      })
      .matches(aadharRegex, 'Enter Valid Aadhaar Number')
      .optional()
      .nullable(),

    nicKey: yup
      .string()
      .when('aadhaarNo', function (v) {
        if (v == null || v == undefined || v == '')
          return this.required('NIC Number is required')
      })
      .optional()
      .nullable(),
    interHallTicketNumber: yup
      .string()
      .required('Inter Hall Ticket number is required'),
  },
  [['nicKey', 'aadhaarNo']],
)

function AdmissionForm({
  data,
  hideDialog,
  notificationId,
}: {
  data?: any
  hideDialog: () => void
  notificationId?: string
}) {
  const queryClient = useQueryClient()
  const { control, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      program: data?.program?.id,
      courseName: data?.course?.id,
      quota: data?.quota?.id,
    },
  })

  const {
    isLoading,
    data: courseData,
    error,
  } = useQuery(['courses'], () => fetchCourseData())

  const courseId = watch().courseId
  const programData = courseId
    ? courseData?.branch?.filter((item) => {
        return item?.courseId === courseId
      })
    : []
  const quotaData = courseId
    ? courseData?.quota?.filter((item) => {
        return item?.courseId === courseId
      })
    : []

  const onSubmit = async (formData) => {
    const phases = {
      phase1: formData.phase == 'phase1' ? true : false,
      phase2: formData.phase == 'phase2' ? true : false,
      phase3: formData.phase == 'phase3' ? true : false,
    }

    delete formData.phase
    formData.fullName =
      formData.firstName + formData?.lastName ? ` ${formData.lastName}` : ''
    formData.admissionNotificationId =
      notificationId != 'all-admissions' ? notificationId : undefined
    const personalDetails = {
      aadhaarNo: formData.aadhaarNo,
      nicKey: formData.nicKey,
    }
    const guardianDetails = {
      fatherName: formData.fatherName,
      fatherMobile: formData.fatherMobile,
    }

    // delete formData.fatherMobile
    // delete formData.fatherName
    // delete formData.nicKey
    // delete formData.aadhaarNo

    const data = {
      ...formData,
      personalDetails: personalDetails,
      guardianDetails: guardianDetails,
      ...phases,
      counselledByMe: true,
      admissionType: 'Walk In',
    }

    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/paymentx/admissions/short-admission',
      data: data,
    }

    try {
      await axios(config)
      queryClient.invalidateQueries('admission')
      queryClient.invalidateQueries('notification-admissions')
      toast.success('Admission created successfully')
      hideDialog()
    } catch (error) {
      toast.error(
        error?.response?.data?.message ?? 'Unable to create admission',
      )
    }
  }
  const onError = (e) => {
    axiosErrorToast(e)
  }

  const handleChange = (e, field) => {
    setValue(field, e.target.value?.toUpperCase())
  }

  if (isLoading) {
    return <Spinner />
  }
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Stack gap={2.5}>
        <FormTextField
          label={'First Name'}
          name={'firstName'}
          control={control}
          onChange={(e) => {
            handleChange(e, 'firstName')
          }}
          required
        />
        <FormTextField
          label={'Last Name'}
          name={'lastName'}
          control={control}
          onChange={(e) => {
            handleChange(e, 'lastName')
          }}
        />
        <FormTextField
          label={'Mobile Number'}
          name={'mobile'}
          control={control}
          required
        />
        <FormTextField
          label={'E-mail ID'}
          name={'email'}
          control={control}
          required
        />
        <Stack gap={2} direction={'row'} sx={{ alignItems: 'center' }}>
          <FormTextField
            label={'Aadhaar Number'}
            name={'aadhaarNo'}
            control={control}
            required
          />
          <Typography variant="subtitle2" textAlign="center">
            Or
          </Typography>
          <FormTextField
            label={'NIC Number'}
            name={'nicKey'}
            control={control}
          />
        </Stack>

        <FormRadioGroup
          row
          required
          label={'Gender'}
          name={'gender'}
          control={control}
          options={[
            { label: 'Male', value: 'male' },
            { label: 'Female', value: 'female' },
          ]}
        />
        <FormSingleSelect
          label={'Degree'}
          name={'courseId'}
          control={control}
          options={courseData?.course?.map((item) => {
            return { label: item.courseName, value: item.id }
          })}
          required
        />
        <FormSingleSelect
          label={'Program'}
          name={'programId'}
          control={control}
          options={
            courseId
              ? programData?.map((item) => {
                  return { label: item?.branchName, value: item?.id }
                })
              : []
          }
          required
        />
        <FormSemseterSelector
          control={control}
          name="semNo"
          label={'Semester'}
          required
          allowAll={false}
          filters={{
            courseId: courseData?.course?.find(
              (course) => course.id === watch('courseId'),
            )?.uniqueId,
          }}
        />
        <FormSingleSelect
          label={'Batch'}
          name={'batch'}
          control={control}
          options={batchOptions.map((item, index) => ({
            label: item,
            value: item,
          }))}
          required
        />
        <FormTextField
          label={"Father's Name"}
          name={'fatherName'}
          control={control}
          required
        />
        <FormTextField
          label={"Father's Mobile"}
          name={'fatherMobile'}
          control={control}
          required
        />
        <FormTextField
          label={'10+2 Ht.No'}
          name={'interHallTicketNumber'}
          control={control}
          required
        />
        <FormRadioGroup
          row
          label={'Phase'}
          name={'phase'}
          control={control}
          options={[
            { label: 'Phase1', value: 'phase1' },
            { label: 'Phase2', value: 'phase2' },
            { label: 'Phase3', value: 'phase3' },
          ]}
        />
        <FormTextField
          minRows={5}
          multiline
          label={'Comments'}
          name={'remarks'}
          control={control}
        />

        <Stack direction="row" gap={2} mt={2}>
          <ActionButton type="submit" fullWidth>
            Create Admission
          </ActionButton>
          <ActionButton
            variant="outlined"
            fullWidth
            onClick={() => hideDialog()}
          >
            Cancel
          </ActionButton>
        </Stack>
      </Stack>
    </form>
  )
}

export default AdmissionForm
