import { axios } from '@campxdev/shared'

export const courses = {
  async fetchCourses() {
    const res = await axios.get('/square/courses')
    return res.data.courses
  },

  async fetchDomainCourses() {
    const res = await axios.get('/paymentx/domain/courses')
    return res.data
  },
}

export const quotas = {
  async fetchQuotas() {
    const res = await axios.get('/square/quotas')
    return res.data
  },

  async fetchDomainQuotas() {
    const res = await axios.get('/paymentx/quotas')
    return res.data.quotas
  },
}
