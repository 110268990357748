import { Spinner } from '@campxdev/shared'
import { urlTenantKey } from '@campxdev/shared/src/contexts/Providers'
import { InstitutionsStore } from '@campxdev/shared/src/shared-state/InstitutionsStore'
import { Box, Stack, Typography } from '@mui/material'
import moment from 'moment'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import AnuragBonafiedLogo from '../../../assets/images/AnuragBonafiedLogo.png'
import STPECHeader from '../../../assets/images/stpecHeader.png'
import { getStudentCertificate, intToRomanSmall } from './services'
import {
  BoxCenter,
  FixedTextUnderLine,
  StyledAddress,
  StyledBox,
  StyledHeader,
  StyledHeaderContainer,
  StyledPara,
  StyledSignature,
  StyledText,
  StyledTypography,
} from './styles'
const Header = () => {
  const assets = InstitutionsStore.useState((s) => s)
  return (
    <>
      {urlTenantKey !== 'aupulse' ? (
        <StyledHeaderContainer>
          <img
            src={assets?.current?.images?.url}
            alt="CollegeLogo"
            style={{
              maxHeight: '180px',
              objectFit: 'contain',
              maxWidth: '300px',
            }}
          />
          <Box>
            <Typography
              sx={{
                fontSize: '20px',
                fontFamily: 'serif',
                textAlign: 'center',
                '@media print': {
                  fontSize: '15px',
                },
              }}
            >
              {assets?.current?.name.toUpperCase()}
            </Typography>
            <Typography
              sx={{
                fontSize: '18px',
                fontFamily: 'serif',
                textAlign: 'center',
                '@media print': {
                  fontSize: '14px',
                },
              }}
            >
              {`(${assets?.current?.recognitionDetails.toUpperCase()})`}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Lora, Regular',
                opacity: '0.85',
                fontSize: '14px',
                textAlign: 'center',
              }}
            >
              {assets?.current?.address}
            </Typography>
            <Typography
              sx={{
                opacity: '0.9',
                fontSize: '14px',
                textAlign: 'center',
              }}
            >
              {assets?.current?.email &&
                `Email :${assets?.current?.email}     ${` ,`}  
              `}
              {assets?.current?.phone && `Phone:${assets?.current?.phone}`}
            </Typography>
          </Box>
        </StyledHeaderContainer>
      ) : (
        <StyledHeader>
          <Box>
            <img
              src={assets?.current?.images?.url}
              alt="CollegeLogo"
              style={{
                maxHeight: '180px',
                objectFit: 'contain',
                maxWidth: '250px',
                marginBottom: '10px',
              }}
            />
            <Typography
              sx={{
                fontSize: '12px',
                maxWidth: '300px',
                fontStyle: 'italic',
                '@media print': {
                  fontSize: '14px',
                },
              }}
            >
              {`${assets?.current?.recognitionDetails}`}
            </Typography>
          </Box>

          <Box>
            <img
              src={AnuragBonafiedLogo}
              alt="BonafiedLogo"
              style={{
                maxWidth: '400px',
                marginBottom: '10px',
                position: 'relative',
                left: '20px',
              }}
            />
            <StyledAddress>{assets?.current?.address}</StyledAddress>
            <StyledAddress>
              Contact: {assets?.current?.phone} | {assets?.current?.email}
            </StyledAddress>
          </Box>
        </StyledHeader>
      )}
    </>
  )
}

const Body = ({ data }) => {
  const assets = InstitutionsStore.useState((s) => s)

  return (
    <>
      {urlTenantKey === 'aupulse' ? (
        <>
          <StyledBox
            sx={{ padding: '0 20px', position: 'relative', top: '-30px' }}
          >
            <Typography sx={{ fontSize: '18px' }}>
              No.: {data?.result?.refId}
            </Typography>
            {data?.studentDetails?.photo && (
              <img
                src={data?.studentDetails?.photo}
                alt="Student Image"
                style={{
                  position: 'relative',
                  top: '7px',
                  height: '100px',
                  objectFit: 'contain',
                }}
              />
            )}
            <Typography sx={{ fontSize: '18px' }}>
              Date: {moment(data?.result?.createdAt).format('DD-MM-YYYY')}
            </Typography>
          </StyledBox>
          <Box sx={{ padding: '0 20px' }}>
            <StyledTypography variant="h2">
              This is to certify that Mr./Ms.{' '}
              <FixedTextUnderLine>
                {data?.studentDetails?.fullName}
              </FixedTextUnderLine>{' '}
              Son/Daughter of Sri.{' '}
              <FixedTextUnderLine>
                {data?.studentDetails?.guardianDetails?.fatherName}
              </FixedTextUnderLine>{' '}
              Roll No.{' '}
              <FixedTextUnderLine>
                {data?.studentDetails?.rollNo ?? '--'}
              </FixedTextUnderLine>{' '}
              is/was a student of this university, studying{' '}
              <FixedTextUnderLine>
                {data?.studentDetails?.course?.courseName}{' '}
                {data?.studentDetails?.program?.branchCode}{' '}
                {intToRomanSmall(data?.studentDetails?.year)} Year
              </FixedTextUnderLine>{' '}
              . During the academic years{' '}
              <FixedTextUnderLine>
                {data?.result?.academicBatch || data?.studentDetails?.batch}
              </FixedTextUnderLine>{' '}
              his/her conduct and character is/was satisfactory/good.
            </StyledTypography>
          </Box>
          <StyledBox
            sx={{
              marginTop: '10px',
              padding: '40px 20px',
            }}
          >
            <StyledSignature color={'#00008B'}>
              Administrative Officer
            </StyledSignature>
            <StyledSignature color={'#A52A2A'}>Director</StyledSignature>
          </StyledBox>
          <Typography color={'grey'} align="center">
            Note: This is System generated Bonafide Certificate.
          </Typography>
        </>
      ) : (
        <>
          <StyledBox sx={{ padding: '20px' }}>
            <Stack gap={'10px'}>
              <Typography
                sx={{ fontFamily: 'Lora, Regular', fontSize: '18px' }}
              >
                REF:
                {`  ${assets?.current?.code?.toUpperCase()}/ Acad./ ${
                  data?.result?.academicBatch || data?.studentDetails?.batch
                } / ${data?.result?.refId}    `}
              </Typography>
              <Typography
                variant="h2"
                sx={{ fontFamily: 'Lora, SemiBold', fontSize: '18px' }}
              >
                Admission No:
                {data?.result?.customAdmissionId ||
                  data?.studentDetails?.studentUniqueId}
              </Typography>
            </Stack>
            <Typography sx={{ fontFamily: 'Lora, Medium', fontSize: '18px' }}>
              Date:{moment(data?.result?.createdAt).format('DD-MM-YYYY')}
            </Typography>
          </StyledBox>
          <BoxCenter>
            <StyledText
              sx={{
                marginY: urlTenantKey == 'stpec' ? '2px' : '5px',
                fontSize: urlTenantKey == 'stpec' ? '25px' : '35px',
              }}
            >
              Bonafide Certificate
            </StyledText>
          </BoxCenter>
          <StyledPara variant="body1">
            This is to certify that{' '}
            {data?.studentDetails?.gender == 'male' ? 'Mr' : 'Ms'}{' '}
            {data?.studentDetails?.fullName}
            {urlTenantKey == 'stpec'
              ? `  with Roll No : ${data?.studentDetails?.rollNo ?? '--'}`
              : null}{' '}
            {data?.studentDetails?.gender == 'male' ? ' S/O' : 'D/O'}{' '}
            {data?.studentDetails?.guardianDetails?.fatherName} is a bonafide
            student of our institution studying{' '}
            {urlTenantKey == 'stpec'
              ? `${intToRomanSmall(data?.studentDetails?.year)} Year  `
              : null}
            {data?.studentDetails?.course?.courseName},{' '}
            {data?.studentDetails?.program?.branchCode}{' '}
            {urlTenantKey != 'stpec'
              ? `His Roll No. ${data?.studentDetails?.rollNo ?? '--'}`
              : null}{' '}
            , during the academic year
            {'  '}
            {data?.result?.academicBatch || data?.studentDetails?.batch}.{' '}
            {data?.studentDetails?.gender == 'male' ? ' His' : 'Her'} conduct is
            satisfactory.{' '}
            {data?.studentDetails?.gender == 'male' ? ' His' : 'Her'} date of
            birth as per the college record is{' '}
            {data?.studentDetails?.personalDetails?.dob ? (
              moment(data?.studentDetails?.personalDetails?.dob).format(
                'DD-MM-YYYY',
              )
            ) : (
              <b>Not Available</b>
            )}
            . This certificate is issued for the purpose of{' '}
            {data?.result?.purpose?.replace('-', ' ')?.toUpperCase()} only.
          </StyledPara>
          <StyledBox
            sx={{
              marginTop: '20px',
              padding: '30px',
            }}
          >
            <Typography sx={{ fontSize: '22px', fontFamily: 'serif' }}>
              Section Officer
            </Typography>
            <Stack>
              <Typography sx={{ fontSize: '22px', fontFamily: 'serif' }}>
                PRINCIPAL
              </Typography>
            </Stack>
          </StyledBox>
        </>
      )}
    </>
  )
}
function Certificate() {
  const { id } = useParams()
  const { data, isLoading } = useQuery('student-certificates', () =>
    getStudentCertificate(id),
  )
  if (isLoading) <Spinner />

  return (
    <>
      {urlTenantKey !== 'aupulse' ? (
        <BoxCenter>
          <Box sx={{ height: '100%', width: '210mm' }}>
            <Box
              sx={{
                border: urlTenantKey == 'stpec' ? '' : '3px solid black',
                margin: '30px',
              }}
            >
              {urlTenantKey == 'stpec' ? (
                <Box>
                  <img
                    src={STPECHeader}
                    alt="StpecHeader"
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  />
                </Box>
              ) : (
                <Header />
              )}
              {urlTenantKey == 'stpec' ? (
                <Box></Box>
              ) : (
                <Box
                  sx={{
                    borderBottom: '2px solid rgba(0,0,0,0.4)',
                    width: '100%',
                  }}
                ></Box>
              )}

              <Body data={data} />
            </Box>
          </Box>
        </BoxCenter>
      ) : (
        <BoxCenter>
          <Box
            sx={{
              height: '100%',
              width: '210mm',
            }}
          >
            <Header />
            <Body data={data} />
          </Box>
        </BoxCenter>
      )}
    </>
  )
}
export default Certificate
