import { Box, Stack, Typography, styled } from '@mui/material'
export const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})
export const BoxCenter = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
export const StyledText = styled(Typography)({
  fontSize: '35px',
  textDecoration: 'underline',
  fontFamily: 'serif',
})
export const StyledStudentsStack = styled(Stack)({
  gap: '10px',
  marginTop: '10px',
  maxHeight: '500px',
  overflowY: 'auto',
})
export const StyledStudentCard = styled(Box)<{ selected: boolean }>(
  ({ theme, selected }) => ({
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    border: theme.borders.grayLight,
    borderRadius: '10px',
    ...(selected && {
      border: `1px solid ${theme.palette.primary.main}`,
    }),
    cursor: 'pointer',
  }),
)
export const StyledHeaderContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '50px',
  padding: '30px',
})
export const StyledPara = styled(Typography)({
  wordSpacing: '2px',
  fontSize: '16px',
  textIndent: '160px',
  lineHeight: '2.5',

  fontFamily: 'Lora, Regular',
  textAlign: 'justify',
  padding: '0 30px',
})
export const StyledStack = styled(Stack)({
  alignItems: 'center',
  flexDirection: 'row',
  gap: '10px',
  height: '14px',
  marginTop: '8px',
})

export const StyledHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '100px',
  padding: '30px',
})

export const StyledTypography = styled(Typography)({
  wordSpacing: '2px',
  fontSize: '16px',
  lineHeight: '2.5',
})

export const StyledAddress = styled(Typography)({
  fontStyle: 'italic',
  color: '#808080',
  fontSize: '11px',
  fontWeight: 'bold',
  paddingLeft: '20px',
})

export const FixedTextUnderLine = styled('span')({
  borderBottom: '1px solid #000',
  position: 'relative',
  top: '-5px',
  padding: '0 5rem',
})

export const StyledSignature = styled(Box)<{ color?: any }>(({ color }) => ({
  fontSize: '16px',
  color: color,
  fontWeight: 'bold',
  fontStyle: 'italic',
}))
