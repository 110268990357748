import {
  ActionButton,
  axios,
  axiosErrorToast,
  FormRadioGroup,
  FormSingleSelect,
  FormTextField,
  Spinner,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import { batchOptions } from 'constants/UIConstants'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { services } from 'services'
import * as yup from 'yup'
import LeadSources from './Components/LeadSources'
import { createLead } from './services'

const mobileRegx: any =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
const emailRegex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}')

const schema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup
    .string()
    .required('Email Id is required')
    .matches(emailRegex, 'Enter a valid E-mail address'),
  mobile: yup.string().required('Mobile number is required'),

  // .matches(mobileRegx, 'Enter a valid 10 digit mobile number')
  // .length(10),
  gender: yup.string().required('Gender is required'),
  source: yup.string().required('Lead Source is required'),
  courseId: yup.string().required('Degree is required'),
  programId: yup.string().required('Program is required'),
  batch: yup.string().required('Batch is required'),
  status: yup.string().required('Lead Status is required'),
})

export const LeadType = ['Hot', 'Warm', 'Cold']

async function fetchCourseData() {
  const courseData = await services.courses.fetchDomainCourses()
  let branchData = await services.programs.fetchPrograms()
  // let counsellerData = await axios.get('/square/admissions/counsellors')
  let leadStatusData = await axios.get('/paymentx/lead-status')
  let leadSources = await axios.get('/paymentx/lead-sources')
  return {
    courses: courseData,
    branches: branchData,
    // counsellers: counsellerData.data,
    leadStatus: leadStatusData.data,
    leadSources: leadSources.data,
  }
}
function LeadForm({
  data,
  hideDialog,
}: {
  data?: any
  hideDialog: () => void
}) {
  const queryClient = useQueryClient()
  const { control, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      programId: data?.program?.id,
      courseId: data?.course?.id,
    },
  })

  const {
    isLoading,
    data: form,
    error,
  } = useQuery(['courses'], () => fetchCourseData())

  const courseId = watch().courseId
  const programData = courseId
    ? form?.branches?.filter((item) => {
        return item?.courseId == courseId
      })
    : []

  const { mutate, isLoading: posting } = useMutation(createLead, {
    onSuccess() {
      queryClient.invalidateQueries('leads')
      toast.success('Lead Created Successfully')
      close()
      hideDialog()
    },
    onError(err) {
      axiosErrorToast(err ?? 'Something went wrong')
    },
  })

  const onSubmit = async (formData) => {
    mutate({ data: formData })
  }
  const onError = (e) => {
    axiosErrorToast(e)
  }

  if (isLoading) return <Spinner />
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Stack gap={2.5}>
        <FormTextField
          label={'First Name'}
          name={'firstName'}
          control={control}
          required
        />
        <FormTextField
          label={'Last Name'}
          name={'lastName'}
          control={control}
          required
        />
        <FormTextField
          label={'E-Mail ID'}
          name={'email'}
          control={control}
          required
        />

        <FormTextField
          label={'Mobile Number'}
          name={'mobile'}
          control={control}
          required
        />
        <FormRadioGroup
          row
          required
          label={'Gender'}
          name={'gender'}
          control={control}
          options={[
            { label: 'Male', value: 'male' },
            { label: 'Female', value: 'female' },
          ]}
        />
        <LeadSources control={control} setValue={setValue} />
        <FormSingleSelect
          label={'Lead Type'}
          name={'type'}
          control={control}
          options={LeadType?.map((item) => {
            return { label: item, value: item }
          })}
        />
        {/* <FormSingleSelect
          label={'Assign to'}
          name={'counsellorId'}
          control={control}
          options={form?.counsellers?.map((item) => {
            return { label: item?.user?.fullName, value: item?.userId }
          })}
        /> */}
        <FormSingleSelect
          label={'Interested Degree'}
          name={'courseId'}
          control={control}
          options={form?.courses?.map((item) => {
            return { label: item.courseName, value: item.id }
          })}
          required
        />
        <FormSingleSelect
          label={'Interested Programs'}
          name={'programId'}
          control={control}
          options={
            courseId
              ? programData?.map((item) => {
                  return { label: item?.branchName, value: item?.id }
                })
              : []
          }
          required
        />

        <FormSingleSelect
          label={'Batch'}
          name={'batch'}
          control={control}
          options={batchOptions.map((item, index) => ({
            label: item,
            value: item,
          }))}
          required
        />
        <FormSingleSelect
          label={'Lead Status'}
          name={'status'}
          control={control}
          options={form?.leadStatus?.map((item, index) => ({
            label: item.name,
            value: item.name,
          }))}
          required
        />

        <FormTextField
          minRows={5}
          multiline
          label={'Comments'}
          name={'remarks'}
          control={control}
        />

        <Stack direction="row" gap={2} mt={2}>
          <ActionButton loading={posting} type="submit" fullWidth>
            Create Lead
          </ActionButton>
          <ActionButton
            variant="outlined"
            fullWidth
            onClick={() => hideDialog()}
          >
            Cancel
          </ActionButton>
        </Stack>
      </Stack>
    </form>
  )
}

export default LeadForm
