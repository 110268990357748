import { FormSingleSelect, JsonPreview, Spinner, axios } from '@campxdev/shared'
import { Alert, Box, Stack } from '@mui/material'
import { useQuery } from 'react-query'
import { formStore } from './AdmissionForm'
import { batchOptions } from 'constants/UIConstants'

export default function Step3Form({ control, watch }) {
  const { data: course, isLoading: courseLoading } = useQuery('branch', () =>
    axios.get('/square/courses').then((res) => res.data),
  )
  const { formErrors } = formStore.useState((s) => s)

  const { data: programs, isLoading: programLoading } = useQuery(
    ['program', watch('courseId')],
    () =>
      axios
        .get('/square/branches', {
          params: {
            courseId: watch('courseId'),
          },
        })
        .then((res) => res.data),
    {
      enabled: !!watch('courseId'),
    },
  )
  const errors = Object.entries(formErrors ?? {}).map((item: [string, any]) => {
    return {
      key: item[0],
      message: item[1]?.message,
    }
  })

  if (courseLoading) return <Spinner />
  return (
    <Stack gap={2}>
      <FormSingleSelect
        control={control}
        name="batch"
        label="Batch"
        options={[
          { label: 'None', value: 'None' },
          ...batchOptions.map((item, index) => ({
            label: item,
            value: item,
          })),
        ]}
      />

      <FormSingleSelect
        name="courseId"
        label="Degree"
        required
        control={control}
        options={course?.map((item) => ({
          label: item?.courseName,
          value: item?.id,
        }))}
      />

      {programLoading ? (
        <Spinner />
      ) : (
        <>
          <FormSingleSelect
            name="branchCode"
            label="Program (choice 1)"
            required
            control={control}
            options={programs?.map((item) => {
              return {
                label: item?.branchName,
                value: item?.branchCode,
              }
            })}
          />

          <FormSingleSelect
            name="choice2"
            label="Program (choice 2)"
            control={control}
            options={programs
              ?.filter((item) => item.id !== watch('choice1'))
              ?.map((item) => {
                return {
                  label: item?.branchName,
                  value: item?.id,
                }
              })}
          />

          <FormSingleSelect
            name="choice3"
            label="Program (choice 3)"
            control={control}
            options={programs
              ?.filter(
                (item) =>
                  item.id != watch('choice1') && item.id != watch('choice2'),
              )
              ?.map((item) => {
                return {
                  label: item?.branchName,
                  value: item?.id,
                }
              })}
          />
        </>
      )}

      {/* <>
        {errors?.length ? (
          <Alert severity="error">
            <Stack gap={1}>
              {errors.map((item) => (
                <li>{item?.message}</li>
              ))}
            </Stack>
          </Alert>
        ) : null}
      </> */}
    </Stack>
  )
}
