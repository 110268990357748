import { Permission } from '@campxdev/shared'
import { EnrollPermissions } from '@campxdev/shared/src/shared-state/PermissionsStore'
import { AppRegistration } from '@mui/icons-material'
import { CalenderIcon } from 'layouts/SettingsLayout/icons'
import {
  ApplicationIcon,
  CetIcon,
  CollegeFeedIcon,
  ConcessionIcon,
  DashboardIcon,
  LogsIcon,
  ReportsIcon,
  SettingsIcon,
  TaskListIcon,
  UsersIcon,
} from './icons'

export const dashboardMenu = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: DashboardIcon,
    permissionKey: Permission.CAN_ADMISSIONS_DASHBOARD_VIEW,
  },
  {
    title: 'Cluster Dashboard',
    path: '/cluster-dashboard',
    icon: DashboardIcon,
    permissionKey: EnrollPermissions.CAN_CLUSTERS_DASHBOARD_VIEW,
  },

  {
    title: 'Leads',
    path: '/leads',
    icon: TaskListIcon,
    permissionKey: Permission.CAN_LEADS_VIEW,
  },

  {
    title: 'Admissions',
    path: '/admissions',
    icon: UsersIcon,
    permissionKey: Permission.ADMISSIONS_VIEW,
  },

  {
    title: 'Counsellors',
    path: '/counsellors',
    icon: UsersIcon,
    permissionKey: Permission.CAN_COUNSELLOR_VIEW,
  },
  {
    path: '/concessions',
    title: 'Concessions',
    icon: ConcessionIcon,
    permissionKey: Permission.CAN_ENROLL_X_CONCESSION_VIEW,
  },
  {
    path: '/student-service-requests',
    title: 'Student Service Requests',
    icon: CalenderIcon,
  },
  {
    title: 'Certificates',
    path: 'certificates',
    icon: CalenderIcon,
    permissionKey: Permission.CAN_STUDENT_CERTIFICATES_VIEW,

    children: [
      {
        title: 'Student Certificates',
        path: '/student-certificates',
        permissionKey: Permission.CAN_STUDENT_CERTIFICATES_VIEW,
      },
      {
        title: 'Templates',
        path: '/templates',
        // permissionKey: isDevelopment && Permission.STUDENTS_VIEW,
      },
    ],
  },
  {
    title: 'CET',
    path: '/cet',
    icon: CollegeFeedIcon,
    permissionKey: Permission.CAN_CET_VIEW,
  },
  {
    title: 'Agri CET',
    path: '/agricet',
    icon: CetIcon,
    permissionKey: Permission.CAN_CET_VIEW,
  },

  {
    title: 'PHD Applications',
    path: '/phd',
    icon: ApplicationIcon,
    permissionKey: Permission.CAN_PHD_FORM_VIEW,
  },

  {
    title: 'Reports',
    path: '/reports',
    icon: ReportsIcon,
    permissionKey: Permission.CAN_LEAD_COUNSELLOR_REPORT_VIEW,
  },

  // {
  //   title: 'Reports',
  //   path: '/reports',
  //   icon: ReportsIcon,
  //   permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  //   children: [
  //     {
  //       path: '/counsellor-reports',
  //       title: 'Counsellor Report',
  //       permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  //     },
  //     {
  //       path: '/semester-reports',
  //       title: 'Semester Report',
  //       permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  //     },
  //     {
  //       path: '/deleted-admission-reports',
  //       title: 'Deleted Admissions',
  //       permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  //     },
  //     {
  //       path: '/admission-intake-report',
  //       title: 'Admission Intake Report',
  //       permissionKey: Permission.CAN_COUNSELLOR_REPORT_VIEW,
  //     },
  //   ],
  // },

  {
    title: 'Activity Logs',
    path: '/audit-logs',
    icon: LogsIcon,
    permissionKey: Permission.CAN_ENROLL_X_AUDIT_LOGS,
  },
  {
    title: 'Lead Exam Registrations',
    path: '/lead-registration',
    icon: AppRegistration,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },

  {
    title: 'Configuration',
    path: '/settings',
    icon: SettingsIcon,
    permissionKey: Permission.CAN_SETTINGS_VIEW,
  },
]
