import {
  MongoDashboard,
  PageHeader,
  SingleSelect,
  Spinner,
  UserStore,
} from '@campxdev/shared'
import { InstitutionsStore } from '@campxdev/shared/src/shared-state/InstitutionsStore'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { services } from 'services'
import { getMongoChartsAccessToken } from '../../services/mongo-charts'

export interface Filters {
  tenantId: {
    $oid: string
  }
  institutionId: string
  clusterId: any
}

const ClusterDashboard: React.FC = () => {
  const { institutions, current }: { institutions; current } =
    InstitutionsStore.useState((s) => s)

  const { user } = UserStore.useState((s) => s)

  const [filters, setFilters] = useState<Filters>({
    tenantId: {
      $oid: user.tenantId,
    },
    institutionId: current.id,
    clusterId: {
      $exists: true,
    },
  })

  let dashboardId = '377436da-918e-47b8-97e1-cc2628ca67b1'

  const { data: token, isLoading: tokenLoading } = useQuery(
    ['mongo-charts-token'],
    () => getMongoChartsAccessToken(),
  )

  const { data, isLoading: clustersLoading } = useQuery(['clusters'], () =>
    services.clusters.getClusters(),
  )

  const charts = [
    [
      '6620da06-0dae-4012-8afc-0cb3a1612560',
      '44e7a740-2a01-4991-9aea-c05baba492a8',
      '3242c592-f7e8-4423-b5dd-9d6ca299c2ac',
      'f8cc87d2-8d08-4d91-8d77-d7f2b06c8365',
      '665435ff-5f48-4758-8038-9d774ca4fab2',
      'f4d1d68a-861a-419d-ae99-57799b2a165b',
    ],
  ]

  if (tokenLoading || clustersLoading) {
    return <Spinner />
  }

  return (
    <>
      <PageHeader
        title="Cluster Dashboard"
        actions={[
          [
            <SingleSelect
              key={0}
              label={'Select Cluster'}
              name={'clusterId'}
              value={!filters.clusterId?.$exists ? filters.clusterId : 'all'}
              options={[
                { label: 'All', value: 'all' },
                ...data?.map((cluster) => ({
                  label: cluster.name,
                  value: cluster.id,
                })),
              ]}
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  clusterId:
                    e.target.value === 'all'
                      ? {
                          $exists: true,
                        }
                      : e.target.value,
                }))
              }
              sx={{
                minWidth: '200px',
              }}
            />,
          ],
        ]}
      />

      <MongoDashboard
        token={token}
        dashboardId={dashboardId}
        charts={charts}
        chartFilters={[filters]}
        sx={{ height: '200vh' }}
      />
    </>
  )
}

export default ClusterDashboard
